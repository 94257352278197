import { collection, doc, getDocs } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import React from "react";
import { db } from "../app/firebase";

const useFileHandlers = (
  setFileName,
  setFiles,
  setUploadProgress,
  currentUser,
  project_id,
  setProject_id,
  activeProjectId,
  addProjectDoc,
  projectName,
  setProjectCreated,
  setProjectDraftStatus,
  uploadFile,
  setIsDragOver,
  addFileDoc,
  updateProjectFilesData,
  files,
  inputRef,
  deleteFileFromFirestore,
  decrementTotalFiles,
  dispatch,
  deleteFileFromStorage,
  projectCreated
) => {
  const handleOnDropFiles = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let newFile = e.dataTransfer.files[0];
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    //console.log("file: ", newFile);
    if (newFile && newFile.type !== "text/plain") {
      setFileName(null);
      alert("Please select a .txt file");
      if (newFile.size > maxFileSize) {
        alert("Please select valid .txt files that are no larger than 5MB.");
      }
      return;
    }

    // Display the file name
    setFileName(newFile.name);

    // Add the file to the local state with an initial progress of 0
    const fileWithProgress = {
      id: newFile.lastModified,
      uid: `${Date.now()}-${Math.random().toString(36)}`,
      name: newFile.name,
      size: newFile.size,
      progress: 0,
    };
    setFiles((prevFiles) => [...prevFiles, fileWithProgress]);

    // Update the progress of the file in the state
    const updateFileProgress = (progress) => {
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.uid === fileWithProgress.uid ? { ...file, progress } : file
        )
      );
      setUploadProgress(progress);
    };

    const createdDate = new Date();
    const createdBy = currentUser.uid;

    try {
      let currentProjectId = project_id;

      if (!projectCreated && !currentProjectId) {
        const projectRef = doc(collection(getFirestore(), "projects"));
        currentProjectId = projectRef.id;
        setProject_id(currentProjectId);
        await dispatch(activeProjectId(currentProjectId));
      }
      const createdProjectId = await dispatch(
        addProjectDoc(projectName, createdBy, currentProjectId)
      );

      if (createdProjectId) {
        //console.log("Project created with ID:", createdProjectId);
        setProjectCreated(true);
        await dispatch(setProjectDraftStatus(true, createdProjectId));
      } else {
        console.error("Failed to create project");
      }

      // Upload file and get the download URL, file format, and storage name
      const { downloadURL, storageName, format } = await uploadFile(
        createdBy,
        currentProjectId,
        newFile,
        fileWithProgress.uid,
        updateFileProgress
      );
      setIsDragOver(false);

      // Update Firestore with total files, and add file data to the subcollection
      const fileId = await dispatch(
        addFileDoc(
          fileWithProgress.uid,
          storageName,
          newFile.size,
          downloadURL,
          format,
          createdDate,
          currentProjectId
        )
      );

      if (fileId) {
        //console.log("File document added with ID:", fileId);
      } else {
        console.error("Failed to add file document");
      }

      await dispatch(
        updateProjectFilesData(currentProjectId, files.length + 1)
      );
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleBrowseUpload = () => {
    inputRef.current.click();
  };

  // remove file
  const handleRemoveFile = async (userId, project_id, file, uid) => {
    const newFileList = files.filter((el) => el.uid !== file.uid);
    setFiles(newFileList);
    //console.log("File uid to delete:", newFileList);

    const storageDeleteSuccess = await deleteFileFromStorage(
      userId,
      project_id,
      uid,
      file.name
    );

    if (storageDeleteSuccess) {
      const firestoreDeleteSuccess = await dispatch(
        deleteFileFromFirestore(project_id, uid)
      );

      if (firestoreDeleteSuccess) {
        // Decrement total files count when the file is successfully deleted
        await dispatch(decrementTotalFiles(project_id));
      }
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
    //console.log("drag over");
  };

  // add file via browse
  const handleChange = async (e) => {
    //console.log("e :", e.target.files[0]);
    e.preventDefault();
    let newFile = e.target.files[0];
    if (newFile && newFile.type !== "text/plain") {
      alert("Please select a .txt file");
      return;
    }
    // Display the file name
    setFileName(newFile.name);

    // Add the file to the local state with an initial progress of 0
    const fileWithProgress = {
      id: newFile.lastModified,
      uid: `${Date.now()}-${Math.random().toString(36)}`,
      name: newFile.name,
      size: newFile.size,
      progress: 0,
    };
    setFiles((prevFiles) => [...prevFiles, fileWithProgress]);

    // Update the progress of the file in the state
    const updateFileProgress = (progress) => {
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.uid === fileWithProgress.uid ? { ...file, progress } : file
        )
      );
      setUploadProgress(progress);
    };

    const createdDate = new Date();
    const createdBy = currentUser.uid;

    try {
      let currentProjectId = project_id;

      if (!projectCreated && !currentProjectId) {
        const projectRef = doc(collection(getFirestore(), "projects"));
        currentProjectId = projectRef.id;
        setProject_id(currentProjectId);
        await dispatch(activeProjectId(currentProjectId));
      }
      const createdProjectId = await dispatch(
        addProjectDoc(projectName, createdBy, currentProjectId)
      );

      if (createdProjectId) {
        //console.log("Project created with ID:", createdProjectId);
        setProjectCreated(true);
        // Check if the labels collection is empty
        const labelsCollectionRef = collection(
          db,
          "projects",
          createdProjectId,
          "labels"
        );
        const labelsSnapshot = await getDocs(labelsCollectionRef);
        const isEmpty = labelsSnapshot.empty;

        if (isEmpty) {
          await dispatch(setProjectDraftStatus(true, createdProjectId));
        } else {
          //console.log("The labels collection is not empty.");
          await dispatch(setProjectDraftStatus(false, createdProjectId));
        }
      } else {
        console.error("Failed to create project");
      }

      // Upload file and get the download URL, file format, and storage name
      const { downloadURL, storageName, format } = await uploadFile(
        createdBy,
        currentProjectId,
        newFile,
        fileWithProgress.uid,
        updateFileProgress
      );
      setIsDragOver(false);

      // Update Firestore with total files, and add file data to the subcollection
      const fileId = await dispatch(
        addFileDoc(
          fileWithProgress.uid,
          storageName,
          newFile.size,
          downloadURL,
          format,
          createdDate,
          currentProjectId
        )
      );

      if (fileId) {
        //console.log("File document added with ID:", fileId);
      } else {
        console.error("Failed to add file document");
      }

      await dispatch(
        updateProjectFilesData(currentProjectId, files.length + 1)
      );
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return {
    handleOnDropFiles,
    handleBrowseUpload,
    handleRemoveFile,
    handleDragLeave,
    handleOnDragOver,
    handleChange,
  };
};

export default useFileHandlers;
