const AuthErrorHandler = ({ error }) => {
  //console.log("ERROR: ", error);
  //console.log("ERROR CODE: ", error.code);

  switch (error.code) {
    case "auth/email-already-in-use":
      return "email already in use";

    case "auth/weak-password":
      return "password should be strong";

    case "auth/user-not-found":
      return "user not found";

    case "auth/wrong-password":
      return "wrong password";

    case "auth/admin-restricted-operation":
      return "admin restricted operation";

    case "auth/invalid-email":
      return "Please enter a valid email";

    case "auth/requires-recent-login":
      return "";

    case "auth/missing-email":
      return "missing email";

    default:
      return "Something went wrong, Please try later.";
  }
};

export default AuthErrorHandler;
