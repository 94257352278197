import * as types from "../Actions/actionTypes";

import initialState from "./initialState";

import {
  updateProject,
  updateFileInProject,
  updateLabelInProject,
} from "./utilityFunctions";

const projects = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ANNOTATION: {
      const { annotation } = action.payload;
      const updatedProjects = updateProject(
        state.projects,
        annotation.projectId,
        (project) =>
          updateFileInProject(project, annotation.fileId, (file) => ({
            ...file,
            annotations: {
              ...(file.annotations || {}),
              [annotation.annotationId]: annotation,
            },
          }))
      );
      return { ...state, projects: updatedProjects };
    }

    case types.SET_ANNOTATIONS: {
      const { projectId, fileId, annotations } = action.payload;

      const updatedProjects = updateProject(
        state.projects,
        projectId,
        (project) =>
          updateFileInProject(project, fileId, (file) => ({
            ...file,
            annotations: {
              ...annotations,
            },
          }))
      );
      return { ...state, projects: updatedProjects };
    }

    case types.REMOVE_ANNOTATION: {
      const { annotation } = action.payload;
      //console.log("remove annotation: ", annotation);
      const updatedProjects = updateProject(
        state.projects,
        annotation.projectId,
        (project) =>
          updateFileInProject(project, annotation.fileId, (file) => {
            const updatedAnnotations = { ...file.annotations };
            delete updatedAnnotations[annotation.annotationId];
            return { ...file, annotations: updatedAnnotations };
          })
      );
      return { ...state, projects: updatedProjects };
    }
    case types.UPDATE_ANNOTATION: {
      const { projectId, fileId, annotationId, updatedData } = action.payload;

      const updatedProjects = state.projects.projects.map((project) => {
        if (project.projectId === projectId) {
          const updatedFiles = project.files.map((file) => {
            if (
              file.id === fileId &&
              file.annotations &&
              file.annotations[annotationId]
            ) {
              return {
                ...file,
                annotations: {
                  ...file.annotations,
                  [annotationId]: {
                    ...file.annotations[annotationId],
                    ...updatedData,
                  },
                },
              };
            }
            return file;
          });
          return { ...project, files: updatedFiles };
        }
        return project;
      });

      return {
        ...state,
        projects: {
          ...state.projects,
          projects: updatedProjects,
        },
      };
    }

    case types.GET_TOTAL_PROJECT:
      return { ...state, loading: true };
    case types.SET_TOTAL_PROJECT:
      return { ...state, loading: false, totalProject: action.totalProject };

    case types.ADD_PROJECT_SUCCESS:
      //console.log("add project success");
      return {
        ...state,
        projects: [action.project],
        addProjectError: null,
      };
    case types.ADD_PROJECT_ERROR:
      //console.log("add project error");
      return {
        ...state,
        addProjectError: action.error,
      };
    case types.ACTIVE_PROJECT_ID:
      return { ...state, activeProject_id: action.projectId };

    case types.UPDATE_LABEL_NAME:
      const {
        project_id: updateLabelProjectId,
        label_id: updateLabelId,
        newLabelName,
      } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === updateLabelProjectId) {
            return {
              ...project,
              labels: project.labels.map((label) => {
                if (label.label_id === updateLabelId) {
                  return { ...label, name: newLabelName };
                }
                return label;
              }),
            };
          }
          return project;
        }),
      };

    case types.INIT_SUBLABELS: {
      const {
        project_id: initSublabelsProjectId,
        label_id: initSublabelsLabelId,
        subLabelData,
      } = action.payload;
      //console.log("sublabel id ", subLabelData);
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === initSublabelsProjectId) {
            return {
              ...project,
              labels: project.labels.map((label) => {
                if (label.label_id === initSublabelsLabelId) {
                  // Update the subLabels property with the provided initSublabels
                  return {
                    ...label,
                    subLabels: [...(label.subLabels || []), subLabelData],
                  };
                }
                return label;
              }),
            };
          }
          return project;
        }),
      };
    }

    case types.SET_SUBLABELS:
      const {
        project_id: setSublabelsProjectId,
        label_id: setSublabelsLabelId,
        subLabel_id: setSublabelsId,
        subLabelValue,
      } = action.payload;
      //console.log("set sublabel id: ", setSublabelsId);
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === setSublabelsProjectId) {
            return {
              ...project,
              labels: project.labels.map((label) => {
                if (label.label_id === setSublabelsLabelId) {
                  return {
                    ...label,
                    subLabels: label.subLabels.map((sublabel) => {
                      if (sublabel.sublabel_id === setSublabelsId) {
                        return {
                          ...sublabel,
                          name: subLabelValue,
                        };
                      }
                      return sublabel;
                    }),
                  };
                }
                return label;
              }),
            };
          }
          return project;
        }),
      };

    case types.REMOVE_LABEL: {
      const { project_id: removeLabelProjectId, label_id: removeLabelId } =
        action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === removeLabelProjectId) {
            return {
              ...project,
              labels: project.labels.filter(
                (label) => label.label_id !== removeLabelId
              ),
            };
          }
          return project;
        }),
      };
    }

    case types.UPDATE_LABEL_ID: {
      const {
        project_id: updateLabelIdProjectId,
        oldId,
        newId,
      } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === updateLabelIdProjectId) {
            const updatedLabels = project.labels.map((label) => {
              if (label.label_id === oldId) {
                return { ...label, label_id: newId };
              }
              return label;
            });
            return { ...project, labels: updatedLabels };
          }
          return project;
        }),
      };
    }

    case types.GET_LABELS_LOCAL: {
      const { project_id: setLabelsProjectId, labels } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === setLabelsProjectId) {
            return {
              ...project,
              labels: labels,
            };
          }
          return project;
        }),
      };
    }

    case types.SET_LABELS:
      const { project_id: setLabelsProjectId, label } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === setLabelsProjectId) {
            const labels = project.labels || [];
            return {
              ...project,
              labels: [...labels, label],
            };
          }
          return project;
        }),
      };

    case types.SET_FILES:
      const { project_id: setFilesProjectId, file } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === setFilesProjectId) {
            const files = project.files || [];
            return {
              ...project,
              files: [...files, file],
            };
          }
          return project;
        }),
      };

    case types.DELETE_FILES:
      const { project_id: setFileProjectId, selectedFileIds } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === setFileProjectId) {
            return {
              ...project,
              files: project.files.filter(
                (file) => !selectedFileIds.includes(file.id)
              ),
            };
          }
          return project;
        }),
      };

    case types.SET_PROJECT_DRAFT_STATUS:
      return { ...state, loading: false };

    case types.GET_PROJECT:
      return {
        ...state,
        projects: [action.project],
        addProjectError: null,
      };

    case types.SET_CURRENT_FILE_INDEX: {
      return {
        ...state,
        projects: state.projects.map((project) => {
          console.log("currentfileid update", project, action.payload);

          if (project.projectId === action.payload.projectId) {
            return {
              ...project,
              currentFileIndex: action.payload.index,
            };
          }
          return project;
        }),
      };
    }
    case types.SET_CURRENT_FILE_ID: {
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === action.payload.projectId) {
            return {
              ...project,
              currentFileId: action.payload.id,
            };
          }
          return project;
        }),
      };
    }

    case types.DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === action.projectId) {
            return [];
          }
          return project;
        }),
      };

    case types.CREATE_NEW_PROJECT:
      return {
        ...state,
        projects: [action.project],
        addProjectError: null,
      };

    case types.SET_TOTAL_PAGES:
      const {
        project_id: setTotalPagesFilesProjectId,
        file_id,
        totalPages,
      } = action.payload;
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.projectId === setTotalPagesFilesProjectId) {
            return {
              ...project,
              files: project.files.map((file) => {
                if (file.id === file_id) {
                  return { ...file, totalPages: totalPages };
                }
                return file;
              }),
            };
          }
          return project;
        }),
      };

    default:
      return state;
  }
};

export default projects;
