import React, { useState } from "react";
import {
  Button,
  Div,
  FeaturedIcon,
  HelpIcon,
  Input,
  InputWrapper,
  Label,
  ModalActions,
  ModalContainer,
  ModalContent,
  Text,
  TextWrapper,
} from "./ShareProjectModalStyles";
import { ReactComponent as Featured_green } from "../../assets/FeaturedIcon-green.svg";
import { ReactComponent as Help } from "../../assets/HelpIcon.svg";
import { v4 as uuidv4 } from "uuid"; // Import uuidv4 from uuid library
import { _setSharedUserViaEmail } from "../../app/Actions/projectActions";
import { HintText } from "../ForgetPassword/ForgetPasswordStyles";
import { connect } from "react-redux";
import {
  CloseIcon,
  IconActions,
} from "../PageLabels/MarkAsCompleteModalStyles";
import { ReactComponent as Close } from "../../assets/CloseIcon.svg";

const ShareProjectModal = ({
  closeModal,
  projectId,
  currentUser,
  screen,
  setSharedUserAPI,
}) => {
  const [email, setEmail] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [copiedButtonText, setCopiedButtonText] = useState("Copy Link");
  const [shareableLink, setShareableLink] = useState(null);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (emailText) => {
    if (emailText === "") {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(emailText)) {
      if (!/@/.test(emailText)) {
        setEmailError('Email is missing "@" symbol');
      } else if (!/\.\S+/.test(emailText)) {
        setEmailError('Email is missing ".com"');
      }
    } else {
      setEmailError("");
    }
  };

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const HandleInviteUserViaEmail = async (projectId, currentUser) => {
    validateEmail(email);
    if (!emailError && currentUser.email !== email) {
      generateShareableLink();
      await setSharedUserAPI(projectId, email);
      closeModal();
    }
  };

  const generateShareableLink = () => {
    // Generate a unique token using uuid
    if (projectId && !shareableLink) {
      const uniqueToken = uuidv4();
      const link = `http://localhost:3000/page-label?projectId=${projectId}&shareToken=${uniqueToken}`;
      //console.log("shareable link: ", link);
      setShareableLink(link);
    } else {
      setShareableLink(null);
    }
  };

  const handleCopyShareableLinkClick = () => {
    generateShareableLink();
    if (!isLinkCopied && shareableLink) {
      const textarea = document.createElement("textarea");
      textarea.value = shareableLink;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      //alert("Link copied!");
      setCopiedButtonText("Copied");
      setIsLinkCopied(true);
      setTimeout(() => {
        closeModal();
      }, 1500); // Close modal after 1.5 seconds
    }
  };

  return (
    <ModalContainer className={screen === "home" ? "home_screen" : null}>
      <ModalContent>
        <IconActions>
          <FeaturedIcon as={Featured_green} />
          <CloseIcon as={Close} onClick={closeModal} />
        </IconActions>
        <TextWrapper>
          <Text className="text">Share Project</Text>
          <Text className="supportive_text">
            This blog post has been published. Team members will be able to edit
            this post and republish changes.
          </Text>
        </TextWrapper>
        <InputWrapper>
          <Label>Email</Label>
          <Div>
            <Input
              type="text"
              placeholder="Enter an email"
              value={email}
              onChange={handleOnChange}
            />
            <HelpIcon as={Help} />
          </Div>
          {emailError.length !== 0 && <HintText>{emailError}</HintText>}
        </InputWrapper>
      </ModalContent>
      <ModalActions>
        <Button
          className="button_cancel"
          onClick={() => HandleInviteUserViaEmail(projectId, currentUser)}
        >
          Invite
        </Button>
        <Button
          className="button_confirm"
          onClick={handleCopyShareableLinkClick}
        >
          {copiedButtonText}
        </Button>
      </ModalActions>
    </ModalContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSharedUserAPI: (projectId, user) =>
      dispatch(_setSharedUserViaEmail(projectId, user)),
  };
};

export default connect(null, mapDispatchToProps)(ShareProjectModal);
