import React, { useState } from "react";
import {
  Container,
  ForgetPassForm,
  HintText,
  Input,
  KeyFeaturedIcon,
  Label,
  LeftArrowIcon,
  RedirectText,
  ResetPassButton,
  SupportingText,
  Text,
  TextAndSupportingText,
} from "./ResetPasswordStyles";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrowIcon.svg";
import { Link } from "react-router-dom";
import { ReactComponent as FeaturedLogo } from "../../assets/FeaturedIcon.svg";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import { connect } from "react-redux";
import { confirmPasswordResetHandler } from "../../app/Actions/authActions";
import { useLocation } from "react-router-dom";

const ResetPassword = ({ authMessage, confirmPasswordResetAPI }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [error, setError] = useState("");
  const location = useLocation();

  //console.log("location: ", location);

  const handleSubmit = async (e, confirmPassword) => {
    e.preventDefault();
    if (password === confirmPassword && !authMessage && !error) {
      // Password and confirm password match, implement the password reset logic here
      const queryParams = new URLSearchParams(location.search);
      const oobCode = queryParams.get("oobCode");
      //console.log("oobCode: ", oobCode);
      await confirmPasswordResetAPI(oobCode, confirmPassword);
      //console.log("reset pass success...");
      setIsResetPasswordOpen(true); // Only set this to true when passwords match
      setError(""); // Clear any previous errors if they exist
    } else {
      //console.log("pass & confirm pass are different");
      setError("Password and confirm password must be the same.");
      setIsResetPasswordOpen(false);
    }
  };

  return (
    <>
      {!isResetPasswordOpen || error || authMessage ? (
        <Container>
          <KeyFeaturedIcon as={FeaturedLogo} />
          <TextAndSupportingText>
            <Text>Set new password</Text>
            <SupportingText>
              Your new password must be different to previously used passwords.
            </SupportingText>
          </TextAndSupportingText>
          <ForgetPassForm onSubmit={(e) => handleSubmit(e, confirmPassword)}>
            <Label>Password</Label>
            <Input
              type="password"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength="8"
              style={{ borderColor: error ? "#FDA29B" : "" }}
            />
            <HintText>Must be at least 8 characters.</HintText>
            <Label>Confirm password</Label>
            <Input
              type="password"
              placeholder=""
              value={confirmPassword}
              required
              minLength="8"
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ borderColor: error ? "#FDA29B" : "" }}
            />
            {error.length !== 0 && (
              <HintText style={{ color: " #f04438" }}>{error}</HintText>
            )}

            <ResetPassButton type="submit">Reset password</ResetPassButton>
          </ForgetPassForm>
          <Link to="/" style={{ textDecoration: "none" }}>
            <RedirectText>
              <LeftArrowIcon as={LeftArrow} />
              Back to log in
            </RedirectText>
          </Link>
        </Container>
      ) : (
        <>
          {/* Show ResetPasswordSuccess only when passwords match */}
          {isResetPasswordOpen && !error && !authMessage && (
            <ResetPasswordSuccess password={password} />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authMessage: state.auth.authMessage,
  };
};

// Define functions to dispatch actions to the Redux store
const mapDispatchToProps = (dispatch) => ({
  confirmPasswordResetAPI: (oobCode, confirmPassword) =>
    dispatch(confirmPasswordResetHandler(oobCode, confirmPassword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
