export const updateProject = (projects, projectId, callback) =>
  projects.map((project) =>
    project.projectId === projectId ? callback(project) : project
  );

export const updateFileInProject = (project, fileId, callback) => ({
  ...project,
  files: project.files.map((file) =>
    file.id === fileId ? callback(file) : file
  ),
});

export const updateLabelInProject = (project, labelId, callback) => ({
  ...project,
  labels: project.labels.map((label) =>
    label.label_id === labelId ? callback(label) : label
  ),
});
