import React, { useEffect, useState } from "react";
import {
  Actions,
  ButtonControls,
  CancelButton,
  Div,
  EmailText,
  Form,
  HeaderForm,
  HeaderInput,
  HeaderLabel,
  HelloText,
  HintText,
  Input,
  Label,
  MailIcon,
  NameText,
  ProfileDetail,
  ProfileSettingsContainer,
  SaveButton,
  SupportingText,
  Text,
  TextAndSupportingText,
} from "./ProfileSettingsStyle";
import { ReactComponent as Mail } from "../../assets/MailIcon.svg";
import UserAvatar from "./UserAvatar";
import { connect } from "react-redux";
import {
  updateUserName,
  updateUserPassword,
  updateUserProfilePicture,
} from "../../app/Actions/authActions";
import AvatarIcon from "../../assets/AvatarIcon.svg";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../app/firebase";
import { nanoid } from "nanoid";

const ProfileSettings = ({
  currentUser,
  updateUserNameAPI,
  updateUpdatePasswordAPI,
  updateUserProfilePictureAPI,
}) => {
  const [name, setName] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [file, setFile] = useState(null);
  const [profileAvatar, setProfileAvatar] = useState(null);

  useEffect(() => {
    if (currentUser) {
      //console.log("profile avatar: ", currentUser);
      currentUser.profilePicture
        ? setProfileAvatar(currentUser.profilePicture)
        : setProfileAvatar(AvatarIcon);
    }
  }, [currentUser]);

  const handleUpdateName = (e) => {
    setName(e.target.value);
  };

  const generateUniqueId = () => {
    return nanoid();
  };

  // create URL for selected file. Set new profile picture.
  const handlePictureChange = async (e) => {
    //console.log("img: ", varFile);
    const newProfilePicture = e.target.files[0];
    if (newProfilePicture) {
      setFile(e.target.files[0]);

      // Upload the new profile picture to Firebase Storage
      // Get the download URL after upload and pass it to updateProfilePicture action

      let pic_id = generateUniqueId();

      // Example of uploading to Firebase Storage
      const storageRef = ref(
        storage,
        `profilePictures/${currentUser.uid}/${pic_id}`
      );
      uploadBytes(storageRef, newProfilePicture)
        .then((snapshot) => getDownloadURL(snapshot.ref))
        .then(async (downloadURL) => {
          await updateUserProfilePictureAPI(currentUser, downloadURL);
        })
        .catch((error) => {
          console.error("Error uploading profile picture:", error);
        });
    }
  };

  // call to API and update email, name
  const handleUpdateUserName = async (e) => {
    e.preventDefault();
    //console.log("Edit Profile: ", email, name);
    if (name !== "") {
      await updateUserNameAPI(currentUser, name);
    }
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    updateUpdatePasswordAPI(currentUser, newPassword);
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
  };

  return (
    <ProfileSettingsContainer>
      <UserAvatar
        avatarSrc={profileAvatar}
        handlePictureChange={handlePictureChange}
        file={file}
      />
      <ProfileDetail>
        <HelloText>
          Hello,
          <NameText>
            &nbsp;
            {currentUser.displayName !== null
              ? currentUser.name
              : currentUser.email.split("")[0]}
          </NameText>
        </HelloText>
        <EmailText>{currentUser.email}</EmailText>
        <Form>
          <Label>Name</Label>
          <Div>
            <MailIcon as={Mail} />
            <Input
              type="text"
              placeholder={currentUser.name}
              value={name}
              onChange={handleUpdateName}
            />
          </Div>
          <ButtonControls>
            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
            <SaveButton onClick={handleUpdateUserName}>Save</SaveButton>
          </ButtonControls>
        </Form>
      </ProfileDetail>
      <TextAndSupportingText>
        <Text>Password</Text>
        <SupportingText>
          Please enter your current password to change your password.
        </SupportingText>
      </TextAndSupportingText>
      <HeaderForm>
        <HeaderLabel>Current password</HeaderLabel>
        <HeaderInput
          type="password"
          placeholder=""
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />

        <HeaderLabel>New password</HeaderLabel>
        <HeaderInput
          type="password"
          placeholder=""
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <HintText>Your new password must be more than 8 characters.</HintText>

        <HeaderLabel>Confirm new password</HeaderLabel>
        <HeaderInput
          type="password"
          placeholder=""
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />

        <Actions>
          <CancelButton>Cancel</CancelButton>
          <SaveButton onClick={handleUpdatePassword}>
            Update password
          </SaveButton>
        </Actions>
      </HeaderForm>
    </ProfileSettingsContainer>
  );
};

// Use the connect function from react-redux to connect the Login component to the Redux store
const mapStateToProps = (state) => {
  return {};
};

// Define functions to dispatch actions to the Redux store
const mapDispatchToProps = (dispatch) => ({
  updateUserProfilePictureAPI: (currentUser, downloadURL) =>
    dispatch(updateUserProfilePicture(currentUser, downloadURL)),
  updateUserNameAPI: (currentUser, name) =>
    dispatch(updateUserName(currentUser, name)),
  updateUpdatePasswordAPI: (currentUser, newPassword) =>
    dispatch(updateUserPassword(currentUser, newPassword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
