import React from "react";
import {
  Button,
  CloseIcon,
  FeaturedIcon,
  IconActions,
  ModalActions,
  ModalContainer,
  ModalContent,
  Text,
  TextWrapper,
} from "./MarkAsCompleteModalStyles";
import { ReactComponent as Featured_green } from "../../assets/FeaturedIcon-green.svg";
import { ReactComponent as Close } from "../../assets/CloseIcon.svg";

const MarkAsCompleteModal = ({ closeModal }) => {
  return (
    <ModalContainer>
      <ModalContent>
        <IconActions>
          <FeaturedIcon as={Featured_green} />
          <CloseIcon as={Close} onClick={closeModal} />
        </IconActions>
        <TextWrapper>
          <Text className="text">Mark as complete?</Text>
          <Text className="supportive_text">
            You will still be able to edit the project but its status will
            appear as complete to other users
          </Text>
        </TextWrapper>
      </ModalContent>
      <ModalActions>
        <Button className="button_cancel" onClick={closeModal}>
          Cancel
        </Button>
        <Button className="button_confirm">Confirm</Button>
      </ModalActions>
    </ModalContainer>
  );
};

export default MarkAsCompleteModal;
