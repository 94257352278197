import React, { useState, useEffect, useRef } from "react";
import { FileViewerWrapper } from "./PageLabelsStyles";
import { collection, getDocs } from "firebase/firestore"; // Import these at the top of your file
import { db } from "../../app/firebase"; // make sure 'db' is the correct export from your firebase configuration
import {
  TextWithAlpha,
  ParagraphText,
  TableBody,
  TableRow,
  TableData,
} from "../../Util/LabellingStyles";
import useKeyboardHandlers from "../../Util/useKeyboardHandlers";
import useLabelHandlers from "../../Util/useLabelHandlers";
import AlphaTextComponent from "../../Util/AlphaTextComponent";
import RectangleDivComponent from "../../Util/RectangleDivComponent";
import LabelsPopup from "../Upload/LabelsPopup";
import { useSelector, useDispatch } from "react-redux";
import useFileHandler from "./useFileHandler";
import { setAnnotationsAction } from "../../app/Actions/annotationActions"; // Update the path accordingly

const FileViewer = ({
  fileUrl,
  fileId,
  isSidebarOpen,
  setMaxPage,
  setTotalPages,
  currentPage,
  project_id,
  setTotalPagesAPI,
  labels,
  setShowStepNumber,
}) => {
  const firestore = db;
  const { fileContent, data, loading, error } = useFileHandler(fileUrl);

  // const ROWS_PER_PAGE = 9; // number of rows per page
  // const { fileContent, data, loading, error } = useFileHandler(fileUrl);

  // const maxPage = Math.ceil(fileContent.length / ROWS_PER_PAGE);
  // setMaxPage(maxPage);
  // const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
  // const endIndex = startIndex + ROWS_PER_PAGE;
  // const displayedRows = fileContent.slice(startIndex, endIndex);
  // const totalPages = Math.ceil(fileContent.length / ROWS_PER_PAGE);

  // setTotalPages(totalPages);

  // const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const [isMouseHovering, setIsMouseHovering] = useState(null);
  const [startId, setStartId] = useState({ id: null, row: null });
  const [endId, setEndId] = useState({ id: null, row: null });
  const [annotationId, setAnnotationId] = useState("");

  const [selectLabel, setSelectLabel] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [id, setId] = useState(null);
  const [highlightState, setHighlightState] = useState(null);

  const projectsState = useSelector((state) => state.projects);
  const dispatch = useDispatch();

  const getAnnotationsForFile = () => {
    const project = projectsState.projects.find((p) => p.id === project_id);
    if (project) {
      const file = project.files.find((f) => f.id === fileId);
      if (file && file.annotations) {
        return Object.values(file.annotations);
      }
    }
    return null;
  };

  useEffect(() => {
    const loadAnnotations = async () => {
      // Reset the highlights to an empty array. This ensures that when fileId changes, previous annotations don't appear.
      setHighlights([]);
      const reduxAnnotations = getAnnotationsForFile(); // Check redux first

      if (reduxAnnotations && reduxAnnotations.length) {
        //console.log("currentAnnotations 1 ", reduxAnnotations);
        setHighlights(reduxAnnotations);
      } else {
        // Fetch annotations from Firestore if not in Redux
        const annotationsCollectionRef = collection(
          firestore,
          "projects",
          project_id,
          "files",
          fileId,
          "annotations"
        ); // Adjust this path based on your Firestore structure
        const annotationsSnapshot = await getDocs(annotationsCollectionRef);

        const firestoreAnnotations = [];
        annotationsSnapshot.forEach((doc) => {
          const annotationData = doc.data();
          firestoreAnnotations.push(annotationData);
        });

        if (firestoreAnnotations.length) {
          setHighlights(firestoreAnnotations);
          dispatch(
            setAnnotationsAction(project_id, fileId, firestoreAnnotations)
          );
        }
      }
    };

    loadAnnotations();
  }, [fileId, project_id]);

  const Letters = [
    "q",
    "w",
    "e",
    "r",
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m",
  ];
  const count = data[selectedRow]?.split(" ").length;
  const newLetters = Letters;
  const len = Letters.length;

  if (count > len) {
    const iIndex = Math.floor(count / len);
    for (let i = 0; i < iIndex; i++) {
      for (let j = 0; j < len; j++) {
        var result = Letters[i].concat(Letters[j]);
        newLetters.push(result);
        if (newLetters.length === count) break;
      }
    }
  }

  const {
    handleLabelSelection,
    handleDeleteClick,
    setHighlights: setLabelHighlights,
    setStartId: setLabelStartId,
    setEndId: setLabelEndId,
    setSelectLabel: setLabelSelectLabel,
    setSelectedRow: setLabelSelectedRow,
  } = useLabelHandlers(
    highlights,
    startId,
    endId,
    selectLabel,
    setIsVisible,
    selectedRow,
    setId,
    setStartId,
    setEndId,
    setSelectLabel,
    setSelectedRow,
    setHighlights,
    project_id,
    fileId
  );

  const refFlag = useRef({
    flag: false,
  });
  const lastKeyRef = useRef(null);

  const [completedSteps, setCompletedSteps] = useState(null);
  // Add state to track mouse dragging
  const [isMouseDragging, setIsMouseDragging] = useState(false);

  const {
    handleAlphaTextMouseDown,
    handleAlphaTextMouseEnter,
    handleAlphaTextMouseUp,
  } = useKeyboardHandlers(
    lastKeyRef,
    isVisible,
    setIsVisible,
    startId,
    endId,
    selectedRow,
    newLetters,
    setStartId,
    setEndId,
    setId,
    refFlag,
    setSelectedRow,
    setHighlights,
    selectLabel,
    setSelectLabel,
    completedSteps,
    setCompletedSteps,
    handleLabelSelection, // Add this line
    labels,
    isMouseDragging,
    setIsMouseDragging,
    setShowStepNumber
  );

  const handleMouseEnter = (num) => {
    setIsMouseHovering(num + 1);
  };
  const handleMouseLeave = (a) => {
    setIsMouseHovering(a);
  };

  function getSegments(content) {
    const paragraphs = content;

    const segments = [];
    let currentSegmentWords = [];

    for (const paragraph of paragraphs) {
      const sentences = paragraph.match(/[^.!?]+[.!?]+/g) || [paragraph];

      for (const sentence of sentences) {
        let words = sentence.split(" ");

        if (currentSegmentWords.length + words.length > 150) {
          segments.push(currentSegmentWords.join(" "));
          currentSegmentWords = words;
        } else {
          currentSegmentWords = [...currentSegmentWords, ...words];
        }
      }
    }

    // add the last segment if it exists
    if (currentSegmentWords.length > 0) {
      segments.push(currentSegmentWords.join(" "));
    }

    return segments;
  }

  const HighlightText = ({ text, index, row, highlights }) => {
    // Get all highlights for the given row
    const rowHighlights = highlights.filter((h) => h.startRow === row);

    let highlight;
    let labelStyle = {
      margin: "4px 0",
      padding: "2px 5px", // Default padding
      backgroundColor: "none",
      userSelect: "none", // prevent default text selection
    };

    if (rowHighlights.length > 0) {
      highlight = rowHighlights.find(
        (h) => h.startTokenId <= index && index <= h.endTokenId
      );

      if (highlight) {
        const diff = highlight.endTokenId - highlight.startTokenId;

        if (diff <= 3) {
          labelStyle.padding = "2px 15px";
        }

        labelStyle.backgroundColor =
          highlight.labelId && highlight.clr ? highlight.clr : "#FE8C8E";
      }
    }

    return (
      <div
        className="highlightedText"
        onClick={() => handleAlphaLabelTextClick(highlight)}
        style={labelStyle}
      >
        {text}
      </div>
    );
  };

  const handleAlphaLabelTextClick = (highlight) => {
    //console.log("label clicked ", highlight);
    if (highlight) {
      setAnnotationId(highlight.annotationId);
      setStartId(highlight.startTokenId);
      setEndId(highlight.endTokenId);
      setIsVisible(true);
      const popupId = Math.floor(
        (highlight.endTokenId + 1 + (highlight.startTokenId + 1)) / 2
      );
      setId(popupId);
    }
    // console.log("click ", isVisible, id);
  };

  const handleHighlightChange = (highlight) => {
    setHighlightState(highlight);
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        // Display your data here
        <FileViewerWrapper>
          <table>
            <TableBody className={!isSidebarOpen ? "short_sidebar" : null}>
              {data &&
                data.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => {
                      setSelectedRow(index);
                      setShowStepNumber(1);
                    }}
                    className={selectedRow === index ? "selected" : ""}
                    onMouseEnter={(e) => {
                      handleMouseEnter(index);
                      e.stopPropagation();
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      handleMouseLeave(null);
                    }}
                  >
                    <TableData
                      className={!isSidebarOpen ? "short_sidebar" : null}
                      style={{
                        backgroundColor:
                          (index + 1) & 1
                            ? "#fffbfa"
                            : "rgba(255, 209, 186, 0.2)",
                      }}
                    >
                      {index + 1}
                    </TableData>

                    <TableData
                      className={!isSidebarOpen ? "short_sidebar" : null}
                    >
                      {row.split(" ").map((text, i) => (
                        <React.Fragment key={i}>
                          {selectedRow === index ? (
                            <TextWithAlpha
                              style={{
                                marginTop: "8px",
                              }}
                            >
                              <ParagraphText className="selected_para">
                                <RectangleDivComponent
                                  start_id={startId}
                                  end_id={endId}
                                  text_index={i}
                                  selectLabel={selectLabel}
                                  highlights={highlights}
                                  row={selectedRow}
                                  selectedRow={selectedRow}
                                />

                                <HighlightText
                                  text={text}
                                  index={i}
                                  row={selectedRow}
                                  highlights={highlights}
                                  handleAlphaLabelTextClick={
                                    handleAlphaLabelTextClick
                                  }
                                  setHighlights={setHighlights}
                                />
                              </ParagraphText>
                              <AlphaTextComponent
                                alphaKey={newLetters[i]}
                                start_id={startId}
                                end_id={endId}
                                text_index={i}
                                selectLabel={selectLabel}
                                highlights={highlights}
                                row={selectedRow}
                                onHighlightChange={handleHighlightChange}
                                handleAlphaLabelTextClick={
                                  handleAlphaLabelTextClick
                                }
                                selectedRow={selectedRow}
                                newLetters={newLetters}
                                onMouseDown={handleAlphaTextMouseDown}
                                onMouseEnter={handleAlphaTextMouseEnter}
                                onMouseUp={handleAlphaTextMouseUp}
                              />
                              {id === i && (
                                <LabelsPopup
                                  screen="preview"
                                  isVisible={isVisible}
                                  setIsVisible={setIsVisible}
                                  handleLabelSelection={handleLabelSelection}
                                  labels={labels}
                                  handleDeleteClick={handleDeleteClick}
                                  setHighlights={setHighlights} // Pass setHighlights function
                                  highlights={highlights}
                                  selectLabel={selectLabel}
                                  annotationId={annotationId}
                                />
                              )}
                            </TextWithAlpha>
                          ) : (
                            <ParagraphText>
                              <HighlightText
                                text={text}
                                index={i}
                                row={index}
                                highlights={highlights}
                                handleAlphaLabelTextClick={
                                  handleAlphaLabelTextClick
                                }
                                setHighlights={setHighlights}
                              />
                            </ParagraphText>
                          )}
                        </React.Fragment>
                      ))}
                    </TableData>
                  </TableRow>
                ))}
            </TableBody>
          </table>
        </FileViewerWrapper>
      )}
    </div>
  );
};

export default FileViewer;
