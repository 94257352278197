import React, { useEffect, useState } from "react";
import {
  Container,
  Title,
  Text,
  SupportingText,
  LoginForm,
  Input,
  Label,
  RememberText,
  SignUpControl,
  CheckBox,
  ForgotPassText,
  Div,
  SignInButton,
  GoogleSignInButton,
  GoogleIcon,
  GoogleButtonText,
  RememberWrapper,
  InputWithIcon,
  HintText,
  AlertIcon,
} from "./LoginStyles";
import { ReactComponent as Logo } from "../../assets/logos/logo-mobile.svg";
import { ReactComponent as GoogleSignIn } from "../../assets/GoogleSignInIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import validator from "validator"; // Import validator

import {
  logInWithEmailAndPassword,
  onAuthChanged,
  signInWithGoogle,
} from "../../app/Actions/authActions";
import { ReactComponent as Alert } from "../../assets/Alert-CircleIcon.svg";
import Cookies from "js-cookie";

// Define a React component called Login
const Login = ({
  LogInAPI, // Function to authenticate user with email and password
  authMessage, // Error message returned from authentication API
  currentUser, // Information about currently logged-in user
  onAuthChanged, // Function to check the user's login status
  SignInWithGoogleAPI, // Function to authenticate user with Google
  emailVerificationSent,
}) => {
  // Define state variables for email and password input fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  // Define a navigation hook to redirect the user to other pages
  const navigate = useNavigate();
  const localUser = localStorage.getItem("user");

  const handleForgotPassword = () => {
    console.log("Forgot Password Clicked", email);
    // navigate("/forgot-password");
    navigate("/forgot-password", { state: { email } });
  };

  // Define a function to validate email input field
  /*const validateEmail = (emailText) => {
    if (!validator.isEmail(emailText)) {
      // Use validator's isEmail method
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };*/
  const validateEmail = (emailText) => {
    if (emailText === "") {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(emailText)) {
      if (!/@/.test(emailText)) {
        setEmailError('Email is missing "@" symbol');
      } else if (!/\.\S+/.test(emailText)) {
        setEmailError('Email is missing ".com"');
      }
    } else if (authMessage === "auth/invalid-email") {
      setEmailError("Email is not valid");
    } else if (authMessage === "auth/user-not-found") {
      setEmailError("user not found");
    } else {
      setEmailError("");
    }
  };

  // Define a function to validate password input field
  const validatePassword = (passwordText) => {
    //console.log("Pass validation started");
    if (passwordText === "") {
      setPasswordError("Password cannot be empty");
    } //else if (authMessage?.props?.error?.code === "auth/wrong-password") {
    else if (authMessage === "auth/wrong-password") {
      setPasswordError("wrong password");
    } else {
      setPasswordError("");
    }
  };
  const checkAndNavigate = (currentPath, targetPath) => {
    if (currentPath !== targetPath) {
      navigate(targetPath);
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentUser && currentUser.emailVerified) {
      console.log("email  verifie  login redirecting to home", currentUser);
      checkAndNavigate(currentPath, "/home");
      // Clear input fields

      setEmail("");
      setPassword("");
    } else if (
      currentUser &&
      !currentUser.emailVerified &&
      emailVerificationSent
    ) {
      checkAndNavigate(currentPath, "/verify-email");
    } else if (currentUser && !currentUser.emailVerified) {
      console.log("Email not verified. Stay on sign in");
    }
  }, [currentUser, emailVerificationSent]); // Added 'emailVerificationSent' to the dependency array

  // Separate useEffect for authMessage and handling errors
  useEffect(() => {
    //console.log("login currectuser", currentUser, authMessage);

    // If there is an authentication error, validate input fields
    if (!currentUser && authMessage !== null) {
      validateEmail(email);
      validatePassword(password);
    }

    // get the data from cookies and set to variables
    const emailCookie = Cookies.get("email");
    const passwordCookie = Cookies.get("password");

    const isCheckedCookie = emailCookie && passwordCookie;

    emailCookie !== undefined && setEmail(emailCookie);
    passwordCookie !== undefined && setPassword(passwordCookie);
    Boolean(isCheckedCookie) === true && setIsChecked(true);

    // auto-login code commented out since storing passwords in cookies is not secure
    // consider using refresh tokens or another secure method if you need this functionality
  }, [authMessage]);

  // Define a function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateEmail(email);
    validatePassword(password);
    await LogInAPI(email, password);
  };

  // Define a function to handle Google sign-in
  const handleGoogleSignIn = (e) => {
    e.preventDefault();
    console.log("call to google sign in popup from login");

    SignInWithGoogleAPI();
  };

  // Define a function to handle checkbox click
  const handleCheckBoxClick = (e) => {
    if (e.target.checked && email && password) {
      //console.log("✅ Checkbox is checked");
      Cookies.set("email", email, { expires: 7 }); // store email in cookie for 7 days
      Cookies.set("password", password, { expires: 7 }); // store password in cookie for 7 days
    } else {
      //console.log("⛔️ Checkbox is NOT checked");
    }
    setIsChecked(!isChecked);
  };

  // Render the login form using styled components and React Router links
  return (
    <Container>
      <Logo />
      <Title>
        <Text>Login in to your account</Text>
        <SupportingText>
          {`Welcome back! Please enter your details.`}
        </SupportingText>
      </Title>
      <LoginForm>
        <Label>Email</Label>
        <InputWithIcon>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // required
            style={{ borderColor: emailError ? "#FDA29B" : "" }}
          />
          {emailError && <AlertIcon as={Alert} />}
        </InputWithIcon>
        {emailError.length !== 0 && <HintText>{emailError}</HintText>}
        <Label>Password</Label>
        <InputWithIcon>
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            //required
            style={{ borderColor: passwordError ? "#FDA29B" : "" }}
          />
          {passwordError && <AlertIcon as={Alert} />}
        </InputWithIcon>
        {passwordError.length !== 0 && <HintText>{passwordError}</HintText>}
        <Div>
          <RememberWrapper>
            <CheckBox
              type="checkbox"
              value={isChecked}
              onChange={handleCheckBoxClick}
            />
            <RememberText>Remember Me</RememberText>
          </RememberWrapper>
          <ForgotPassText
            onClick={() => {
              handleForgotPassword();
            }}
          >
            Forgot Password
          </ForgotPassText>{" "}
        </Div>
        <SignInButton type="submit" onClick={handleSubmit}>
          Sign in
        </SignInButton>
        <GoogleSignInButton>
          <GoogleIcon
            // Use the GoogleSignIn icon from the SVG file
            as={GoogleSignIn}
          />
          <GoogleButtonText onClick={handleGoogleSignIn}>
            Sign in with Google
          </GoogleButtonText>
        </GoogleSignInButton>
      </LoginForm>
      <SignUpControl>
        Don't have an account?{" "}
        <ForgotPassText
          onClick={() => {
            handleForgotPassword();
          }}
        >
          Forgot Password
        </ForgotPassText>
      </SignUpControl>
    </Container>
  ); //: (
  // <Container>loading...</Container>
  //);
};

// Use the connect function from react-redux to connect the Login component to the Redux store
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    authMessage: state.auth.authMessage,
    emailVerificationSent: state.auth.emailVerificationSent,
  };
};

// Define functions to dispatch actions to the Redux store
const mapDispatchToProps = (dispatch) => ({
  LogInAPI: (email, password) =>
    dispatch(logInWithEmailAndPassword(email, password)),
  SignInWithGoogleAPI: () => dispatch(signInWithGoogle()),
  // onAuthChanged: () => dispatch(onAuthChanged()),
});

// Connect the Login component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(Login);
