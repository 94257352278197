import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase"; // make sure 'db' is the correct export from your firebase configuration
import * as types from "./actionTypes";
import { nanoid } from "nanoid";

const firestore = db; // If 'db' is the correct Firestore instance from your firebase configuration

// Action Creators

export const addAnnotation = (annotation) => {
  return async (dispatch) => {
    try {
      var newAnnotationId;
      if (annotation.annotationId == null) {
        newAnnotationId = nanoid();
        annotation.annotationId = newAnnotationId;
      }

      // Convert the Date object to a timestamp or string for serialization
      if (annotation.createdDate && annotation.createdDate instanceof Date) {
        annotation.createdDate = annotation.createdDate.toISOString();
      }
      // Add to Firestore under the 'annotations' sub-collection of the project
      const annotationRef = doc(
        collection(firestore, "projects", annotation.projectId, "annotations"),
        newAnnotationId
      );
      await setDoc(annotationRef, annotation);

      // Add to Firestore under the 'annotations' sub-collection of the specific file
      const fileAnnotationRef = doc(
        collection(
          firestore,
          "projects",
          annotation.projectId,
          "files",
          annotation.fileId,
          "annotations"
        ),
        newAnnotationId
      );
      await setDoc(fileAnnotationRef, annotation);

      // If successful, dispatch to Redux
      dispatch({
        type: types.ADD_ANNOTATION,
        payload: {
          annotation: { ...annotation },
        },
      });

      return newAnnotationId; // Return the new annotation ID if successful
    } catch (error) {
      console.error("Error adding annotation: ", error);
      return null; // Return null if there's an error
    }
  };
};

export const removeAnnotation = (annotation) => {
  return async (dispatch) => {
    try {
      // Convert the Date object to a timestamp or string for serialization
      if (annotation.createdDate && annotation.createdDate instanceof Date) {
        annotation.createdDate = annotation.createdDate.toISOString();
      }

      // Remove from Firestore from both 'annotations' and the specific file's 'annotations'
      const annotationRef = doc(
        collection(firestore, "projects", annotation.projectId, "annotations"),
        annotation.annotationId
      );
      await deleteDoc(annotationRef);

      const fileAnnotationRef = doc(
        collection(
          firestore,
          "projects",
          annotation.projectId,
          "files",
          annotation.fileId,
          "annotations"
        ),
        annotation.annotationId
      );
      await deleteDoc(fileAnnotationRef);

      // If successful, dispatch to Redux
      dispatch({
        type: types.REMOVE_ANNOTATION,
        payload: {
          annotation: { ...annotation },
        },
      });

      return true; // Return true if removal was successful
    } catch (error) {
      console.error("Error removing annotation: ", error);
      return false; // Return false if there was an error
    }
  };
};

export const setAnnotationsAction = (projectId, fileId, annotations) => {
  return {
    type: types.SET_ANNOTATIONS,
    payload: {
      projectId,
      fileId,
      annotations,
    },
  };
};

export const updateAnnotation = (annotation, updatedData) => {
  return async (dispatch) => {
    try {
      // Update in Firestore in both 'annotations' and the specific file's 'annotations'
      const annotationRef = doc(
        collection(firestore, "projects", annotation.projectId, "annotations"),
        annotation.annotationId
      );
      await updateDoc(annotationRef, updatedData);

      const fileAnnotationRef = doc(
        collection(
          firestore,
          "projects",
          annotation.projectId,
          "files",
          annotation.fileId,
          "annotations"
        ),
        annotation.annotationId
      );
      await updateDoc(fileAnnotationRef, updatedData);

      // If successful, dispatch to Redux
      dispatch({
        type: types.UPDATE_ANNOTATION,
        payload: {
          projectId: annotation.projectId,
          fileId: annotation.fileId,
          annotationId: annotation.annotationId,
          updatedData,
        },
      });

      return true; // Return true if update was successful
    } catch (error) {
      console.error("Error updating annotation: ", error);
      return false; // Return false if there was an error
    }
  };
};

export const clearAnnotations = () => {
  return {
    type: types.CLEAR_ANNOTATIONS,
  };
};
