import styled from "styled-components";

export const ProfileSettingsContainer = styled.div`
  display: flex;
  position: fixed; // Change from absolute to fixed
  flex-direction: column;
  align-items: center;
  background-color: #f9fafb;
  top: 80px;
  height: calc(100vh - 80px); // Adjust height to account for the top offset
  width: 380px;
  right: 0;
  transition: right 5s;
  caret-color: transparent;
  overflow-y: auto;
`;

export const ProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eaecf0;
  padding-top: 32px;
  padding-bottom: 24px;
  width: 380px;
  position: relative;
`;

export const HelloText = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #101828;
  margin: 0 0 6px;
`;

export const NameText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #e26d5c;
  margin: 0;
`;

export const EmailText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  //margin: 0 0 4.38vh; //0 0 41.95px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 320px;
  margin-top: 4.38vh;
`;

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 0 0 6px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
`;

export const MailIcon = styled.svg`
  position: absolute;
  width: 16.67px;
  height: 13.33px;
  margin-left: 15.67px;
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px 10px 40px;
  //gap: 8px;
  width: 266px;
  background: #f9fafb;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  caret-color: #667085;

  &:focus {
    outline: none;
  }
`;

export const ButtonControls = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  width: 156px;
  height: 40px;
  margin: 3.24vh 0 0; //31px 0 0;
  position: relative;
`;

export const CancelButton = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  margin: 0 12px 0 0; // margin left only
  width: 79px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f0f1f3;
  }
`;

export const SaveButton = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  height: 40px;
  background: #ef7866;
  border: 1px solid #ef7866;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #cc5b4a;
  }
`;

export const TextAndSupportingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 343px;
  height: 72px;
  margin: 3.13vh 0 0;
`;

export const Text = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #101828;
  margin: 0 0 4px;
`;

export const SupportingText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin: 0 0 8px;
`;

export const HeaderForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 343px;
`;

export const HeaderLabel = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 20px 0 6px;
  align-self: flex-start;
`;

export const HeaderInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;
  width: 315px; // total width = 343 - 14*2 (padding)
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  margin: 0;
  caret-color: #667085;
  align-self: center;

  &:focus {
    outline: none;
  }
`;

export const HintText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin: 6px 0 0;
  align-self: flex-start;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  height: 40px;
  margin: 4.28vh 0 0;
  position: relative;
`;
