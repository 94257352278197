/**
 * Sign in with email nd password
 * Login with email and password
 * Sign in with google
 * Update user state - on auth changed
 * Password Reset
 * Logout
 * Email verification
 * Update email
 * Update name
 * send error to error handler
 *
 * TO-DO: update loading state
 */
import { auth, db, googleProvider } from "../firebase";
import * as types from "./actionTypes";
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
  updateEmail,
  updateProfile,
  sendEmailVerification,
  updatePassword,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";
import {
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import AuthErrorHandler from "../AuthErrorHandler";
import {
  fetchProjectsByCreatedBy,
  fetchProjectsByUserID,
  mergeProjectsArrays,
} from "./projectActions";

export const loading = (status) => {
  return {
    type: types.SET_LOADING_STATUS,
    status,
  };
};

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url:
    process.env.NODE_ENV === "production"
      ? "http://app.annoberry.com/verify-email"
      : "http://localhost:3000/verify-email",
  // : "http://localhost:3000/finishSignUp",

  // This must be true.
  handleCodeInApp: true,
};

export const register = (name, email, password) => async (dispatch) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // add user in database 'users'
        const usersCollectionRef = collection(db, "users"); // create ref to firestore collection
        const userId = userCredential.user.uid;
        const createdDate = new Date().toISOString();

        const userData = {
          name: name,
          email: email,
          password: password,
          user_id: userId,
          totalProject: 0,
          totalTags: 0,
          bio: "",
          profilePicture: null,
          projects: [],
          createdDate: createdDate,
          emailVerified: false, // initially set to false
        };

        const userDocRef = doc(usersCollectionRef, userId);

        setDoc(userDocRef, userData)
          .then(async () => {
            // document created successfully
            dispatch({
              type: types.SIGNUP_SUCCESS,
              payload: "",
            });
            // update name
            updateProfile(userCredential.user, {
              displayName: name,
            });
            dispatch({
              type: types.SET_USER,
              user: userData,
            });

            // send email verification on new registration
            await sendEmailVerification(userCredential.user, actionCodeSettings)
              .then(() => {
                console.log("Email verification sent!");
                dispatch({
                  type: types.EMAIL_VERIFICATION_SENT,
                  payload: "",
                });
              })
              .catch((err) => {
                // console.log("Error while email verifying...\n", err);
              });
          })
          .catch((err) => {
            // handle errors here
            handleError(err, dispatch, types.SIGNUP_ERROR); // a function that logs the error and then dispatches
          });
      })
      .catch((err) => {
        console.log("Err at registration: ", err);
        handleError(err, dispatch, types.SIGNUP_ERROR); // a function that logs the error and then dispatches
      });
  } catch (err) {
    // This was missing in your code
    console.log(err);
    handleError(err, dispatch, types.SIGNUP_ERROR); // a function that logs the error and then dispatches
  }
};

export const logInWithEmailAndPassword =
  (email, password) => async (dispatch) => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // <-- Added 'async' here
          // Signed in
          const user = userCredential.user;
          console.log("Auth Changed user (login): ", user);
          const simplifiedUser = {
            // firebaseUser: user, // This is the original Firebase user object
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            accessToken: user.accessToken,
            photoURL: user.photoURL,
            phoneNumber: user.phoneNumber,
            name: user.name, // Add fetched name to the user object
            emailVerified: user.emailVerified,
          };

          dispatch({
            type: types.SET_USER,
            user: simplifiedUser,
          });

          dispatch({
            type: types.LOGIN_SUCCESS,
            payload: null,
          });
          console.log("\nUser logged in!");

          if (!user.emailVerified) {
            // send email verification on new registration
            await sendEmailVerification(user, actionCodeSettings)
              .then(() => {
                console.log("Email verification sent!");
                dispatch({
                  type: types.EMAIL_VERIFICATION_SENT,
                  payload: "",
                });
              })
              .catch((err) => {
                console.log("Error while email verifying...\n", err);
              });
          }
        })
        .catch((err) => {
          // console.log("Err in login with email & pass: ", err.message);
          dispatch({
            type: types.LOGIN_ERROR,
            payload: err.code,
          });
        });
    } catch (err) {
      dispatch({
        type: types.LOGIN_ERROR,
        payload: err.code,
      });
    }
  };

// export const signInWithGoogle = () => async (dispatch) => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;

//     const usersCollectionRef = collection(db, "users");
//     const userDocRef = doc(usersCollectionRef, user.uid);
//     const createdDate = new Date().toISOString();

//     const userData = {
//       name: user.displayName,
//       email: user.email,
//       user_id: user.uid,
//       totalProject: 0,
//       totalTags: 0,
//       bio: "",
//       profilePicture: user.photoURL,
//       projects: [],
//       createdDate: createdDate,
//       emailVerified: user.emailVerified,
//     };

//     const userDoc = await getDoc(userDocRef);

//     if (userDoc.exists()) {
//       console.log("User already exists");
//     } else {
//       await setDoc(userDocRef, userData);
//       console.log("Document successfully written!");

//       // Dispatch actions similar to email registration
//       dispatch({
//         type: types.SIGNUP_SUCCESS,
//         payload: "",
//       });

//       // Update the display name in the user profile
//       await updateProfile(user, {
//         displayName: user.displayName,
//       });

//       dispatch({
//         type: types.SET_USER,
//         user: userData,
//       });

//       if (!user.emailVerified) {
//         // Your code to handle email verification if necessary
//         console.log("Email not verified for Google user");
//         // Similar to the email registration, you can also dispatch EMAIL_VERIFICATION_SENT
//       }
//     }

//     // The SIGNIN_SUCCESS action, if you wish to keep it separate from SIGNUP_SUCCESS
//     dispatch({
//       type: types.SIGNIN_SUCCESS,
//       payload: null,
//     });
//   } catch (error) {
//     console.error(error);
//     handleError(error, dispatch, types.SIGNIN_ERROR);
//   }
// };

export const signInWithGoogle = () => async (dispatch) => {
  try {
    console.log("call to google sign in popup from auth actions");

    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    const usersCollectionRef = collection(db, "users");
    const userDocRef = doc(usersCollectionRef, user.uid);
    const createdDate = new Date().toISOString();

    const userData = {
      name: user.displayName,
      email: user.email,
      user_id: user.uid,
      totalProject: 0,
      totalTags: 0,
      bio: "",
      profilePicture: user.photoURL,
      projects: [],
      createdDate: createdDate,
      emailVerified: user.emailVerified,
    };

    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      // console.log("User already exists");
    } else {
      await setDoc(userDocRef, userData);
      console.log("Document successfully written!");

      // Dispatch actions similar to email registration
      dispatch({
        type: types.SIGNUP_SUCCESS,
        payload: "",
      });

      // Update the display name in the user profile
      await updateProfile(user, {
        displayName: user.displayName,
      });

      dispatch({
        type: types.SET_USER,
        user: userData,
      });

      if (!user.emailVerified) {
        // Your code to handle email verification if necessary
        //show error on UI : Email not verified for Google user"

        console.log("Email not verified for Google user");
        // Similar to the email registration, you can also dispatch EMAIL_VERIFICATION_SENT
      }
    }

    // The SIGNIN_SUCCESS action, if you wish to keep it separate from SIGNUP_SUCCESS
    dispatch({
      type: types.SIGNIN_SUCCESS,
      payload: null,
    });
  } catch (error) {
    console.error(error);
    handleError(error, dispatch, types.SIGNIN_ERROR);
  }
};

//To-do : setup userAction & userReducer in different files

let isAuthListenerSet = false;

export const onAuthChanged = () => async (dispatch) => {
  if (!isAuthListenerSet) {
    isAuthListenerSet = true;

    onAuthStateChanged(auth, async (user) => {
      console.log("user in Auth: ", user);
      if (user) {
        // Check if email is verified
        if (user.emailVerified) {
          // Fetch user data from Firestore using UID
          const userRef = doc(collection(db, "users"), user.uid);
          try {
            const userSnapshot = await getDoc(userRef);
            if (userSnapshot.exists()) {
              const userData = userSnapshot.data();
              const { name, profilePicture } = userData;

              // Create a simplified user object with necessary data including name
              const simplifiedUser = {
                // firebaseUser: user, // This is the original Firebase user object
                uid: user.uid,
                email: user.email,
                displayName: user.displayName,
                accessToken: user.accessToken,
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber,
                name: name, // Add fetched name to the user object
                profilePicture: profilePicture,
                emailVerified: user.emailVerified,
              };

              dispatch({
                type: types.SET_USER,
                user: simplifiedUser,
              });

              localStorage.setItem("user", JSON.stringify(simplifiedUser));
            } else {
              console.log("User data not found in Firestore.");
            }
          } catch (error) {
            console.error("Error fetching user data from Firestore:", error);
          }
        } else {
          console.log("Email not verified. Please verify your email.");
          // const simplifiedUser = {
          //   firebaseUser: user, // This is the original Firebase user object
          // };

          // dispatch({
          //   type: types.UPDATE_FIREBASE_USER,
          //   user: simplifiedUser,
          // });

          // Optionally, you can dispatch a different action here to handle unverified emails
          // dispatch({ type: types.UNVERIFIED_EMAIL, user: user });
        }
      } else {
        // Handle the case when the user is not signed in if necessary
        // e.g., dispatch({ type: types.NO_USER });
      }
    });
  }
};

export const sendPasswordReset = (email) => async (dispatch) => {
  try {
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch({
          type: types.RESET_SUCCESS,
          payload: null,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.RESET_ERROR,
          payload: err.message || err.code, // store only the message or error code
        });
      });
    //alert("Password reset link sent!");
  } catch (err) {
    // console.log("error at password reset: ", err.message);
    dispatch({
      type: types.RESET_ERROR,
      payload: err.message || err.code, // store only the message or error code
    });
  }
};

export const updateUserPassword = (user, newPassword) => async (dispatch) => {
  try {
    const docRef = doc(db, "users", user.uid);
    const data = {
      password: newPassword,
    };
    updateDoc(docRef, data)
      .then(() => {
        // console.log("password updated in docs");
      })
      .catch((err) => {
        const msg = AuthErrorHandler({ err: err.code });
        console.log("msg in email update: ", msg);
      });
    updatePassword(user, newPassword)
      .then(() => {
        // Update successful.
        dispatch({
          type: types.UPDATE_PASSWORD_SUCCESS,
          payload: null,
        });
      })
      .catch((err) => {
        // An error ocurred
        dispatch({
          type: types.UPDATE_PASSWORD_ERROR,
          payload: err.code,
        });
      });
  } catch (err) {
    handleError(err, dispatch, types.UPDATE_PASSWORD_ERROR); // a function that logs the error and then dispatches
  }
};

export const setEmail = (email) => async (dispatch) => {
  // console.log("set email is called...", email);
  dispatch({
    type: types.SET_EMAIL,
    email,
  });
};

export const confirmPasswordResetHandler =
  (oobCode, newPassword) => async (dispatch) => {
    try {
      // console.log("oobCode & new pass : ", oobCode, " -- ", newPassword);

      // Verify the password reset code and get the user's email
      const email = await verifyPasswordResetCode(auth, oobCode);
      // console.log("email- pass reset: ", email);

      // Complete the password reset process
      await confirmPasswordReset(auth, oobCode, newPassword);

      // console.log("password reset successful...");
      dispatch(setEmail(email));
      dispatch({ type: types.UPDATE_PASSWORD_SUCCESS, payload: null });
    } catch (error) {
      // console.error("Error confirming password reset:", error);
      // Handle the error and dispatch an error action if needed
      dispatch({
        type: types.UPDATE_PASSWORD_ERROR,
        payload: error.code,
      });
    }
  };

export const logout = () => async (dispatch) => {
  try {
    signOut(auth)
      .then(() => {
        onAuthStateChanged(auth, (user) => {
          if (!user) {
            // console.log("Auth Changed user (logout): ", user);
            dispatch({
              type: types.LOGOUT_SUCCESS,
              payload: user,
            });
            dispatch({
              type: types.EMAIL_VERIFICATION_UNSENT,
              payload: "",
            });
            localStorage.clear();
          }
        });
      })
      .catch((err) => {
        // console.log("Err at logout: ", err.message);
        dispatch({
          type: types.LOGOUT_ERROR,
          payload: err.code,
        });
      });
  } catch (err) {
    // console.log("Err at logout: ", err.message);
    dispatch({
      type: types.LOGOUT_ERROR,
      payload: err.code,
    });
  }
};

export const sendUserEmailVerification = async (user) => {
  await sendEmailVerification(user)
    .then(() => {
      // console.log("Email verification sent!");
    })
    .catch((err) => {
      // console.log("Error while email verifying...", err);
    });
};

export const updateUserEmail = async (user, email) => {
  const q = query(collection(db, "users"), where("uid", "==", user.uid));
  const docs = await getDocs(q);
  //console.log("doc from firestore: ", docs.docs[0].data().password);
  const tempPassword = docs.docs[0].data().password;
  const tempEmail = docs.docs[0].data().email;

  const res = await signInWithEmailAndPassword(auth, tempEmail, tempPassword);
  const getUser = res.user;
  await updateEmail(getUser, email)
    .then((res) => {
      // Email updated!
      // console.log("email updated!", res);
    })
    .catch((error) => {
      // An error occurred
      const msg = AuthErrorHandler({ error: error.code });
      console.log("msg in email update: ", msg);
    });
};

export const updateUserProfilePicture =
  (currentUser, downloadURL) => async (dispatch) => {
    /* await updateProfile(auth.currentUser, {
    photoURL: photoUrl, //"https://example.com/jane-q-user/profile.jpg",
  })
    .then(() => {
      // Profile updated!
    //console.log("Profile updated");
    })
    .catch((error) => {
      // An error occurred
      const msg = <AuthErrorHandler error={error} />;
    //console.log("msg in profile picture update: ", msg);
    }); */
    try {
      const docRef = doc(db, "users", currentUser.uid);
      const data = {
        profilePicture: downloadURL,
      };

      await updateDoc(docRef, data);
      // console.log("profile pic updated in Firestore docs");

      dispatch({
        type: types.UPDATE_PROFILE_PICTURE,
        downloadURL,
      });

      // console.log("Profile picture updated successfully");
    } catch (error) {
      // console.error("Error updating profile picture:", error);
    }
  };

export const updateUserName = (user, name) => async (dispatch) => {
  try {
    const docRef = doc(db, "users", user.uid);
    const data = {
      name: name,
    };

    await updateDoc(docRef, data);
    // console.log("Name updated in Firestore docs");

    // await updateProfile(user, {
    //   displayName: name, //"Jane Q. User",
    // });

    dispatch({
      type: types.UPDATE_USER_NAME,
      name,
    });

    // console.log("Display name updated in user profile");
  } catch (error) {
    // console.error("Error updating user data:", error);
  }
};

export const getTotalProject = (user) => async (dispatch) => {
  try {
    /* const dbRef = collection(db, "projects");
    const q = query(dbRef, where("createdBy", "==", user.uid));
    const querySnapshot = await getDocs(q);
    const fetchedData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));*/
    //console.log("projects snapshots: ", fetchedData.length);

    //dispatch(setTotalProject(fetchedData.length, user.uid));

    Promise.all([
      fetchProjectsByCreatedBy(user.uid),
      fetchProjectsByUserID(user.uid),
    ])
      .then(([projectsCreatedBy, projectsByUserID]) => {
        const mergedProjects = mergeProjectsArrays(
          projectsCreatedBy,
          projectsByUserID
        );
        // console.log("Merged Projects Array (total project):", mergedProjects);
        dispatch(setTotalProject(mergedProjects.length, user.uid));
      })
      .catch((error) => {
        console.error("Error fetching and merging projects:", error);
      });

    dispatch({
      type: types.GET_TOTAL_PROJECT,
    });
  } catch (error) {
    // console.log("error at get total project: ", error.message);
  }
};

export const setTotalProject = (totalProject, uid) => async (dispatch) => {
  dispatch({
    type: types.SET_TOTAL_PROJECT,
    totalProject,
  });

  // console.log("Document data:", totalProject);
  const docRef = doc(db, "users", uid);
  await updateDoc(docRef, {
    totalProject,
  });
};

const handleError = (error, dispatch, actionType) => {
  console.error(error);
  console.log("error code: ", error.code);
  dispatch({ type: actionType, payload: error.code });
  dispatch(loading(false)); // set loading status to false
};

export const handleInitialData = () => async (dispatch) => {
  dispatch(loading(true));

  dispatch(loading(false));
};
