import React, { useEffect, useState } from "react";
import {
  Content,
  Div,
  DropDownDivWrapper,
  DropDownIcon,
  HeadingAndSupportingText,
  HomeContainer,
  HorizontalTabs,
  Input,
  InputDropdownWrapper,
  InputSearchWrapper,
  MoreIcon,
  NewProjectButton,
  PlusIcon,
  ProgressBar,
  ProgressBarWrapper,
  ProgressPercentage,
  ProjectCard,
  ProjectDetails,
  ProjectQueueContainer,
  SearchIcon,
  SortAndSearch,
  SupportingText,
  TabButton,
  TabsAndSearch,
  Text,
  TextAndProjectDetails,
  TotalProject,
} from "./HomeExistingUserStyles";
import { ReactComponent as Plus } from "../../assets/PlusIcon-newproject.svg";
import { ReactComponent as Dropdown } from "../../assets/DropdownIcon.svg";
import { ReactComponent as Search } from "../../assets/SearchIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ProjectPlus } from "../../assets/PlusIcon.svg";
import { ReactComponent as More_vertical } from "../../assets/MoreVerticalIcon.svg";
import Header from "../../Header/Header";
import {
  _createNewProject,
  _deleteProject,
  _getProject,
  activeProjectId,
  fetchProjectsByCreatedBy,
  fetchProjectsByUserID,
  mergeProjectsArrays,
} from "../../app/Actions/projectActions";
import { connect } from "react-redux";
import DropDownMenu from "./DropDownMenu";
import ShareProjectModal from "../ShareProject/ShareProjectModal";

const HomeExistingUser = ({
  currentUser,
  totalProject,
  activeProjectIdAPI,
  getProjectAPI,
  createNewProjectAPI,
  deleteProjectAPI,
  activeProject_id,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showMoreIndex, setShowMoreIndex] = useState(null);
  const [projectData, setProjectData] = useState([]);
  const [isSortingType, setIsSortingType] = useState("Newest first");
  const [searchInput, setSearchInput] = useState("");
  const [isProjectDeleted, setIsProjectDeleted] = useState(false);
  const [isShareProjectModalOpen, setIsShareProjectModalOpen] = useState(false);

  const navigate = useNavigate();
  const TabButtonsArray = [
    "All Projects",
    "Created by me",
    "Created by others",
  ];

  const fetchProjects = async () => {
    if (TabButtonsArray[selectedTab] === "All Projects") {
      //console.log("all projects are fetched");
      //setProjectData([]);
      try {
        Promise.all([
          fetchProjectsByCreatedBy(currentUser.uid),
          fetchProjectsByUserID(currentUser.uid),
        ])
          .then(([projectsCreatedBy, projectsByUserID]) => {
            const mergedProjects = mergeProjectsArrays(
              projectsCreatedBy,
              projectsByUserID
            );
            //console.log("Merged Projects Array:", mergedProjects);
            const filteredProjects = mergedProjects.filter((project) =>
              project.name.toLowerCase().includes(searchInput.toLowerCase())
            );
            setProjectData(filteredProjects);
          })
          .catch((error) => {
            console.error("Error fetching and merging projects:", error);
          });
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    }
    if (TabButtonsArray[selectedTab] === "Created by me") {
      //console.log("created by me projects fetched");
      const fetchedData = await fetchProjectsByCreatedBy(currentUser.uid);

      const filteredProjects = fetchedData.filter((project) =>
        project.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setProjectData(filteredProjects);
      //setProjectData(fetchedData);
    }
    if (TabButtonsArray[selectedTab] === "Created by others") {
      //console.log("created by others projects fetched");
      const fetchedData = await fetchProjectsByUserID(currentUser.uid);
      const filteredProjects = fetchedData.filter((project) =>
        project.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setProjectData(filteredProjects);
    }
  };

  const sortProjects = (projects) => {
    if (isSortingType === "Newest first") {
      //console.log("projects sorted as newest first ", projects);

      const sortedProjects = [...projects].sort(
        (a, b) =>
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
      setProjectData(sortedProjects);
    }
    if (isSortingType === "Oldest first") {
      //console.log("projects sorted as oldest first ", projects);

      const sortedProjects = [...projects].sort(
        (a, b) =>
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
      );
      setProjectData(sortedProjects);
    }
    if (isSortingType === "Arrange alphabetically") {
      //console.log("projects sorted as arrange alpha.. ", projects);
      const sortedProjects = [...projects].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setProjectData(sortedProjects);
    }
  };

  const handleSelectedHorizontalTabClick = async (index) => {
    setSelectedTab(index);
  };

  const handleSortingSelection = (event) => {
    setIsSortingType(event.target.value);
  };

  useEffect(() => {
    fetchProjects();
  }, [selectedTab, searchInput, isProjectDeleted]); // Fetch project data when selectedTab changes

  useEffect(() => {
    sortProjects(projectData);
  }, [isSortingType, searchInput]); // Sort project data when isSortingType changes

  const handleCreateNewProjectClick = () => {
    createNewProjectAPI();
  };

  const handleMoreIconClick = (num) => {
    setShowMoreIndex(num);
    setShowMore(!showMore);
    setIsProjectDeleted(false);
  };

  const handleProjectCardClick = (projectId, draft, event) => {
    // Prevent the event from bubbling up and triggering the click on the "More" icon
    event.stopPropagation();

    // Open the options panel if the user clicked on the "More" icon
    if (event.target.classList.contains("more-icon")) {
      setShowMore(!showMore);
      setIsProjectDeleted(false);
    } else {
      // Navigate to the appropriate screen when clicking on any other part of the project card
      if (draft) {
        navigate("/upload-files", { state: { screen: "add-label" } });
      } else {
        navigate("/page-label");
      }
      activeProjectIdAPI(projectId);
      getProjectAPI(projectId, draft);
    }
  };

  const handleOpenProjectClick = async (projectId, draft) => {
    await activeProjectIdAPI(projectId);
    if (draft) {
      navigate("/upload-files", { state: { screen: "add-label" } });
    } else {
      navigate("/page-label");
    }
    await getProjectAPI(projectId, draft);
    setShowMore(!showMore);
  };

  const handleShareProjectClick = (projectId) => {
    activeProjectIdAPI(projectId);
    setShowMore(!showMore);
    setIsShareProjectModalOpen(true);
  };

  const handleExportProjectClick = () => {};

  const handleDeleteProjectClick = async (project_Id) => {
    //console.log("delete project: ", project_Id);
    await deleteProjectAPI(project_Id);
    setIsProjectDeleted(true); // Set the flag to true after deletion
    setShowMore(!showMore);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <React.Fragment>
      <Header screen="home" />
      <HomeContainer>
        <Content>
          <HeadingAndSupportingText>
            <TextAndProjectDetails>
              <SupportingText>Home</SupportingText>
              <ProjectDetails>
                <TotalProject className="total_project">
                  Total projects :
                </TotalProject>
                <TotalProject className="total_project_count">
                  {!totalProject ? "0" : totalProject}
                </TotalProject>
              </ProjectDetails>
            </TextAndProjectDetails>
            <Link to="/upload-files" style={{ textDecoration: "none" }}>
              <NewProjectButton onClick={handleCreateNewProjectClick}>
                <PlusIcon as={Plus} />
                New Project
              </NewProjectButton>
            </Link>
          </HeadingAndSupportingText>
          <TabsAndSearch>
            <HorizontalTabs>
              {TabButtonsArray.map((tab, index) => (
                <TabButton
                  key={index}
                  className={selectedTab === index ? "selected" : ""}
                  onClick={() => {
                    setSelectedTab(index);
                    handleSelectedHorizontalTabClick(index);
                  }}
                >
                  {tab}
                </TabButton>
              ))}
            </HorizontalTabs>
            <SortAndSearch>
              <InputDropdownWrapper
                value={isSortingType}
                className="type"
                onChange={(e) => handleSortingSelection(e)}
              >
                <option value="Newest first">Newest first</option>{" "}
                {/** desc order */}
                <option value="Oldest first">Oldest first</option>{" "}
                {/** asc order */}
                <option value="Arrange alphabetically">
                  Arrange alphabetically
                </option>
                <DropDownIcon as={Dropdown} />
              </InputDropdownWrapper>
              <InputSearchWrapper>
                <SearchIcon as={Search} />
                <Input
                  className="search_input"
                  placeholder="Search"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
              </InputSearchWrapper>
            </SortAndSearch>
          </TabsAndSearch>
        </Content>

        <ProjectQueueContainer>
          {projectData.map((item, index) =>
            index === 0 ? (
              <React.Fragment key={index}>
                <Link to="/upload-files" style={{ textDecoration: "none" }}>
                  <ProjectCard
                    className="fixed_card"
                    onClick={handleCreateNewProjectClick}
                  >
                    <PlusIcon as={ProjectPlus} className="project_plus_icon" />
                    <Text>New Project</Text>
                  </ProjectCard>
                </Link>
                <ProjectCard
                  className={item.draft ? "draft" : ""}
                  key={index}
                  onClick={(event) =>
                    handleProjectCardClick(item.projectId, item.draft, event)
                  }
                >
                  <DropDownDivWrapper>
                    <MoreIcon
                      as={More_vertical}
                      onClick={() => handleMoreIconClick(index)}
                      className="more-icon" // Add a class for identifying the "More" icon
                    />
                    {showMore && showMoreIndex === index && (
                      <DropDownMenu
                        item={item}
                        handleOpenProjectClick={handleOpenProjectClick}
                        handleShareProjectClick={handleShareProjectClick}
                        handleExportProjectClick={handleExportProjectClick}
                        handleDeleteProjectClick={handleDeleteProjectClick}
                      />
                    )}
                  </DropDownDivWrapper>
                  <Div>
                    <Text className="card_text">{item.name}</Text>
                    <Text className="card_supporting_text">
                      {item.draft ? "Draft" : ""}
                    </Text>
                    {/* <ProgressBarWrapper>
                        <ProgressBar
                          max={100}
                          value={item.percent}
                        ></ProgressBar>
                        <ProgressPercentage>
                          {item.percent >= 0 ? item.percent : "0"}%
                        </ProgressPercentage>
                      </ProgressBarWrapper> */}
                  </Div>
                </ProjectCard>
              </React.Fragment>
            ) : (
              <ProjectCard
                className={item.draft ? "draft" : ""}
                key={index}
                onClick={(event) =>
                  handleProjectCardClick(item.projectId, item.draft, event)
                }
              >
                <DropDownDivWrapper>
                  <MoreIcon
                    as={More_vertical}
                    onClick={() => handleMoreIconClick(index)}
                    className="more-icon" // Add a class for identifying the "More" icon
                  />
                  {showMore && showMoreIndex === index && (
                    <DropDownMenu
                      item={item}
                      handleOpenProjectClick={handleOpenProjectClick}
                      handleShareProjectClick={handleShareProjectClick}
                      handleExportProjectClick={handleExportProjectClick}
                      handleDeleteProjectClick={handleDeleteProjectClick}
                    />
                  )}
                </DropDownDivWrapper>
                <Div>
                  <Text className="card_text">{item.name}</Text>
                  <Text className="card_supporting_text">
                    {item.draft ? "Draft" : ""}
                  </Text>
                  {/* <ProgressBarWrapper>
                        <ProgressBar
                          max={100}
                          value={item.percent}
                        ></ProgressBar>
                        <ProgressPercentage>
                          {item.percent >= 0 ? item.percent : "0"}%
                        </ProgressPercentage>
                      </ProgressBarWrapper> */}
                </Div>
              </ProjectCard>
            )
          )}
        </ProjectQueueContainer>
        {isShareProjectModalOpen && (
          <ShareProjectModal
            closeModal={() => setIsShareProjectModalOpen(false)}
            projectId={activeProject_id}
            currentUser={currentUser}
            screen="home"
          />
        )}
      </HomeContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const activeProject_id = state.projects.activeProject_id;

  return {
    activeProject_id: activeProject_id,

    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeProjectIdAPI: (projectId) => dispatch(activeProjectId(projectId)),
    getProjectAPI: (projectId, draft) =>
      dispatch(_getProject(projectId, draft)),
    createNewProjectAPI: () => dispatch(_createNewProject()),
    deleteProjectAPI: (projectId) => dispatch(_deleteProject(projectId)),
  };
};

export default connect(null, mapDispatchToProps)(HomeExistingUser);
