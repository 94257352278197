import { combineReducers } from "redux";
import auth from "./auth";
import projects from "./project";
import annotations from "./annotation";

const rootReducer = combineReducers({
  auth: auth,
  projects: projects,
  // annotations: annotations,
});

export default rootReducer;
