import styled from "styled-components";

export const PageLabelsContainer = styled.div`
  display: flex;
  margin-top: 80px;
  caret-color: transparent;
`;

export const ChevronLeftIcon = styled.svg`
  position: fixed;
  z-index: 999;
  top: 162px;
  left: 22.5vw;
  width: 2rem;
  height: 2rem;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  transition: 0.5s;
  opacity: 1;

  &.sidebar_closed {
    transform: rotate(180deg);
    transition: 0.6s;
    left: 4.5vw;
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }

  @media (min-width: 1700px) {
    left: 23vw;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LabelsSidebarContainer = styled.div`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fbfcfd;
  border-right: 1px solid #dddddd;
  width: 23.75vw; //342px;
  //flex: 1;
  overflow-x: hidden;
  margin-top: 50px;
  overflow-y: auto;
  caret-color: transparent;
  height: calc(100vh - 170px); /* Adjust the height to fit your layout */
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    //border-radius: 8px;
  }

  &.short_sidebar {
    display: none;
  }

  &:hover {
    ${ChevronLeftIcon} {
      //opacity: 0;
      display: none;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ShortSideBar = styled.div`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fbfcfd;
  border-right: 1px solid #dddddd;
  width: 5.8vw;
  overflow: hidden;
  caret-color: transparent;
  height: calc(100vh - 120px); /* Adjust the height to fit your layout */

  @media (max-width: 768px) {
    // display: none;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding: 0px;
  //gap: 2px;
  height: 50px;
  width: 23.75vw; // 342px;
  border-bottom: 1px solid #eaecf0;
  position: fixed;
  top: 80px;
  z-index: 99;
`;

export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #667085;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0;
  width: 11.88vw; //171px;
  height: 50px;
  background: #fbfcfd;

  &.selected_tab {
    color: #e26d5c;
    border-bottom: 2px solid #e26d5c;
  }

  &.short_sidebar_text {
    width: 5.5vw;
    margin: 20px 0px;
  }

  &.file_name_tab {
    width: 23.75vw;
  }

  &.total_files_text {
    font-size: 12px;
    line-height: 20px;
    //align-items: flex-end;
    margin: 7px 0px 6px 0px;
    padding: 0px;
    //height: 20px;
    //width: 20vw;
    width: auto;
  }

  &.select_all {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    width: auto;
    margin-left: 12px;
  }
`;

export const SearchDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  position: relative;
  margin: 24px 0;
  width: 18.13vw;

  &.file_tab_is_open {
    margin-bottom: 0px;
  }
`;

export const SearchIcon = styled.svg`
  width: 20px;
  height: 20px;
`;

export const Input = styled.input`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  border: none;
  caret-color: #667085;

  :focus {
    outline: none;
  }
`;

export const PlusIcon = styled.svg`
  padding: 0;
  width: 24px;
  height: 24px;
  margin: auto 0px auto auto;

  &.add_files {
    width: 14px;
    height: 14px;
    margin: 0px 0px 0px 8px;
  }
`;

export const Hr = styled.hr`
  width: 23.75vw;
  height: 1px;
  background-color: #eaecf0;
  border: none;
  margin: 0px;
  padding: 0px;
`;

export const LabelListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 27px;
  width: 100%;
  height: calc(100vh - 318px);
  overflow-y: scroll;
  overflow-x: hidden;
  &.show_labels {
    margin-top: 24px;
  }

  cursor: pointer;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    //border-radius: 8px;
  }
`;

export const LabelSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border: 1px solid;
  border-radius: 8px;
  width: 18.13vw; //261px;
`;

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ShortcutIcon = styled.svg`
  width: 21px;
  height: 18px;
  margin-right: 16px;
`;

export const LabelName = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 0;
`;

export const SubLabelList = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  width: 17.64vw; //254px;
  margin: 12px 0px 0px;
`;

export const SubLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  background: #ffffff;
  border: 4px solid;
  border-radius: 8px;
  margin: 0px 0px 9px;
`;

export const SubLabelName = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 0;
`;

export const DropdownIcon = styled.svg`
  width: 15px;
  height: 15px;
  background: rgba(249, 250, 251, 0.59);
  border-radius: 16px;
  padding: 6px;
  transition: 0.5s;

  &.rotate {
    transform: rotate(270deg);
    transition: 0.5s;
  }
`;

export const FileListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  //padding: 0px 0px 0px 24px;
  //gap: 27px;
  //width: 90%;
  width: 23.75vw;
  //overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f9fafb;
  height: calc(100vh - 318px);

  cursor: pointer;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    //border-radius: 8px;
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  background: #ffffff;
  border: 1px solid gray;
  border-radius: 8px;
  //margin: 0px 0px 9px;
  width: 18.13vw; //261px;
`;

export const FileName = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 0;
`;

export const LabelingFilesContainer = styled.div`
  caret-color: transparent;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  flex: 1;
  // background: #9ca5da;

  //overflow-y: auto;
  overflow-x: hidden;
  margin-top: 50px;
  // width: calc(100vw - 23.75vw);

  &.short_sidebar {
    width: calc(100vw - 5.8vw);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  width: calc(100vw - 24vw);
  height: 50px;
  position: fixed;
  top: 80px;
  background: #ffffff;
  z-index: 99;

  &.short_sidebar {
    width: calc(100vw - 5.8vw);
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 20px 0px 0px;
`;

export const ActionControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 32px 0px 0px;
`;

export const DeleteIcon = styled.svg`
  width: 14px;
  height: 14px;
  background: #f3f3f3;
  border-radius: 6px;
  padding: 5px;
  margin: 0px 16px;
  cursor: pointer;

  &.delete_files {
    padding: 0;
    margin: 0px 0px 0px 2px;
  }
`;

export const UndoIcon = styled.svg`
  width: 14px;
  height: 14px;
  background: #f3f3f3;
  border-radius: 6px;
  padding: 5px;
  margin: 0px 16px;
  cursor: pointer;
`;

export const RedoIcon = styled.svg`
  width: 14px;
  height: 14px;
  background: #f3f3f3;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
`;

export const ArrowIcon = styled.svg`
  //width: 20px;
  //height: 20px;
  position: relative;

  &.right_arrow_white {
    width: 11.67px;
    height: 11.67px;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 0px;
  gap: 8px;
  background: transparent;
  cursor: pointer;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #667085;

    &.footer_next {
      color: #ffffff;
    }
  }

  &.footer_button {
    margin: auto 20px auto auto;
  }

  @media (max-width: 768px) {
    span {
      display: none;
    }
  }
`;

export const PaginationNumbersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 2px;
  margin: 0px 24px;
`;

export const NumberBase = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &.selected_numbering {
    color: #be4e40;
    background: #fffbfa;
    border-radius: 4px;
  }
`;

export const FileViewerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // background: #b57184;
  width: 100%;
  overflow-y: auto; /* Add this line to enable vertical scrolling */
  height: calc(100vh - 170px);
  overflow-x: hidden; /* add this line to disable horizontal scrolling */

  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    //border-radius: 8px;
  }
`;

export const Table = styled.table``;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  flex: 1;
  caret-color: transparent;
`;

export const TableRow = styled.tr`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  border-bottom: 1px solid #dddddd;
`;

export const TableData = styled.td`
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-style: normal;
  position: relative;
  //height: auto;
  overflow: visible;

  :first-child {
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    color: #14213d;
    width: 3.33vw;
    align-self: stretch;
    height: auto;
    @media (max-width: 1024px) {
      width: 50px;
    }
  }
  :nth-child(2) {
    padding: 8px;
    width: calc(100vw - 28.6vw);
    flex-wrap: wrap;
    margin-right: 1px;
    @media (max-width: 1024px) {
      width: calc(100vw - 30vw);
    }
    @media (min-width: 2000px) {
      width: calc(100vw - 28vw);
    }
    &.short_sidebar {
      width: calc(100vw - 11vw);
    }
  }
`;

export const Footer = styled.footer`
  background: #14213d;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 50;
  width: 100%; //1440px;;
  height: 40px;
`;

export const ProgressBarContainer = styled.div`
  width: 22vw;
  height: 8px;
  background-color: white;
  border-radius: 4px;
  margin: 0px 12px;
`;

export const Progress = styled.div`
  height: 100%;
  border-radius: 4px;
  background-color: #6ce9a6;
  transition: width 0.3s ease;
`;

export const FooterText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: auto auto auto 15px;
`;

export const FileItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 28px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 20vw;
  //width: 18.13vw;
  //margin-left: 32px;
  &.open_file {
    background-color: #fffbfa;
    border: 0.5px solid #e26d5c;
  }
`;

export const FileNameText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #101828;
  margin: 0;

  &.header_file_name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #667085;
    margin: 0 23px;
    width: 20%;
  }
`;

export const TextAndSupportingText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileSizeText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #667085;
  margin: 0px;
`;

export const CheckBoxInput = styled.input`
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: pointer;

  &:checked {
    background-color: #fffbfa;
    border-color: #be4e40;
  }

  &:checked::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 6px;
    padding: 2px;
    border: 1px solid #be4e40;
    border-width: 0 2px 2px 0;
    margin-top: 10px;
    margin-left: 10px;
    transform: translate(-50%, -70%) rotate(45deg);
  }
`;

export const TrashIcon = styled.svg`
  height: 24px;
  width: 24px;
  position: relative;
  // Add any other styles you require for the DeleteIcon
`;

export const AddFilesButton = styled.button`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
  border: none;
  height: 41px;
  width: 23.75vw; // 342px;
  position: fixed;
  bottom: 40px;
  z-index: 99;
  border-top: 0.5px solid #ddd;
  background: #fffbfa;
  box-shadow: 0px -1px 2px 0px rgba(16, 24, 40, 0.05);
  border-right: 1px solid #dddddd;
  color: #e26d5c;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const SelectAndDeleteFiles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 20vw;
  height: 20px;
  margin-top: 7px;
  margin-bottom: 6px;
`;

export const SelectAll = styled.span`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: -10px;
  cursor: pointer;
`;

export const ShortSidebarTabIcon = styled.svg`
  margin: 0px 0px 9px;
`;
