import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  caret-color: transparent;
  overflow-y: auto;
`;

export const LogoMarkText = styled.h1`
  font-family: "Kanit";
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  color: #141414;
  text-align: center;

  span {
    color: #e26d5c;
  }
`;

export const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding: 20px 16px;
  gap: 20px;
  width: 359px;
  height: 224px;
  background: #ffffff;
  border: 1px dashed #4b4b4b;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  margin: 112px 0;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    border-color: #ef7866;
    box-shadow: 0px 4px 6px rgba(16, 24, 40, 0.2),
      0px 2px 4px rgba(16, 24, 40, 0.12);
    background-color: #f9f9f9;
  }
`;

export const PlusIcon = styled.svg`
  position: relative;
  width: 30.33px;
  height: 30.33px;
`;

export const Text = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #14213d;
  margin: 30px 0 0;
  position: relative;
`;

export const BottomText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #14213d; ;
`;
