import React from "react";
import { RectangleDiv } from "../components/Upload/PreviewStyles";

const RectangleDivComponent = ({
  start_id,
  end_id,
  text_index,
  selectLabel,
  highlights,
  row,
  selectedRow,
}) => {
  const rowHighlights = highlights.filter((h) => h.startRow === row);

  if (rowHighlights) {
    const highlight = rowHighlights.find(
      (h) => h.startTokenId <= text_index && text_index <= h.endTokenId
    );

    if (highlight) {
      if (
        highlight.startTokenId <= text_index &&
        text_index <= highlight.endTokenId
      ) {
        return <div />;
      }
    }
  }
  if (
    start_id.row === selectedRow &&
    start_id.id + 1 <= text_index &&
    text_index <= end_id.id - 1
  ) {
    return <div />;
  } else return <RectangleDiv />;
};

export default RectangleDivComponent;
