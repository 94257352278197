import React, { useState, useRef, useEffect } from "react";
import {
  ChooseKey,
  DropdownButton,
  DropdownIcon,
  LabelsPopupContainer,
  MenuItem,
  MenuItemsWrapper,
  SubLabelList,
  Text,
} from "./LabelsPopupStyles";
import { ReactComponent as Dropdown } from "../../assets/DropdownIcon.svg";
import { connect } from "react-redux";
import { ReactComponent as TrashIcon } from "../../assets/TrashIcon.svg";

const LabelsPopup = ({
  screen,
  isVisible,
  setIsVisible,
  handleLabelSelection,
  labels,
  handleDeleteClick,
  setHighlights,
  highlights,
  selectLabel,
  annotationId,
}) => {
  const [expandedItems, setExpandedItems] = useState([]);
  //console.log("annotation id: ", annotationId);

  useEffect(() => {
    const labelsWithSublabels =
      labels &&
      labels
        ?.filter((label) => label.subLabels && label.subLabels.length > 0)
        .map((label, index) => index);

    setExpandedItems(labelsWithSublabels);
  }, [labels]);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  const handleDeleteCli = (label) => {
    // Add logic to remove the highlight
    handleDeleteClick(selectLabel, annotationId);

    // console.log("selectLabel1 ", highlights, " no ");

    // console.log("selectLabel ", " no ", selectLabel.label_id);

    // const updatedHighlights = highlights.filter(
    //   (highlightx) => highlightx.label.label_id !== selectLabel.label_id
    // );
    // setHighlights(updatedHighlights);
    // handleDeleteClick(selectLabel);
  };

  // handle label select click
  const handleSelectLabelClick = (index) => {
    //console.log("is this being called? ", index);

    handleLabelSelection(labels[index]);
    //  setIsVisible(false); // closes the dropdown when a label is selected
  };

  const toggleExpandItem = (index) => {
    setExpandedItems((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    screen === "preview" &&
    isVisible && (
      <LabelsPopupContainer
      // style={{
      //   position: "absolute",
      //   top: `${position.y}px`,
      //   left: `${position.x}px`,
      //   transform: "translateX(-50%) translateY(-50%)", // this will position the Popup's center at the given X and Y
      // }}
      >
        <MenuItemsWrapper ref={dropdownRef}>
          {labels?.map((label, index) => (
            <React.Fragment key={label.label_id}>
              <MenuItem onClick={() => handleSelectLabelClick(index)}>
                <ChooseKey style={{ backgroundColor: label.clr }}>
                  {index + 1}
                </ChooseKey>
                <Text>{label.name}</Text>
                {label.subLabels?.length > 0 && (
                  <DropdownButton onClick={() => toggleExpandItem(index)}>
                    {expandedItems.includes(index) ? (
                      <DropdownIcon as={Dropdown} />
                    ) : (
                      <DropdownIcon as={Dropdown} className="rotate" />
                    )}
                  </DropdownButton>
                )}
              </MenuItem>
              {label.subLabels && expandedItems.includes(index) && (
                <SubLabelList>
                  {label.subLabels?.map((subLabel, subIndex) => (
                    <MenuItem className="sub_labels">
                      <ChooseKey style={{ backgroundColor: label.clr }}>
                        {index + 1} + {String.fromCharCode(subIndex + 97)}
                      </ChooseKey>
                      <Text key={subIndex}>{subLabel.name}</Text>
                    </MenuItem>
                  ))}
                </SubLabelList>
              )}
            </React.Fragment>
          ))}
          <MenuItem onClick={handleDeleteCli}>
            <Text>Delete</Text>
            <TrashIcon />
          </MenuItem>
        </MenuItemsWrapper>
      </LabelsPopupContainer>
    )
  );
};

const mapStateToProps = (state) => {
  const activeProject_id = state.projects.activeProject_id;

  const activeProject = state.projects.projects.find(
    (project) => project.projectId === activeProject_id
  );

  return {
    activeProject_id: activeProject_id,
    labels: activeProject ? activeProject.labels : [],
  };
};

export default connect(mapStateToProps)(LabelsPopup);
