import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  LeftArrowIcon,
  MailFeaturedIcon,
  OpenEmailButton,
  RedirectText,
  SupportingText,
  Text,
  TextAndSupportingText,
} from "./ResetPasswordSuccessStyles";
import { ReactComponent as SuccessFeatured } from "../../assets/SuccessFeaturedIcon.svg";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrowIcon.svg";
import { connect } from "react-redux";
import { logInWithEmailAndPassword } from "../../app/Actions/authActions";

const PasswordReset = ({ email, password, LogInAPI }) => {
  //console.log("email pass: ", email, password);
  const navigate = useNavigate();

  const handleContinueClick = async () => {
    if (email !== null && password !== null) {
      await LogInAPI(email, password);
      navigate("/home");
    }
  };

  return (
    <Container>
      <MailFeaturedIcon as={SuccessFeatured} />
      <TextAndSupportingText>
        <Text>Password reset</Text>
        <SupportingText>
          Your password has been successfully reset. Click below to log in
          magically.
        </SupportingText>
      </TextAndSupportingText>
      <OpenEmailButton onClick={handleContinueClick}>Continue</OpenEmailButton>
      <Link to="/" style={{ textDecoration: "none" }}>
        <RedirectText>
          <LeftArrowIcon as={LeftArrow} />
          Back to log in
        </RedirectText>
      </Link>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    authMessage: state.auth.authMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  LogInAPI: (email, password) =>
    dispatch(logInWithEmailAndPassword(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
