import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Upload from "./components/Upload/Upload";
import Header from "./Header/Header";
import ProtectedRoute from "./components/ProtectedRoute";
import HomeExistingUser from "./components/Home/HomeExistingUser";
import { getTotalProject } from "./app/Actions/authActions";
import { connect } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./App.css";
import PageLabels from "./components/PageLabels/PageLabels";
import ResetPassword from "./components/ForgetPassword/ResetPassword";
import ErrorBoundary from "./ErrorBoundary";
import { onAuthChanged } from "./app/Actions/authActions"; // Add this import
import {
  _getProject,
  _setSharedUser,
  activeProjectId,
} from "./app/Actions/projectActions";
import VerifyEmail from "./components/Signup/VerifyEmail";
import CheckEmail from "./components/ForgetPassword/CheckEmail";

const App = ({
  currentUser,
  getTotalProjectsAPI,
  totalProject,
  onAuthCheck,
  activeProjectIdAPI,
  getProjectAPI,
  setSharedUserAPI,
}) => {
  // console.log("total project in app: ", totalProject);
  const localUser = localStorage.getItem("user");
  const [isLoading, setIsLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false); // <-- Add this state
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId") || null;
  const shareToken = searchParams.get("shareToken") || null;
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      await onAuthCheck(); // First, check the authentication
      setAuthChecked(true); // Indicate that the auth check is complete
    };

    checkAuth();
  }, [onAuthCheck]);

  useEffect(() => {
    if (authChecked && currentUser) {
      const loadData = async () => {
        await getTotalProjectsAPI(currentUser);
        setIsLoading(false);
      };

      loadData();
    }

    if (currentUser && projectId !== null && shareToken !== null) {
      projectActions(); // Call projectActions only if necessary
      navigate("/page-label"); // Redirect to the page-label screen
    }
  }, [
    authChecked,
    currentUser,
    getTotalProjectsAPI,
    projectId,
    shareToken,
    navigate,
  ]);

  const projectActions = async () => {
    await activeProjectIdAPI(projectId);
    await getProjectAPI(projectId, false);
    await setSharedUserAPI(projectId, currentUser);
  };

  return (
    <div className="App">
      <ErrorBoundary>
        <Header />
        {/* {!currentUser && <Navigate to="/" replace />} */}

        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route
              exact
              path="/home"
              element={
                localUser && isLoading ? (
                  <div className="Loading">loading...</div>
                ) : totalProject ? (
                  <HomeExistingUser
                    currentUser={currentUser}
                    totalProject={totalProject}
                  />
                ) : (
                  <Home />
                )
              }
            />
            <Route
              exact
              path="/upload-files"
              element={
                <DndProvider backend={HTML5Backend}>
                  <Upload />
                </DndProvider>
              }
            />
            <Route path="/page-label" element={<PageLabels />} />
          </Route>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/verify-email" element={<VerifyEmail />} />
          <Route exact path="/forgot-password" element={<ForgetPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/check-email" element={<CheckEmail />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    totalProject: state.projects.totalProject,
  };
};

// Define functions to dispatch actions to the Redux store
const mapDispatchToProps = (dispatch) => {
  return {
    getTotalProjectsAPI: (currentUser) =>
      dispatch(getTotalProject(currentUser)),
    onAuthCheck: () => dispatch(onAuthChanged()), // Add this line
    activeProjectIdAPI: (projectId) => dispatch(activeProjectId(projectId)),
    getProjectAPI: (projectId, draft) =>
      dispatch(_getProject(projectId, draft)),
    setSharedUserAPI: (projectId, user) =>
      dispatch(_setSharedUser(projectId, user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
