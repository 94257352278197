import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 112px;
  background-color: #ffffff;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f4f7;

  @media (max-width: 768px) {
    padding: 0 32px;
  }
`;

export const SignUpButton = styled.button`
  background-color: #ee7866;
  color: #ffffff;
  border-radius: 8px;
  border: none;
  font-family: "Inter", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 24px;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const LoginButton = styled.button`
  color: #667084;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  white-space: nowrap;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #4a4a4a; /* Change this color to your desired hover color */
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const KebabMenu = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 40px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 24px;
  }
`;
export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  @media (max-width: 1024px) {
    gap: 24px;
  }

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  gap: 32px;

  @media (max-width: 1024px) {
    gap: 24px;
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: #ffffff;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;

export const NavLink = styled.a`
  color: #667084;
  font-family: "Inter", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
`;

export const AvatarContainer = styled.div`
  display: flex;
  width: 190px;
  height: 75px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  right: 0;
  background-color: #f9fafb;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  right: 110px;
  caret-color: transparent;
  cursor: pointer;
  background-color: #f9fafb;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //width: 420px;
  height: 40px;
  //position: absolute;

  @media (max-width: 768px) {
    margin-right: 30px;
  }
`;

export const ShareButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  //padding: 10px 16px;
  gap: 8px;
  width: 99px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  //margin: 0px 6px;
  cursor: pointer;
  &:hover {
    background-color: #f0f1f3;
  }
`;

export const ShareIcon = styled.svg``;

export const ExportButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //padding: 10px 16px;
  gap: 8px;
  background: #ef7866;
  border: 1px solid #ef7866;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 105px;
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  //margin: 0px 6px;
  cursor: pointer;
  &:hover {
    background-color: #cc5b4a;
  }
`;

export const EditExportICon = styled.svg``;

export const MarkAsCompleteButton = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2f9e44;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  background: rgba(208, 244, 222, 0.4);
  border: 1px solid rgba(208, 244, 222, 0.4);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  outline: none;
  margin-right: 18px;
  cursor: pointer;
  :hover {
    border-color: #2f9e44;
  }
`;

export const CheckIcon = styled.svg``;
