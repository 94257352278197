import React from "react";
import { ReactComponent as PlusSquare } from "../../assets/PlusSquareIcon.svg";
//import AvatarIcon from "../../assets/AvatarIcon.svg";
import { Avatar, AvatarWrapper, PlusSquareIcon } from "./UserAvatarStyles";

const UserAvatar = ({ screen, avatarSrc, handlePictureChange, file }) => {
  return (
    <AvatarWrapper>
      <Avatar src={avatarSrc} alt="user avatar" referrerPolicy="no-referrer" />
      {screen !== "profile" && !file && (
        <>
          <PlusSquareIcon as={PlusSquare} />
          <input type="file" onChange={handlePictureChange} />
        </>
      )}
    </AvatarWrapper>
  );
};

export default UserAvatar;
