import React from "react";
import {
  ArrowIcon,
  Button,
  PaginationNumbersWrapper,
  NumberBase,
} from "./PageLabelsStyles";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrowIcon.svg";
import { ReactComponent as RightArrow_black } from "../../assets/RightArrowIcon-Black.svg";

const Pagination = ({
  currentPage,
  totalPages,
  maxPage,
  totalFiles,
  currentFileIndex,
  goToPreviousPage,
  goToNextPage,
  setCurrentPage,
}) => {
  return (
    <>
      <Button
        disabled={currentPage === 1 && currentFileIndex === 0}
        onClick={goToPreviousPage}
      >
        <ArrowIcon as={LeftArrow} />
        <span>Previous</span>
      </Button>
      <PaginationNumbersWrapper>
        {totalPages <= 4 ? (
          [...Array(totalPages)].map((_, index) => (
            <NumberBase
              key={index}
              className={currentPage === index + 1 ? "selected_numbering" : ""}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </NumberBase>
          ))
        ) : (
          <>
            <NumberBase
              className={currentPage === 1 ? "selected_numbering" : ""}
              onClick={() => setCurrentPage(1)}
            >
              1
            </NumberBase>
            {currentPage >= totalPages - 1 ? (
              <NumberBase>...</NumberBase>
            ) : (
              <NumberBase
                className={
                  currentPage === currentPage && currentPage > 1
                    ? "selected_numbering"
                    : ""
                }
                onClick={() => setCurrentPage(currentPage)}
              >
                {currentPage > 2 && currentPage !== totalPages
                  ? currentPage
                  : "2"}
              </NumberBase>
            )}

            {currentPage < totalPages - 1 ? (
              <NumberBase>...</NumberBase>
            ) : (
              <NumberBase
                className={
                  currentPage === totalPages - 1 ? "selected_numbering" : ""
                }
                onClick={() => setCurrentPage(currentPage)}
              >
                {totalPages - 1}
              </NumberBase>
            )}
            <NumberBase
              className={currentPage === totalPages ? "selected_numbering" : ""}
              onClick={() => setCurrentPage(totalPages)}
            >
              {totalPages}
            </NumberBase>
          </>
        )}
      </PaginationNumbersWrapper>
      <Button
        disabled={
          currentPage === maxPage && currentFileIndex === totalFiles - 1
        }
        onClick={goToNextPage}
      >
        <span>Next</span> <ArrowIcon as={RightArrow_black} />
      </Button>
    </>
  );
};

export default Pagination;
