import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px 0px;
  gap: 32px;
  z-index: 999;
  position: absolute;
  width: 400px;
  height: 268px;
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  width: 400px;
  height: 268px;
  top: 31%; //351px;
  left: 46%; //651px;

  @media (max-width: 768px) {
    left: 25%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 352px;
  height: 144px;
`;

export const IconActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CloseIcon = styled.svg`
  width: 30px;
  height: 30px;
  cursor: pointer;

  circle {
    fill: none;
  }

  :hover {
    circle {
      fill: #f8f9fa;
    }
  }
`;

export const FeaturedIcon = styled.svg`
  width: 48px;
  height: 48px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 352px;
  height: 76px;
  //align-self: stretch;
`;

export const Text = styled.p`
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  &.text {
    font-weight: 500;
    font-size: 18px;
    color: #101828;
    //margin-top: 20px;
  }

  &.supportive_text {
    font-weight: 400;
    font-size: 14px;
    color: #667085;
    margin-top: 8px;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  //align-self: stretch;
  width: 352px;
  height: 44px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  width: 170px;
  height: 44px;
  //margin: 0px 6px;
  cursor: pointer;
  &.button_cancel {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &:hover {
      background-color: #f0f1f3;
    }
  }
  &.button_confirm {
    background: #ef7866;
    border: 1px solid #ef7866;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #ffffff;

    &:hover {
      background-color: #cc5b4a;
    }
  }
`;
