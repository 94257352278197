import React, { useEffect } from "react";
import {
  Container,
  OpenEmailButton,
  ResendText,
  ResendControl,
  SupportingText,
  Text,
  TextAndSupportingText,
  LeftArrowIcon,
  MailFeaturedIcon,
} from "./CheckEmailStyles";
import { RedirectText } from "./ForgetPasswordStyles";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrowIcon.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as MailFeatured } from "../../assets/MailFeaturedIcon.svg";

const CheckEmail = ({ sendPasswordResetAPI }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const email = location.state?.email;

  useEffect(() => {
    if (!email) {
      console.log("No email to resend the password link to. 1");
      navigate("/forgot-password");
    }
  }, [email, navigate]);

  const handleOpenEmailAppClick = () => {
    const mailtoLink = `mailto:${email}`;
    //window.location.href = mailtoLink;      // open mail app on window which may redirect the user away from the current page.

    window.open(mailtoLink, "_blank"); //open in new tab or window, allowing the user to view the email web client without leaving the current page
    //setIsResetPasswordOpen(true);     // remove this to prevent the immediate redirect to the SetNewPassword component.
  };
  const handleForgotPassword = () => {
    console.log("Forgot Password Clicked");
    navigate("/forgot-password");
  };

  // handle sent password reset email
  const handleResendClick = async () => {
    console.log("No email to resend the password link to.");
    navigate("/forgot-password"); // Navigate back to "Forgot Password"
  };

  return (
    <Container>
      <MailFeaturedIcon as={MailFeatured} />
      <TextAndSupportingText>
        <Text>Check your email</Text>
        <SupportingText>
          We sent a password reset link to {email}
        </SupportingText>
      </TextAndSupportingText>
      <OpenEmailButton onClick={handleOpenEmailAppClick}>
        Open email app
      </OpenEmailButton>
      <ResendControl>
        Didn’t receive the email?
        <ResendText onClick={handleResendClick}>Click to resend</ResendText>
      </ResendControl>
      <Link to="/" style={{ textDecoration: "none" }}>
        <RedirectText>
          <LeftArrowIcon as={LeftArrow} />
          Back to log in
        </RedirectText>
      </Link>
    </Container>
  );
};

export default CheckEmail;
