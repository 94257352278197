import { useSelector } from "react-redux";

export const useAnnotationsDownloader = () => {
  const projects = useSelector((state) => state.projects.projects);

  const downloadObjectAsJson = (projectId, fileId) => {
    const project = projects.find((p) => p.projectId === projectId);
    if (!project) return;

    const file = project.files.find((f) => f.id === fileId);
    if (!file) return;

    const annotations = file.annotations;

    //console.log("download annotations: ", projectId, fileId);
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(annotations));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute(
      "download",
      `${projectId}_${fileId}_annotations.json`
    );
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return downloadObjectAsJson;
};
