import React from "react";

import { ReactComponent as ArrowUpRight } from "../../assets/ArrowUpRightIcon.svg";
import { ReactComponent as Share } from "../../assets/ShareIcon.svg";
import { ReactComponent as Database } from "../../assets/DatabaseIcon.svg";
import { ReactComponent as Trash } from "../../assets/TrashIcon.svg";
import {
  ArrowUpRightIcon,
  DatabaseIcon,
  DropdownDiv,
  DropdownText,
  DropdownWrapper,
  ShareIcon,
  TrashIcon,
} from "./HomeExistingUserStyles";

const DropDownMenu = ({
  item,
  handleOpenProjectClick,
  handleShareProjectClick,
  handleExportProjectClick,
  handleDeleteProjectClick,
}) => {
  return (
    <DropdownWrapper>
      <DropdownDiv
        onClick={() => handleOpenProjectClick(item.projectId, item.draft)}
      >
        <ArrowUpRightIcon as={ArrowUpRight} />
        <DropdownText>Open</DropdownText>
      </DropdownDiv>
      <DropdownDiv
        // onClick={() => handleShareProjectClick(item.projectId)}
        className="more-icon" // Add a class for identifying the "More" icon
      >
        <ShareIcon as={Share} />
        <DropdownText>Share</DropdownText>
      </DropdownDiv>
      <DropdownDiv
        onClick={handleExportProjectClick}
        className="more-icon" // Add a class for identifying the "More" icon
      >
        <DatabaseIcon as={Database} />
        <DropdownText>Export</DropdownText>
      </DropdownDiv>
      <DropdownDiv
        onClick={() => handleDeleteProjectClick(item.projectId)}
        className="more-icon" // Add a class for identifying the "More" icon
      >
        <TrashIcon as={Trash} />
        <DropdownText>Delete</DropdownText>
      </DropdownDiv>
    </DropdownWrapper>
  );
};

export default DropDownMenu;
