import React, { useState, useEffect } from "react";
import {
  AlphaText,
  AlphaLabel,
  AlphaLabelText,
} from "../components/Upload/PreviewStyles";

const AlphaTextComponent = ({
  alphaKey,
  start_id,
  end_id,
  text_index,
  selectLabel,
  highlights,
  row,
  onHighlightChange,
  handleAlphaLabelTextClick,
  selectedRow,
  newLetters,
  onMouseDown,
  onMouseEnter,
  onMouseUp,
}) => {
  const [currentHighlight, setCurrentHighlight] = useState(null);
  const [mid, setMid] = useState(null);

  const isInRange = (start, end) => text_index >= start && text_index <= end;

  useEffect(() => {
    const rowHighlights = highlights.filter((h) => h.startRow === row);
    const highlight = rowHighlights.find(
      (h) => h.startTokenId <= text_index && text_index <= h.endTokenId
    );

    if (highlight) {
      const midPoint = Math.floor(
        (highlight.startTokenId + highlight.endTokenId) / 2
      );
      setCurrentHighlight(highlight);
      setMid(midPoint);
      onHighlightChange(highlight);
    }
  }, [highlights, row, text_index, onHighlightChange]);

  if (
    start_id.row === selectedRow &&
    isInRange(start_id.id + 1, end_id.id - 1)
  ) {
    return null;
  }

  const isSelected =
    start_id.row === selectedRow &&
    (start_id.id === text_index || end_id.id === text_index);

  if (
    currentHighlight &&
    mid === text_index &&
    currentHighlight.labelName !== ""
  ) {
    return (
      <AlphaText
        onClick={() => handleAlphaLabelTextClick(currentHighlight)}
        className="label_selected"
        style={{
          backgroundColor: currentHighlight.clr,
          marginBottom: "38px",
          border: currentHighlight.clr,
          display: "flex",
          alignItems: "center",
          userSelect: "none",
        }}
      >
        <AlphaLabelText>
          {newLetters[currentHighlight.startTokenId]}
        </AlphaLabelText>
        {currentHighlight.labelName}
      </AlphaText>
    );
  }
  // Check if we are within the range of a highlighted area
  const isWithinHighlight =
    currentHighlight &&
    isInRange(currentHighlight.startTokenId, currentHighlight.endTokenId);

  return !isWithinHighlight ? (
    <AlphaText
      className={isSelected ? "selected_alpha_text" : ""}
      onMouseDown={onMouseDown && onMouseDown?.bind(null, text_index)}
      onMouseEnter={onMouseEnter && onMouseEnter?.bind(null, text_index)}
      onMouseUp={onMouseUp && onMouseUp?.bind(null, text_index)}
      style={{ userSelect: "none" }}
    >
      {alphaKey}
    </AlphaText>
  ) : null;
};

export default AlphaTextComponent;
