import React from "react";
import {
  ConnectorIcon,
  ConnectorWrap,
  PanelContainer,
  PanelText,
  StepBase,
  StepBaseIcon,
  SupportingText,
  TextAndSupportingText,
} from "./PreviewPanelStyles";
import { ReactComponent as StepBaseSvg } from "../../assets/StepBaseIcon.svg";
import { ReactComponent as stepBaseFilledUp } from "../../assets/StepBaseFilledIcon.svg";
import { ReactComponent as stepBaseChecked } from "../../assets/StepBaseCheckedIcon.svg";

const PreviewPanel = ({ completedSteps, isMouseHovering }) => {
  return (
    <PanelContainer>
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon
            as={
              completedSteps.includes(1)
                ? stepBaseChecked
                : isMouseHovering && completedSteps.length === 1
                ? stepBaseFilledUp
                : StepBaseSvg
            }
            className={completedSteps.includes(1) ? "checked_icon" : null}
          />
          <ConnectorIcon
            className={completedSteps.includes(1) ? "selected" : null}
          />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Hover and Click!</PanelText>
          <SupportingText>
            Click on a section or press relevant numeric key to start tagging
          </SupportingText>
        </TextAndSupportingText>
      </StepBase>
      {/* Add more steps if needed */}
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon
            as={
              completedSteps.includes(2)
                ? stepBaseChecked
                : completedSteps.includes(1)
                ? stepBaseFilledUp
                : StepBaseSvg
            }
            className={completedSteps.includes(2) ? "checked_icon" : null}
          />
          <ConnectorIcon />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Press q to label from first word</PanelText>
          <SupportingText>
            Taps will guide you to use keyboard for tagging
          </SupportingText>
        </TextAndSupportingText>
      </StepBase>
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon
            as={
              completedSteps.includes(3)
                ? stepBaseChecked
                : completedSteps.includes(2)
                ? stepBaseFilledUp
                : StepBaseSvg
            }
            className={completedSteps.includes(3) ? "checked_icon" : null}
          />
          <ConnectorIcon />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Press “Space” P to label till Pth tap</PanelText>
          <SupportingText>
            Sentence from “q” to “p” will be labeled
          </SupportingText>
        </TextAndSupportingText>
      </StepBase>
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon
            as={
              completedSteps.includes(4)
                ? stepBaseChecked
                : completedSteps.includes(3)
                ? stepBaseFilledUp
                : StepBaseSvg
            }
            className={completedSteps.includes(4) ? "checked_icon" : null}
          />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Select Label from dropdown</PanelText>
          <SupportingText className="select_label">
            Select or press corresponding key to add labels
          </SupportingText>
        </TextAndSupportingText>
      </StepBase>
    </PanelContainer>
  );
};

export default PreviewPanel;
