import React, { useState } from "react";
import {
  EditButton,
  EditIcon,
  EmailText,
  HelloText,
  LogoutButton,
  NameText,
  NumberText,
  ProfileContainer,
  ProfileDetail,
  ProjectDetail,
  Text,
} from "./ProfileStyles";
import { ReactComponent as EditPenIcon } from "../../assets/EditIcon.svg";
import ProfileSettings from "./ProfileSettings";
import UserAvatar from "./UserAvatar";
import { connect } from "react-redux";
import { logout } from "../../app/Actions/authActions";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AvatarIcon from "../../assets/AvatarIcon.svg";

const Profile = ({ currentUser, SignOutAPI, totalProject }) => {
  const [isProfileSettingsOpen, setIsProfileSettingsOpen] = useState(false);
  // check if logged user have profile avatar or not, if no then add custom avatar
  const profileAvatar = currentUser?.profilePicture
    ? currentUser.profilePicture
    : AvatarIcon;
  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsProfileSettingsOpen(!isProfileSettingsOpen);
  };

  const handleLogout = () => {
    SignOutAPI();
    Cookies.remove("email");
    Cookies.remove("password");
    navigate("/signup");
  };

  return (
    <>
      {!isProfileSettingsOpen ? (
        <ProfileContainer>
          <UserAvatar screen="profile" avatarSrc={profileAvatar} />
          <ProfileDetail>
            <HelloText>
              Hello,{" "}
              <NameText>
                &nbsp;
                {currentUser?.displayName !== null
                  ? currentUser.name
                  : currentUser.email.split("")[0]}
              </NameText>
            </HelloText>
            <EmailText>{currentUser.email}</EmailText>
            <EditButton onClick={handleEditClick}>
              Edit <EditIcon as={EditPenIcon} />
            </EditButton>
          </ProfileDetail>
          <ProjectDetail>
            <Text>Total projects :</Text>
            <NumberText>{!totalProject ? "0" : totalProject}</NumberText>
          </ProjectDetail>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </ProfileContainer>
      ) : (
        <ProfileSettings currentUser={currentUser} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    authMessage: state.auth.authMessage,
    totalProject: state.projects.totalProject,
  };
};

const mapDispatchToProps = (dispatch) => ({
  SignOutAPI: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
