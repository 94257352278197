import styled from "styled-components";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-top: 7%;
  caret-color: transparent;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-top: 13%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 84.44vw;
  height: 15.29vh;
  //position: fixed;
`;

export const HeadingAndSupportingText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  //gap: 306px;
  width: 84.51vw;
  height: 60px;
`;

export const TextAndProjectDetails = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

export const SupportingText = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -0.02em;
  color: #101828;
`;

export const ProjectDetails = styled.span`
  display: flex;
  flex-direction: column;
  //margin-right: 1010px;
`;

export const NewProjectButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  //gap: 8px;
  background: #ef7866;
  border: 1px solid #ef7866;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  width: 142px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #cc5b4a;
  }
`;

export const PlusIcon = styled.svg`
  width: 20px;
  height: 20px;

  &.project_plus_icon {
    position: relative;
    width: 30.33px;
    height: 30.33px;
  }
`;

export const TabsAndSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 0px;
  gap: 236px;
  width: 85.14vw;
  //height: 56px;

  @media (max-width: 768px) {
    gap: 18px;
  }
`;

export const HorizontalTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //padding: 6px 0px 6px 16px;
  gap: 8px;
  width: 439px;
  height: 56px;
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 6px;
    gap: 4px;
  }
`;

export const TabButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px 14px;
  gap: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #667085;
  border-radius: 6px;
  outline: none;
  cursor: pointer;

  &.selected {
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    color: #344054;
  }
`;

export const SortAndSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 28px;
  width: 542px;
  height: 56px;

  @media (max-width: 768px) {
    gap: 14px;
  }
`;

export const InputDropdownWrapper = styled.select`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: 194px;
  //height: 44px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;

  &.type {
    color: #101828;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  :focus {
    outline: none;
  }

  @media (max-width: 768px) {
    //gap: 0;
    width: 150px;
  }
`;

export const Input = styled.input`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  border: none;
  &:focus {
    outline: none;
  }

  &.dropdown_input {
    color: #101828;
    width: 80%;
  }
  &.search_input {
    color: #667085;
    margin-left: 20px;
    caret-color: #667085;
  }
`;

export const DropDownIcon = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
  margin: 12px 14px 12px 145px;

  @media (max-width: 768px) {
    //margin: 12px 14px 12px 110px;
    margin-left: 110px;
  }
`;

export const InputSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: 320px;
  //height: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 175px;
  }
`;

export const SearchIcon = styled.svg`
  width: 20px;
  height: 20px;
  position: absolute;
`;

export const ProjectQueueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  //align-items: center;
  padding: 0px;
  gap: 55px; //5.39%;
  width: 84.38vw;
  margin: 4.85% 0;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eaecf0;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 6%;
  }
`;

export const MoreIcon = styled.svg`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  width: 20px;
  height: 20px;
`;

export const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding: 20px 16px;
  gap: 20px;
  width: 359px;
  height: 224px;
  background: #ffffff;
  border: 1px solid #4b4b4b;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  //margin: 112px 0;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &.fixed_card {
    border: 1px dashed #4b4b4b;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    border-color: #ef7866;
  }
  &.draft:hover {
    border-color: blue;
  }
`;

export const TotalProject = styled.p`
  &.total_project {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #727272;
    margin: 0px 0px 18px;
  }

  &.total_project_count {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #393939;
    border-left: 1px solid #e26d5c;
    padding-left: 10px;
    margin: 0;
  }
`;

export const Text = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #14213d;
  margin: 30px 0 0;
  position: relative;

  &.card_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #14213d;
    margin: 0;
    padding: 0;
  }

  &.card_supporting_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    //color: #e26d5c;
    color: blue;
    margin: 0;
    padding: 0;
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin: 0;
`;

export const ProgressBar = styled.progress`
  width: 278px;
  height: 8px;
  border-radius: 4px;
  margin: 0px 5px 0px 0px;
  padding: 0;

  -webkit-appearance: none;

  ::-webkit-progress-bar {
    width: 100%;
    height: 8px;
    background: #fffbfa;
    border-radius: 4px;
  }

  ::-webkit-progress-value {
    background: #ffbaa4;
    border-radius: 4px;
  }
`;

export const ProgressPercentage = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 0;
  padding: 0;
`;

export const DropDownDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
  position: relative;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 240px;
  height: 160px;
  background: #ffffff;
  position: absolute;
  z-index: 10;
  margin-top: 22px;
  margin-right: 20px;
  //border: 1px solid black;
`;

export const DropdownDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
  width: 208px;
  height: 40px;
  position: relative;

  :hover {
    background: #f9fafb;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;
`;

export const ArrowUpRightIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const DropdownText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #344054;
  margin: 0;
`;

export const ShareIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const DatabaseIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const TrashIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: relative;
`;
