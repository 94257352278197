import React, { useState } from "react";
import {
  BrowseButton,
  FileBase,
  FileDropContainer,
  FileInfo,
  FileName,
  FileNameAndSizeText,
  FileQueueContainer,
  FileSize,
  FileSizeInfoText,
  FileUploadContainer,
  FileUploadIcon,
  FileWrapper,
  ProgressBar,
  ProgressBarWrapper,
  ProgressPercentage,
  SpanText,
  TrashIcon,
  UploadFeaturedIcon,
  UploadTextAndSupportingText,
} from "./UploadStyles";
import { ReactComponent as UploadFeatured } from "../../assets/UploadFeaturedIcon.svg";
import { ReactComponent as Upload_OverFeatured } from "../../assets/UploadFeaturedIcon-Over.svg";
import { ReactComponent as File_Upload } from "../../assets/FileUploadIcon.svg";
import { ReactComponent as Trash } from "../../assets/TrashIcon.svg";
import { ReactComponent as Check } from "../../assets/CheckIcon.svg";

const FileUpload = ({
  handleOnDragOver,
  handleOnDropFiles,
  handleDragLeave,
  isDragOver,
  handleBrowseUpload,
  handleChange,
  inputRef,
  files,
  handleRemoveFile,
  currentUser,
  project_id,
  screen,
}) => {
  const [isHovering, setIsHovering] = useState(null);
  const handleCheckHover = (inx) => {
    setIsHovering(inx);
  };

  const handleCheckLeave = () => {
    setIsHovering(null);
  };

  // calculate file size into KB, MB, GB.
  const calculateFileSize = (num) => {
    let size = 0;
    if (num <= 1000000) {
      size = (num / 1000).toFixed(2) + " KB";
    } else if (num === 1000001 || num <= 1000000000) {
      size = (num / 1000000).toFixed(2) + " MB";
    } else if (num === 1000000001 || num <= 1000000000000) {
      size = (num / 1000000000).toFixed(2) + " GB";
    }
    return size;
  };

  return (
    <FileUploadContainer
      onDragOver={handleOnDragOver}
      onDrop={(e) => handleOnDropFiles(e)}
      onDragLeave={handleDragLeave}
      //onClick={handleBrowseUpload}
      className={screen === "page_label" ? "page_label" : null}
      style={{
        backgroundColor: isDragOver && "rgba(255, 209, 186, 0.2)",
        border: isDragOver && "1px dashed #FF8E7A",
      }}
    >
      <FileDropContainer
        className={
          screen === "page_label" && files.length > 0 ? "file_uploaded" : null
        }
      >
        <UploadFeaturedIcon
          as={isDragOver ? Upload_OverFeatured : UploadFeatured}
          onClick={handleBrowseUpload}
        />
        <UploadTextAndSupportingText>
          <div style={{ display: "flex" }}>
            <input
              type="file"
              onChange={handleChange}
              hidden
              ref={inputRef}
              multiple
              // accept=".jpg, .svg, .png, .gif"
              accept=".txt"
            />
            <BrowseButton onClick={handleBrowseUpload}>
              Click to Upload
            </BrowseButton>
            <SpanText>or drag and drop</SpanText>
          </div>
          <FileSizeInfoText>.txt files (max. 5MB)</FileSizeInfoText>
        </UploadTextAndSupportingText>
      </FileDropContainer>
      {files?.length !== 0 && (
        <FileQueueContainer
          className={screen === "page_label" ? "page_label" : null}
        >
          {files?.map((file, i) => (
            <FileBase
              key={i}
              onMouseEnter={(e) => {
                handleCheckHover(i);
                e.stopPropagation();
              }}
              onMouseLeave={handleCheckLeave}
            >
              <FileWrapper
                className={screen === "page_label" ? "page_label" : null}
              >
                <FileUploadIcon as={File_Upload} />
                <FileInfo>
                  <FileNameAndSizeText>
                    <FileName>{file?.name}</FileName>
                    <FileSize>{calculateFileSize(file?.size)}</FileSize>
                  </FileNameAndSizeText>
                  <ProgressBarWrapper
                    className={screen === "page_label" ? "page_label" : null}
                  >
                    <ProgressBar
                      max={100}
                      value={file.progress || file.percent || 0}
                    ></ProgressBar>
                    <ProgressPercentage>
                      {((file.progress || file.percent || 0) * 1).toFixed(2)}%
                    </ProgressPercentage>
                  </ProgressBarWrapper>
                </FileInfo>
              </FileWrapper>
              {(file?.progress || file?.percent) === 100 ? (
                <div
                  onMouseEnter={() => handleCheckHover(i)}
                  onMouseLeave={handleCheckLeave}
                >
                  <TrashIcon
                    as={Check}
                    className="check_icon"
                    style={{
                      display: isHovering === i ? "none" : "flex",
                    }}
                  />
                  {isHovering === i && (
                    <TrashIcon
                      as={Trash}
                      onClick={(e) =>
                        handleRemoveFile(
                          currentUser.uid,
                          project_id,
                          file,
                          file.uid
                        )
                      }
                      className="trash_icon"
                    />
                  )}
                </div>
              ) : (
                <TrashIcon
                  as={Trash}
                  onClick={(e) =>
                    handleRemoveFile(
                      currentUser.uid,
                      project_id,
                      file,
                      file.uid
                    )
                  }
                  className="trash_icon"
                />
              )}
            </FileBase>
          ))}
        </FileQueueContainer>
      )}
    </FileUploadContainer>
  );
};

export default FileUpload;
