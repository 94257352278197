import styled from "styled-components";

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
  width: 61vw;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    //border-radius: 8px;
  }

  @media (max-width: 1227px) {
    width: 80vw;
  }
`;
export const AlphaLabel = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 4px;
  background: #ffa48f;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-top-left-radius: 3.3px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

/*export const AlphaLabelText = styled.div`
  color: white;
  font-size: 13.18px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 10.99px;
  word-wrap: break-word;
`;*/

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  flex: 1;
  caret-color: transparent;
  border: 1px dashed #eaecf0;
  border-radius: 8px;
  width: 61vw;
  height: 60vh;
  @media (max-width: 1227px) {
    width: 80vw;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;

  &.selected {
    border-bottom: 1px solid #e26d5c;
  }
  &:hover {
    border-bottom: 1px solid #e26d5c;
  }
`;

export const TableData = styled.td`
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-style: normal;
  position: relative;
  height: auto;
  overflow: visible;

  :first-child {
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    color: #be4e40;
    width: 3.33vw;
    height: 98%;
  }
  :nth-child(2) {
    padding: 8px;
    width: 56vw;
    flex-wrap: wrap;
    margin-right: 1px;

    @media (max-width: 1227px) {
      width: 74vw;
    }
  }

  &.odd {
    background: #fffbfa;
  }
  &.even {
    background: rgba(255, 209, 186, 0.2);
  }
`;

export const TextWithAlpha = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AlphaText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13.1833px;
  line-height: 11px;
  color: #14213d;
  //width: 16px;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  gap: 5px;
  background: #dddddd;
  border-radius: 3.3px;
  border-radius: 3.3px;
  z-index: 9;
  position: absolute;
  overflow: hidden;
  margin-bottom: 41px;

  &.selected_alpha_text {
    background: #ffa48f;
    border: 1px solid #fffbfa;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: #ffffff;
  }

  &.label_selected {
    padding: 0 4px 0 0;
    width: auto;
  }
`;

export const AlphaLabelText = styled.span`
  color: #fff;
  border-radius: 3.3px 0px 0px 0px;
  background-color: #ffa48f;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 6px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  z-index: 9;
  //margin: 0 4px 0 0;
`;

export const RectangleDiv = styled.div`
  border-top: 1.09861px solid rgba(226, 109, 92, 0.5);
  border-radius: 8px;
  position: absolute;
  height: 100%;
  width: 85%;
`;

export const ParagraphText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #667085;
  //padding: 0 5px;
  margin: 0;
  align-items: center;
  justify-content: center;
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: 2;
  position: relative;

  &.selected_para {
    margin: 4px 0;
  }
  /* Add your file path to the CSS file in your project. */
  .highlightedText {
    margin: 4px 0 0;
    padding: 0 5px;
  }

  .highlightedText.hovered {
    border: 4px solid black;
  }

  .highlightedText.highlighted {
    // background-color: #fe8c8e;
  }

  /* Modify this color as per your needs. */
  .highlightedText.highlighted.hovered {
    background-color: #fe8c8e;
    border: 4px solid black;
  }
`;
