import { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

export const useSidebar = () => {
  return useContext(SidebarContext);
};

export const SidebarProvider = ({ children }) => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  return (
    <SidebarContext.Provider value={{ isSidebarActive, setIsSidebarActive }}>
      {children}
    </SidebarContext.Provider>
  );
};
