import styled from "styled-components";

export const SidebarMenuContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  background-color: #f9fafb;
  top: 80px;
  height: 100vh;
  width: 380px;
  right: 0;
  bottom: 0%;
  transition: right 5s;
  caret-color: transparent;
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`;

export const NavLink = styled.a`
  color: #667084;
  font-family: "Inter", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  width: 100%;

  :hover {
    background: #f9fafb;
  }
`;
