import React, { useState, useEffect, useRef } from "react";
import {
  LabelingFilesContainer,
  PageLabelsContainer,
  ChevronLeftIcon,
} from "./PageLabelsStyles";
import Sidebar from "./Sidebar";
import FileViewer from "./FileViewer";
import HeaderLabelling from "./HeaderLabelling";
import FooterComponent from "./Footer";
import Header from "../../Header/Header.js";
import { ReactComponent as Chevron } from "../../assets/ChevronLeftIcon.svg";
import { connect } from "react-redux";
import {
  _setTotalPages,
  deleteFiles,
  setCurrentFileIndex,
  setCurrentFileId,
} from "../../app/Actions/projectActions";
import MarkAsCompleteModal from "./MarkAsCompleteModal.js";
import ExportModal from "./ExportModal";
import { SidebarProvider } from "./ContextFile";
import DeleteFilesConfirmModal from "./DeleteFilesConfirmModal";
import ShareProjectModal from "../ShareProject/ShareProjectModal";

import { db } from "../../app/firebase"; // make sure 'db' is the correct export from your firebase configuration
import {
  collection,
  doc,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";
const firestore = db; // If 'db' is the correct Firestore instance from your firebase configuration

const PageLabels = ({
  labelsArray,
  filesArray,
  activeProject_id,
  setTotalPagesAPI,
  deleteFilesAPI,
  activeProject,
  currentUser,
  setCurrentFileIndexing,
  setCurrentFileIding,
}) => {
  const [isActiveTab, setIsActiveTab] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectFileToOpen, setSelectFileToOpen] = useState(
    filesArray?.length ? filesArray[0] : null
  );
  const [progress, setProgress] = useState(50);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  // console.log("selectFileToOpen: ", filesArray[0]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const totalFiles = filesArray?.length;
  const currentFile = filesArray ? filesArray[currentFileIndex] : null;
  const [isMarkAsCompleteModalOpen, setIsMarkAsCompleteModalOpen] =
    useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectAllFiles, setSelectAllFiles] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [isArrowIconVisible, setIsArrowIconVisible] = useState(false);
  const [isShareProjectModalOpen, setIsShareProjectModalOpen] = useState(false);
  const [showStepNumber, setShowStepNumber] = useState(0);

  useEffect(() => {
    // show sublabels expansion default if sublabels are available
    const labelsWithSublabels = labelsArray
      ?.filter((label) => label.subLabels && label.subLabels.length > 0)
      .map((label, index) => index);

    setExpandedItems(labelsWithSublabels);
    // Update the selected file when the current file index changes
    setSelectFileToOpen(currentFile);
    // setCurrentPage(1); // Reset the current page when selecting a new file
  }, [labelsArray, currentFile]);
  useEffect(() => {
    const fetchCurrentFileIndexAndId = async () => {
      try {
        // You should be using getDoc here, not getDocs
        const projectRef = doc(
          collection(firestore, "projects"),
          activeProject_id
        );
        const projectDoc = await getDoc(projectRef); // <-- Using getDoc instead of getDocs

        if (projectDoc.exists) {
          // <-- Check if document exists
          const projectData = projectDoc.data();
          const currentFileIndexFromFirestore =
            projectData.currentFileIndex || 0; // <-- Added fallback
          const currentFileIdFromFirestore = projectData.currentFileId || null; // <-- Added fallback

          setCurrentFileIndex(currentFileIndexFromFirestore);
          setSelectFileToOpen(
            filesArray.find((file) => file.id === currentFileIdFromFirestore)
          );

          // Updating Redux state
          setCurrentFileIndexing(
            currentFileIndexFromFirestore,
            activeProject_id
          );
          setCurrentFileIding(currentFileIdFromFirestore, activeProject_id);
        } else {
          console.error("Document does not exist!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchCurrentFileIndexAndId();
  }, [activeProject_id]); // <-- If this should run on add

  useEffect(() => {
    // Set the maxPage value to the total number of files.
    setMaxPage(totalFiles);
    setTotalPages(totalFiles);
    // Update the selected file when the current file index changes
    setSelectFileToOpen(currentFile);
    console.log(
      "current file changes: ",
      activeProject_id,
      currentFileIndex,
      currentFile
    );
    if (currentFile) {
      updateCurrentFileIndexInFirestore(
        activeProject_id,
        currentFileIndex,
        currentFile.id
      );
    }
  }, [totalFiles, currentFileIndex]);

  const toggleExpandItem = (index) => {
    setExpandedItems((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Function to update the current active file index in Firestore
  const updateCurrentFileIndexInFirestore = async (
    projectId,
    currentFileIndex,
    currentFileId
  ) => {
    const updatedData = {
      currentFileIndex: currentFileIndex,
      currentFileId: currentFileId,
    };

    const projectRef = doc(collection(firestore, "projects"), projectId);
    await updateDoc(projectRef, updatedData);

    setCurrentFileIndexing(currentFileIndex, activeProject_id); // <-- Updated here
    setCurrentFileIding(currentFile.id, activeProject_id); // <-- Updated here
  };

  const ExtractFileName = ({ storageName }) => {
    const lastIndex = storageName.lastIndexOf("/");
    let fileName = storageName.substring(lastIndex + 1);
    const fileNameParts = fileName.split("-");
    fileName = fileNameParts[fileNameParts.length - 1];
    return fileName;
  };

  const handleFileNameClick = (file) => {
    const fileIndex = filesArray.findIndex((f) => f.id === file.id);
    setCurrentFileIndex(fileIndex);
    setCurrentPage(fileIndex + 1); // Reset the current page when selecting a new file
  };

  const handleIsSidebarOpenClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const goToPreviousPage = () => {
    if (currentFileIndex === 0) {
      // First file, do nothing
      return;
    }
    setCurrentFileIndex((prevIndex) => prevIndex - 1);
    setCurrentPage((prevIndex) => prevIndex - 1);
  };

  const goToNextPage = () => {
    if (currentFileIndex === totalFiles - 1) {
      // Last file, do nothing
      return;
    }
    setCurrentFileIndex((prevIndex) => prevIndex + 1);
    setCurrentPage((prevIndex) => prevIndex + 1);
  };

  const handleMarkAsCompleteClick = () => {
    //console.log("mark as complete button clicked....");
    setIsExportModalOpen(false);
    setIsMarkAsCompleteModalOpen(true);
  };

  const handleExportClick = () => {
    //console.log("export button clicked....");
    setIsMarkAsCompleteModalOpen(false);
    setIsExportModalOpen(true);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(itemId)) {
        // Item is already selected, so remove it from the list
        return prevSelectedFiles.filter((item) => item !== itemId);
      } else {
        // Item is not selected, so add it to the list
        return [...prevSelectedFiles, itemId];
      }
    });
  };

  const handleSelectAllFilesChange = () => {
    setSelectAllFiles(!selectAllFiles);
    if (!selectAllFiles) {
      // Select all files
      const allFileIds = filesArray.map((file) => file.id);
      setSelectedFiles(allFileIds);
    } else {
      // Deselect all files
      setSelectedFiles([]);
    }
  };

  const handleDeleteFilesClick = () => {
    setIsDeleteConfirmModalOpen(true);
  };

  const handleDeleteFiles = async () => {
    if (currentFileIndex === 0) {
      //setCurrentFileIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentFileIndex((prevIndex) => prevIndex - 1);
    }
    await deleteFilesAPI(activeProject_id, selectedFiles);
    setIsDeleteConfirmModalOpen(false);
  };

  const handleShareClick = () => {
    setIsShareProjectModalOpen(true);
  };

  return (
    <SidebarProvider>
      <React.Fragment>
        <Header
          screen="page-label"
          handleMarkAsCompleteClick={handleMarkAsCompleteClick}
          handleExportClick={handleExportClick}
          handleShareClick={handleShareClick}
          projectName={activeProject.name}
        />
        <PageLabelsContainer>
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            tabs={["Labels", "Files"]}
            activeTab={isActiveTab}
            setIsActiveTab={setIsActiveTab}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
            isActiveTab={isActiveTab}
            labelsArray={labelsArray}
            expandedItems={expandedItems}
            toggleExpandItem={toggleExpandItem}
            filesArray={filesArray}
            selectedFiles={selectedFiles}
            handleCheckboxChange={handleCheckboxChange}
            projectName={activeProject.name}
            draft={activeProject.draft}
            selectAllFiles={selectAllFiles}
            handleSelectAllFilesChange={handleSelectAllFilesChange}
            handleDeleteFilesClick={handleDeleteFilesClick}
            handleIsSidebarOpenClick={handleIsSidebarOpenClick}
            setIsArrowIconVisible={setIsArrowIconVisible}
            handleFileNameClick={handleFileNameClick}
            selectFileToOpen={selectFileToOpen}
          />

          <ChevronLeftIcon
            as={Chevron}
            onClick={handleIsSidebarOpenClick}
            className={!isSidebarOpen ? "sidebar_closed" : null}
            style={{ opacity: isSidebarOpen && isArrowIconVisible ? 1 : 0 }}
          />

          <LabelingFilesContainer
            className={!isSidebarOpen ? "short_sidebar" : null}
          >
            <HeaderLabelling
              selectFileToOpen={selectFileToOpen}
              currentPage={currentPage}
              totalPages={totalPages}
              maxPage={maxPage}
              totalFiles={totalFiles}
              currentFileIndex={currentFileIndex}
              goToPreviousPage={goToPreviousPage}
              goToNextPage={goToNextPage}
              setCurrentPage={setCurrentPage}
              isSidebarOpen={isSidebarOpen}
              handleMarkAsCompleteClick={handleMarkAsCompleteClick}
              handleExportClick={handleExportClick}
            />
            {selectFileToOpen && (
              <FileViewer
                fileUrl={currentFile?.url}
                fileId={currentFile?.id}
                isSidebarOpen={isSidebarOpen}
                setMaxPage={setMaxPage}
                setTotalPages={setTotalPages}
                currentPage={currentPage}
                project_id={activeProject_id}
                setTotalPagesAPI={setTotalPagesAPI}
                labels={labelsArray}
                setShowStepNumber={setShowStepNumber}
              />
            )}
          </LabelingFilesContainer>
          {isMarkAsCompleteModalOpen && (
            <MarkAsCompleteModal
              closeModal={() => setIsMarkAsCompleteModalOpen(false)}
            />
          )}
          {isExportModalOpen && (
            <ExportModal
              closeModal={() => setIsExportModalOpen(false)}
              fileUrl={currentFile?.url}
              fileId={currentFile?.id}
              currentPage={currentPage}
              project_id={activeProject_id}
            />
          )}
          {isDeleteConfirmModalOpen && (
            <DeleteFilesConfirmModal
              closeModal={() => {
                setIsDeleteConfirmModalOpen(false);
                setSelectedFiles([]);
              }}
              handleDeleteFilesConfirmClick={handleDeleteFiles}
            />
          )}
          {isShareProjectModalOpen && (
            <ShareProjectModal
              closeModal={() => setIsShareProjectModalOpen(false)}
              projectId={activeProject_id}
              currentUser={currentUser}
            />
          )}
        </PageLabelsContainer>

        <FooterComponent
          goToNextPage={goToNextPage}
          progress={progress}
          showStepNumber={showStepNumber}
        />
      </React.Fragment>
    </SidebarProvider>
  );
};

const mapStateToProps = (state) => {
  const activeProject_id = state.projects.activeProject_id;

  const activeProject = state.projects.projects.find(
    (project) => project?.projectId === activeProject_id
  );

  return {
    activeProject_id: activeProject_id,
    labelsArray: activeProject ? activeProject.labels : [],
    filesArray: activeProject ? activeProject.files : [],
    activeProject: activeProject || [],
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTotalPagesAPI: (projectId, fileId, totalPages) =>
      dispatch(_setTotalPages(projectId, fileId, totalPages)),
    deleteFilesAPI: (project_Id, file_id) =>
      dispatch(deleteFiles(project_Id, file_id)),

    setCurrentFileIndexing: (index, activeProject_id) =>
      dispatch(setCurrentFileIndex(index, activeProject_id)),
    setCurrentFileIding: (id, activeProject_id) =>
      dispatch(setCurrentFileId(id, activeProject_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLabels);
