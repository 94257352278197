import React from "react";
import {
  PanelContainer,
  StepBase,
  ConnectorWrap,
  TextAndSupportingText,
  PanelText,
  SupportingText,
  StepBaseIcon,
  ConnectorIcon,
} from "./PanelStyles";
import { ReactComponent as StepBaseSvg } from "../../assets/StepBaseIcon.svg";
// import { ReactComponent as InactiveConnector } from "../../assets/Connector-inactiveIcon.svg";
import Footer from "../../Footer/Footer";

const Panel = () => {
  return (
    <PanelContainer>
      {/* Add your panel content using the styled-components */}
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon as={StepBaseSvg} />
          <ConnectorIcon />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Signup</PanelText>
          <SupportingText>Simply enter your name and email</SupportingText>
        </TextAndSupportingText>
      </StepBase>
      {/* Add more steps if needed */}
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon as={StepBaseSvg} />
          <ConnectorIcon />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Create a New Project</PanelText>
          <SupportingText>Embark on your annotation journey</SupportingText>
        </TextAndSupportingText>
      </StepBase>
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon as={StepBaseSvg} />
          <ConnectorIcon />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Annotate</PanelText>
          <SupportingText>
            Effortlessly tag texts at 5x the speed
          </SupportingText>
        </TextAndSupportingText>
      </StepBase>
      <StepBase>
        <ConnectorWrap>
          <StepBaseIcon as={StepBaseSvg} />
        </ConnectorWrap>
        <TextAndSupportingText>
          <PanelText>Export</PanelText>
          <SupportingText>
            Choose a format and grab your annotated data
          </SupportingText>
        </TextAndSupportingText>
      </StepBase>

      <Footer />
    </PanelContainer>
  );
};

export default Panel;
