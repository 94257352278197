import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  background-color: #f9fafb;
  top: 80px;
  height: 100vh;
  width: 380px;
  right: 0;
  bottom: 0%;
  transition: right 5s;
  caret-color: transparent;
  //overflow-y: auto;
`;

export const AvatarWrapper = styled.div`
  width: 80px;
  height: 80px;
  border: 4px solid #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 200px;
  margin-top: 48px;
  cursor: pointer;
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
`;

export const ProfileDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eaecf0;
  padding: 31px 0;
  //width: 100%;
  width: 343px;
`;

export const HelloText = styled.h3`
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #101828;
  margin: 0 0 6px;
`;

export const NameText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #e26d5c;
  margin: 0;
`;

export const EmailText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  margin: 0 0 53.95px;
`;

export const EditButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  width: 82px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  align-self: flex-end;
  cursor: pointer;

  &:hover {
    background-color: #f0f1f3;
  }
`;

export const EditIcon = styled.svg`
  position: relative;
  height: 16.52px;
  width: 16.52px;
`;

export const ProjectDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 343px;
`;

export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #727272;
  margin: 32px 0 18px;
`;

export const NumberText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #393939;
  margin: 0;
  padding-left: 9px;
  border-left: 1px solid #e26d5c;
`;

export const LogoutButton = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  //margin: 0 12px 0 0; // margin left only
  width: 79px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  top: 81vh;
  right: 20px;

  &:hover {
    background-color: #f0f1f3;
  }
`;
