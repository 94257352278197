import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux"; // You need to import connect

import {
  PreviewContainer,
  TableBody,
  TableRow,
  TableData,
  ParagraphText,
  TextWithAlpha,
  AlphaText,
  AlphaLabel,
  AlphaLabelText,
  RectangleDiv,
} from "./PreviewStyles";
import TextFile from "../../assets/sampleText.txt";
import PreviewPanel from "./PreviewPanel";
import LabelsPopup from "./LabelsPopup";

import useKeyboardHandlers from "../../Util/useKeyboardHandlers";
import useLabelHandlers from "../../Util/useLabelHandlers";
import AlphaTextComponent from "../../Util/AlphaTextComponent";

import RectangleDivComponent from "../../Util/RectangleDivComponent";
import { activeProjectId } from "../../app/Actions/projectActions";
// import HighlightText from "../../Util/HighlightText";

const Preview = ({ labels, setIsStepCompleted }) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [id, setId] = useState(null);
  const [startId, setStartId] = useState({ id: null, row: null });
  const [endId, setEndId] = useState({ id: null, row: null });
  const [selectLabel, setSelectLabel] = useState(null);
  const [highlights, setHighlights] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [completedSteps, setCompletedSteps] = useState([0]);
  const [isMouseHovering, setIsMouseHovering] = useState(null);
  const [highlightState, setHighlightState] = useState(null);
  useEffect(() => {
    if (highlightState) {
      const newArr = [...completedSteps];
      newArr[4] = 4;
      setCompletedSteps(newArr);
      setIsStepCompleted(true);
    }
  }, [highlightState, completedSteps, highlights]); // Depend on highlightState and completedSteps

  const Letters = [
    "q",
    "w",
    "e",
    "r",
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m",
  ];

  const count = data[selectedRow]?.split(" ").length;
  const newLetters = Letters;
  const len = Letters.length;

  if (count > len) {
    const iIndex = Math.floor(count / len);
    for (let i = 0; i < iIndex; i++) {
      for (let j = 0; j < len; j++) {
        var result = Letters[i].concat(Letters[j]);
        newLetters.push(result);
        if (newLetters.length === count) break;
      }
    }
  }

  const {
    handleLabelSelection,
    handleDeleteClick,
    setHighlights: setLabelHighlights,
    setStartId: setLabelStartId,
    setEndId: setLabelEndId,
    setSelectLabel: setLabelSelectLabel,
    setSelectedRow: setLabelSelectedRow,
  } = useLabelHandlers(
    highlights,
    startId,
    endId,
    selectLabel,
    setIsVisible,
    selectedRow,
    setId,
    setStartId,
    setEndId,
    setSelectLabel,
    setSelectedRow,
    setHighlights,
    null,
    null
  );

  const refFlag = useRef({
    flag: false,
  });
  const lastKeyRef = useRef(null);

  const {
    handleAlphaTextMouseDown,
    handleAlphaTextMouseEnter,
    handleAlphaTextMouseUp,
  } = useKeyboardHandlers(
    lastKeyRef,
    isVisible,
    setIsVisible,
    startId,
    endId,
    selectedRow,
    newLetters,
    setStartId,
    setEndId,
    setId,
    refFlag,
    setSelectedRow,
    setHighlights,
    selectLabel,
    setSelectLabel,
    completedSteps,
    setCompletedSteps,
    handleLabelSelection,
    labels
  );

  useEffect(() => {
    try {
      fetch(TextFile).then((res) => {
        res.text().then((dt) => {
          const newData = dt.split("\n");
          setData(newData);
        });
      });
    } catch (err) {
      //console.log(err);
    }
  }, []);

  const handleMouseEnter = (num) => {
    setIsMouseHovering(num + 1);
  };

  const handleMouseLeave = (a) => {
    setIsMouseHovering(a);
  };
  const handleAlphaLabelTextClick = (highlight) => {
    //console.log("label clicked ", highlight);
    if (highlight) {
      setStartId(highlight.startId);
      setEndId(highlight.endId);
      setIsVisible(true);
      const popupId = Math.floor(
        (highlight.endId.id + 1 + (highlight.startId.id + 1)) / 2
      );
      setId(popupId); // Set the ID for the popup
    }
    //console.log("click ", isVisible, id);
  };

  const HighlightText = ({ text, index, row, highlights }) => {
    // Get all highlights for the given row
    const rowHighlights = highlights.filter((h) => h.startRow === row);

    let highlight;
    let labelStyle = {
      margin: "4px 0",
      padding: "2px 5px", // Default padding
      backgroundColor: "none",
      userSelect: "none", // prevent default text selection
    };

    if (rowHighlights.length > 0) {
      highlight = rowHighlights.find(
        (h) => h.startTokenId <= index && index <= h.endTokenId
      );

      if (highlight) {
        const diff = highlight.endTokenId - highlight.startTokenId;

        if (diff <= 3) {
          labelStyle.padding = "2px 15px";
        }

        labelStyle.backgroundColor =
          highlight.labelId && highlight.clr ? highlight.clr : "#FE8C8E";
      }
    }

    return (
      <div
        className="highlightedText"
        onClick={() => handleAlphaLabelTextClick(highlight)}
        style={labelStyle}
      >
        {text}
      </div>
    );
  };
  const handleUpdateStep = () => {
    const newArr = [...completedSteps]; // Create a new array to avoid mutating the state directly
    newArr[1] = 1; // Update the value at index 1 to 1
    setCompletedSteps(newArr);
  };
  const handleHighlightChange = (highlight) => {
    setHighlightState(highlight);
  };

  return (
    <div style={{ display: "flex", margin: "0 6% 2% 0" }}>
      <PreviewPanel
        completedSteps={completedSteps}
        isMouseHovering={isMouseHovering}
      />
      <PreviewContainer>
        <table>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    setSelectedRow(index);
                    handleUpdateStep();
                  }}
                  className={selectedRow === index ? "selected" : ""}
                  onMouseEnter={(e) => {
                    handleMouseEnter(index);
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    e.stopPropagation();
                    handleMouseLeave(null);
                  }}
                >
                  <TableData
                    style={{
                      backgroundColor:
                        (index + 1) & 1
                          ? "#fffbfa"
                          : "rgba(255, 209, 186, 0.2)",
                    }}
                  >
                    {index + 1}
                  </TableData>

                  <TableData>
                    {row.split(" ").map((text, i) => (
                      <React.Fragment key={i}>
                        {selectedRow === index ? (
                          <TextWithAlpha
                            style={{
                              marginTop: "8px",
                            }}
                          >
                            <ParagraphText className="selected_para">
                              <RectangleDivComponent
                                start_id={startId}
                                end_id={endId}
                                text_index={i}
                                selectLabel={selectLabel}
                                highlights={highlights}
                                row={selectedRow}
                                selectedRow={selectedRow}
                              />

                              <HighlightText
                                text={text}
                                index={i}
                                row={selectedRow}
                                highlights={highlights}
                                handleAlphaLabelTextClick={
                                  handleAlphaLabelTextClick
                                }
                                setHighlights={setHighlights}

                                //  backgroundColor={
                                //   selectLabel ? selectLabel.clr : null
                                // }
                              />
                            </ParagraphText>
                            <AlphaTextComponent
                              alphaKey={newLetters[i]}
                              start_id={startId}
                              end_id={endId}
                              text_index={i}
                              selectLabel={selectLabel}
                              highlights={highlights}
                              row={selectedRow}
                              onHighlightChange={handleHighlightChange}
                              handleAlphaLabelTextClick={
                                handleAlphaLabelTextClick
                              }
                              selectedRow={selectedRow}
                              newLetters={newLetters}
                              onMouseDown={handleAlphaTextMouseDown}
                              onMouseEnter={handleAlphaTextMouseEnter}
                              onMouseUp={handleAlphaTextMouseUp}
                            />
                            {id === i && (
                              <LabelsPopup
                                screen="preview"
                                isVisible={isVisible}
                                setIsVisible={setIsVisible}
                                handleLabelSelection={handleLabelSelection}
                                labels={labels}
                                handleDeleteClick={handleDeleteClick}
                                setHighlights={setHighlights} // Pass setHighlights function
                                highlights={highlights}
                                selectLabel={selectLabel}
                              />
                            )}
                          </TextWithAlpha>
                        ) : (
                          <ParagraphText>
                            <HighlightText
                              text={text}
                              index={i}
                              row={index}
                              highlights={highlights}
                              handleAlphaLabelTextClick={
                                handleAlphaLabelTextClick
                              }
                              setHighlights={setHighlights}
                            />
                          </ParagraphText>
                        )}
                      </React.Fragment>
                    ))}
                  </TableData>
                </TableRow>
              ))}
          </TableBody>
        </table>
      </PreviewContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  const activeProject_id = state.projects.activeProject_id;

  const activeProject = state.projects.projects.find(
    (project) => project.projectId === activeProject_id
  );

  return {
    activeProject_id: activeProject_id,
    labels: activeProject ? activeProject.labels : [],
  };
};

export default connect(mapStateToProps)(Preview); // Wrap your component with connect
