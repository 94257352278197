import React, { useEffect, useRef, useState } from "react";
import Tabs from "./Tabs";
import {
  LabelsSidebarContainer,
  ShortSideBar,
  SearchDiv,
  SearchIcon,
  Input,
  PlusIcon,
  Text,
  LabelListContainer,
  LabelSetWrapper,
  LabelDiv,
  ShortcutIcon,
  LabelName,
  DropdownIcon,
  SubLabelList,
  SubLabelDiv,
  SubLabelName,
  FileListContainer,
  FileItemContainer,
  CheckBoxInput,
  TextAndSupportingText,
  FileNameText,
  FileSizeText,
  AddFilesButton,
  SelectAndDeleteFiles,
  SelectAll,
  DeleteIcon,
  DeleteButton,
  ShortSidebarTabIcon,
  TabsWrapper,
} from "./PageLabelsStyles"; // replace this with the correct path to your styles

import { DropdownButton } from "../Upload/LabelsPopupStyles";
import { ReactComponent as Dropdown } from "../../assets/DropdownIcon.svg";
import { ReactComponent as Plus } from "../../assets/PlusIcon-PageLabel.svg";
import { ReactComponent as SearchIcn } from "../../assets/SearchIcon.svg";
import { ReactComponent as Shortcut } from "../../assets/ShortcutIcon.svg";
import { ReactComponent as Plus_AddFile } from "../../assets/PlusIcon.svg";
import FileUpload from "../Upload/FileUpload";
import { uploadFile, deleteFileFromStorage } from "../../app/firebase.js";
import { ReactComponent as Plus_labels } from "../../assets/PlusBadgeIcon.svg";
import { useSidebar } from "./ContextFile";
import { ReactComponent as Files } from "../../assets/FileIcon.svg";
import { ReactComponent as Bookmark } from "../../assets/BookmarkIcon.svg";

import {
  addProjectDoc,
  updateProjectName,
  updateProjectFilesData,
  addFileDoc,
  deleteFileFromFirestore,
  decrementTotalFiles,
  activeProjectId,
  setProjectDraftStatus,
  _deleteProject,
  _getProject,
} from "../../app/Actions/projectActions.js";
import { connect, useDispatch } from "react-redux";
import useFileHandlers from "../../Util/useFileHandlers";
import { _removeLabel, _setLabels } from "../../app/Actions/labelsActions";
import { ReactComponent as Delete } from "../../assets/DeleteIcon.svg";

let COLORS = [
  "rgba(169, 222, 249, 0.4)",
  "rgba(228, 193, 249, 0.4)",
  "rgba(178, 195, 204, 0.4)",
  "rgba(208, 244, 222, 0.4)",
  "rgba(254, 140, 142, 0.4)",
  "rgba(252, 246, 189, 0.4)",
  "rgba(218, 210, 216, 0.4)",
  "rgba(161, 145, 206, 0.4)",
];

const FileItem = ({
  item,
  isSelected,
  onCheckboxChange,
  handleFileNameClick,
  selectFileToOpen,
}) => {
  const ExtractFileName = ({ storageName }) => {
    const lastIndex = storageName.lastIndexOf("/");
    let fileName = storageName.substring(lastIndex + 1);
    const fileNameParts = fileName.split("-");
    fileName = fileNameParts[fileNameParts.length - 1];
    return fileName;
  };

  // calculate file size into KB, MB, GB.
  const calculateFileSize = (num) => {
    let size = 0;
    if (num <= 1000000) {
      size = (num / 1000).toFixed(2) + " KB";
    } else if (num === 1000001 || num <= 1000000000) {
      size = (num / 1000000).toFixed(2) + " MB";
    } else if (num === 1000000001 || num <= 1000000000000) {
      size = (num / 1000000000).toFixed(2) + " GB";
    }
    return size;
  };

  return (
    <FileItemContainer
      className={selectFileToOpen?.id === item?.id ? "open_file" : null}
      onClick={() => handleFileNameClick(item)}
    >
      <CheckBoxInput
        type="checkbox"
        checked={isSelected}
        onChange={() => onCheckboxChange(item.id)}
      />
      <TextAndSupportingText>
        <FileNameText>
          <ExtractFileName storageName={item.name} />
        </FileNameText>
        <FileSizeText>{calculateFileSize(item.size)}</FileSizeText>
      </TextAndSupportingText>
    </FileItemContainer>
  );
};

const Sidebar = ({
  isSidebarOpen,
  tabs,
  activeTab,
  setIsActiveTab,
  searchVal,
  setSearchVal,
  isActiveTab,
  labelsArray,
  expandedItems,
  toggleExpandItem,
  filesArray,
  selectedFiles,
  handleCheckboxChange,
  projectName,
  draft,
  selectAllFiles,
  handleSelectAllFilesChange,
  handleDeleteFilesClick,
  handleIsSidebarOpenClick,
  setIsArrowIconVisible,
  handleFileNameClick,
  selectFileToOpen,
  currentUser,
  activeProject_id,
}) => {
  const [isDoneButtonOpen, setIsDoneButtonOpen] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [projectCreated, setProjectCreated] = useState(false);
  const [project_id, setProject_id] = useState(activeProject_id);
  const [labelName, setLabelName] = useState("");
  const [randomColor, setRandomColor] = useState(COLORS[0]);
  const inputRef = useRef(null);
  const { setIsSidebarActive } = useSidebar();
  const dispatch = useDispatch();

  const {
    handleOnDropFiles,
    handleBrowseUpload,
    handleRemoveFile,
    handleDragLeave,
    handleOnDragOver,
    handleChange,
  } = useFileHandlers(
    setFileName,
    setFiles,
    setUploadProgress,
    currentUser,
    project_id,
    setProject_id,
    activeProjectId,
    addProjectDoc,
    projectName,
    setProjectCreated,
    setProjectDraftStatus,
    uploadFile,
    setIsDragOver,
    addFileDoc,
    updateProjectFilesData,
    files,
    inputRef,
    deleteFileFromFirestore,
    decrementTotalFiles,
    dispatch,
    deleteFileFromStorage,
    projectCreated
  );

  const getProjectFunc = async (activeProject_id, draft) => {
    await dispatch(_getProject(activeProject_id, draft));
  };

  useEffect(() => {
    getProjectFunc(activeProject_id, draft); //update files array when file is uploaded
  }, [files]);

  const CopyOfColors = [
    "rgba(169, 222, 249, 0.4)",
    "rgba(228, 193, 249, 0.4)",
    "rgba(178, 195, 204, 0.4)",
    "rgba(208, 244, 222, 0.4)",
    "rgba(254, 140, 142, 0.4)",
    "rgba(252, 246, 189, 0.4)",
    "rgba(218, 210, 216, 0.4)",
    "rgba(161, 145, 206, 0.4)",
  ];

  const getRandomNumber = (min, max) => {
    let step1 = max - min + 1;
    let step2 = Math.random() * step1;
    let result = Math.floor(step2) + min;
    return result;
  };

  const handleFilesDoneClick = (activeProject_id, draft, name) => {
    //console.log("done button clicked.. ", activeProject_id, draft, name);
    getProjectFunc(activeProject_id, draft);
    setFiles([]);
  };

  const handleAddLabel = async (e) => {
    if (labelName.trim() === "") {
      // Check if the label name is empty or contains only whitespace
      //console.log("Label name cannot be empty");
      return;
    }

    if (COLORS.length === 1) {
      COLORS = [...CopyOfColors];
    }
    let num = getRandomNumber(0, COLORS.length - 1);
    let randomClr = COLORS[num];
    COLORS.splice(num, 1);
    setRandomColor(randomClr);
    const newLabel = { name: labelName, clr: randomColor };
    setLabelName("");

    try {
      let labelId = await dispatch(_setLabels(newLabel, activeProject_id));

      if (!labelId) {
        //console.log("error occurred ", labelId);
        // If API call fails, revert the state by removing the last added label
        await dispatch(_removeLabel(activeProject_id, newLabel.label_id));
      } else {
        //console.log("labels added successfully ", labelId);
      }
    } catch (error) {
      console.error("Error in adding label:", error);
      // If an error occurs, revert the state by removing the last added label
      await dispatch(_removeLabel(activeProject_id, newLabel.label_id));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //console.log("enter clicked to add labels from Labels Tab...");

      handleAddLabel();
    } else {
      return false;
    }
  };

  const handleLabelsDoneClick = () => {};

  return (
    <React.Fragment>
      {isSidebarOpen ? (
        <LabelsSidebarContainer
          className={!isSidebarOpen ? "short_sidebar" : null}
          onFocus={() => setIsSidebarActive(true)}
          onBlur={() => setIsSidebarActive(false)}
          tabIndex="0" // This makes the div focusable
          onMouseEnter={() => setIsArrowIconVisible(true)}
          onMouseLeave={() => setIsArrowIconVisible(false)}
        >
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            setIsActiveTab={setIsActiveTab}
            setIsDoneButtonOpen={setIsDoneButtonOpen}
          />

          {isActiveTab === 0 &&
            isDoneButtonOpen && ( // remove search bar when it is not add label mode
              <SearchDiv
                className={isActiveTab === 1 ? "file_tab_is_open" : null}
              >
                {isActiveTab !== 0 && !isDoneButtonOpen && (
                  <SearchIcon as={SearchIcn} />
                )}

                <Input
                  type="text"
                  placeholder={
                    isActiveTab === 0 && isDoneButtonOpen
                      ? "Enter Label Name"
                      : "Search"
                  }
                  value={
                    isActiveTab === 0 && isDoneButtonOpen
                      ? labelName
                      : searchVal
                  }
                  onChange={(e) =>
                    isActiveTab === 0 && isDoneButtonOpen
                      ? setLabelName(e.target.value)
                      : setSearchVal(e.target.value)
                  }
                  onKeyPress={handleKeyPress}
                />

                {isActiveTab === 1 && <PlusIcon as={Plus} />}
                {isActiveTab === 0 && isDoneButtonOpen && (
                  <PlusIcon as={Plus_labels} onClick={handleAddLabel} />
                )}
              </SearchDiv>
            )}
          {isActiveTab === 1 && (
            <SelectAndDeleteFiles>
              <SelectAll>
                <CheckBoxInput
                  type="checkbox"
                  checked={
                    selectAllFiles &&
                    filesArray.length > 0 &&
                    selectedFiles.length === filesArray.length
                  }
                  onChange={handleSelectAllFilesChange}
                />
                <Text className="select_all">Select All</Text>
              </SelectAll>
              {selectAllFiles || selectedFiles.length > 0 ? (
                <DeleteButton onClick={handleDeleteFilesClick}>
                  <Text className="total_files_text">Delete Files</Text>
                  <DeleteIcon as={Delete} className="delete_files" />
                </DeleteButton>
              ) : (
                <Text className="total_files_text">
                  Total Files: {filesArray?.length}
                </Text>
              )}
            </SelectAndDeleteFiles>
          )}
          {isActiveTab === 0 && (
            <>
              <LabelListContainer
                className={
                  isActiveTab === 0 && !isDoneButtonOpen ? "show_labels" : null
                }
              >
                {labelsArray?.map((label, index) => (
                  <LabelSetWrapper
                    key={index}
                    style={{ background: label.clr, borderColor: label.clr }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LabelDiv>
                        <ShortcutIcon as={Shortcut} />
                        <LabelName>{label.name}</LabelName>
                      </LabelDiv>
                      {label.subLabels?.length > 0 && (
                        <DropdownButton onClick={() => toggleExpandItem(index)}>
                          {expandedItems?.includes(index) ? (
                            <DropdownIcon as={Dropdown} />
                          ) : (
                            <DropdownIcon as={Dropdown} className="rotate" />
                          )}
                        </DropdownButton>
                      )}
                    </div>
                    {label?.subLabels && expandedItems?.includes(index) && (
                      <SubLabelList>
                        {label.subLabels?.map((subLabel) => (
                          <SubLabelDiv
                            key={subLabel.sublabel_id}
                            style={{ borderColor: label.clr }}
                          >
                            <ShortcutIcon />
                            <SubLabelName>{subLabel.name}</SubLabelName>
                          </SubLabelDiv>
                        ))}
                      </SubLabelList>
                    )}
                  </LabelSetWrapper>
                ))}
              </LabelListContainer>
              {isDoneButtonOpen ? (
                <AddFilesButton
                  onClick={() => {
                    setIsDoneButtonOpen(!isDoneButtonOpen);
                    handleLabelsDoneClick();
                  }}
                >
                  Done
                </AddFilesButton>
              ) : (
                <AddFilesButton
                  onClick={() => {
                    setIsDoneButtonOpen(!isDoneButtonOpen);
                  }}
                >
                  Add Labels
                  <PlusIcon as={Plus_AddFile} className="add_files" />
                </AddFilesButton>
              )}
            </>
          )}
          {isActiveTab === 1 && (
            <>
              {isDoneButtonOpen ? (
                <FileUpload
                  handleOnDragOver={handleOnDragOver}
                  handleOnDropFiles={handleOnDropFiles}
                  handleDragLeave={handleDragLeave}
                  isDragOver={isDragOver}
                  handleBrowseUpload={handleBrowseUpload}
                  handleChange={handleChange}
                  inputRef={inputRef}
                  files={files}
                  handleRemoveFile={handleRemoveFile}
                  currentUser={currentUser}
                  project_id={project_id}
                  screen="page_label"
                />
              ) : (
                <FileListContainer>
                  {filesArray?.map((file) => (
                    <FileItem
                      key={file.id}
                      item={file}
                      isSelected={selectedFiles.includes(file.id)}
                      onCheckboxChange={handleCheckboxChange}
                      handleFileNameClick={handleFileNameClick}
                      selectFileToOpen={selectFileToOpen}
                    />
                  ))}
                </FileListContainer>
              )}
              {isDoneButtonOpen ? (
                <AddFilesButton
                  onClick={() => {
                    setIsDoneButtonOpen(!isDoneButtonOpen);
                    handleFilesDoneClick(activeProject_id, draft, projectName);
                  }}
                >
                  Done
                </AddFilesButton>
              ) : (
                <AddFilesButton
                  onClick={() => {
                    setIsDoneButtonOpen(!isDoneButtonOpen);
                  }}
                >
                  Add Files
                  <PlusIcon as={Plus_AddFile} className="add_files" />
                </AddFilesButton>
              )}
            </>
          )}
        </LabelsSidebarContainer>
      ) : (
        <ShortSideBar>
          <ShortSidebarTabIcon
            as={Files}
            onClick={() => {
              handleIsSidebarOpenClick();
              setIsActiveTab(1);
            }}
          />
          <ShortSidebarTabIcon
            as={Bookmark}
            onClick={() => {
              handleIsSidebarOpenClick();
              setIsActiveTab(0);
            }}
          />
        </ShortSideBar>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const activeProject_id = state.projects.activeProject_id;

  const activeProject = state.projects.projects.find(
    (project) => project?.projectId === activeProject_id
  );

  return {
    activeProject_id: activeProject_id,
    labelsArray: activeProject ? activeProject.labels : [],
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, {
  addProjectDoc,
  updateProjectName,
  addFileDoc,
  deleteFileFromFirestore,
  decrementTotalFiles,
  activeProjectId,
  _deleteProject,
  _getProject,
  _setLabels,
})(Sidebar);
