import styled from "styled-components";

export const PanelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #1d2939;
  height: 100vh;
  width: 25%;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StepBase = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  position: relative;
  margin-left: 3rem;
  margin-right: 2rem;
`;

export const StepBaseIcon = styled.svg`
  height: 2rem;
  width: 2rem;
  position: relative;
`;

export const ConnectorWrap = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0px 0px 4px;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  //flex-grow: 1;
`;

export const ConnectorIcon = styled.div`
  width: 2px;
  height: 46px;
  background-color: #667085;
  flex-grow: 1;
`;

export const TextAndSupportingText = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin-left: 1rem;
  caret-color: transparent;
`;

export const PanelText = styled.p`
  align-self: stretch;
  color: #344053;
  font-family: "Inter", Helvetica;
  font-weight: 500;
  position: relative;
  //white-space: nowrap;
  font-size: 1rem;
  color: #ffffff;
  margin: 0 0 2px;
`;

export const SupportingText = styled.p`
  align-self: stretch;
  color: #667084;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  position: relative;
  //white-space: nowrap;
  font-size: 1rem;
  color: #d0d5dd;
  margin: 0 0 36px;
`;
