import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  setDoc,
  deleteDoc, // Add this
} from "firebase/firestore";
import { db } from "../firebase";
import * as types from "./actionTypes";
import { nanoid } from "nanoid";
import { setProjectDraftStatus } from "./projectActions";

export const _getLabels = (projectId) => async (dispatch) => {
  try {
    const projectRef = doc(db, "projects", projectId);
    const projectDoc = await getDoc(projectRef);
    if (!projectDoc.exists()) {
      // console.log("Project document not found");
      return;
    }

    const labelListRef = collection(projectRef, "labels");
    const labelListQuery = query(labelListRef);

    const labelListSnapshot = await getDocs(labelListQuery);
    const labels = labelListSnapshot.docs.map(async (doc) => {
      const labelData = doc.data();
      const subLabelsRef = collection(doc.ref, "subLabels");
      const subLabelsSnapshot = await getDocs(subLabelsRef);
      const subLabels = subLabelsSnapshot.docs.map((subLabelDoc) =>
        subLabelDoc.data()
      );
      return {
        ...labelData,
        subLabels,
      };
    });

    const updatedLabels = await Promise.all(labels);

    dispatch({
      type: types.GET_LABELS,
      payload: updatedLabels,
    });
  } catch (error) {
    console.error("Error getting labels:", error);
  }
};

export const getLabelsLocal = (projectId) => (getState) => {
  try {
    const { projects } = getState().projects;
    // console.log("projects are ", projects);
    const activeProject = projects.find(
      (project) => project.projectId === projectId
    );

    if (!activeProject) {
      // console.log("Project not found in local state");
      return [];
    }

    const labels = activeProject.labels.map((label) => ({
      ...label,
      subLabels: label.subLabels || [],
    }));

    return labels;
  } catch (error) {
    // console.error("Error getting labels from local state:", error);
    return [];
  }
};

export const _initSubLabels =
  (projectId, labelId, newSubLabelName) => async (dispatch, getState) => {
    try {
      //  const labels = getLabelsLocal(projectId)(getState);
      // console.log("labels are ", labels);
      //  const labelIndex = labels.findIndex(
      //     (label) => label.label_id === labelId
      //   );

      //   if (labelIndex === -1) {
      //  //console.log("Label not found");
      ////    return;
      //  }

      const subLabelId = generateUniqueId();
      const subLabelData = {
        name: newSubLabelName,
        sublabel_id: subLabelId,
      };

      //   const updatedLabels = labels.map((label, index) => {
      //    if (index === labelIndex) {
      //      return {
      //       ...label,
      //       subLabels: [...(label.subLabels || []), subLabelData],
      //     };
      //    }
      //    return label;
      //   });

      dispatch({
        type: types.INIT_SUBLABELS,
        payload: {
          project_id: projectId,
          label_id: labelId,
          subLabelData,
        },
      });

      // Create a reference to the project document
      const projectDocRef = doc(db, "projects", projectId);

      // Create a reference to the labels collection under the project document
      const labelsCollectionRef = collection(projectDocRef, "labels");
      try {
        // Create a reference to the label document
        const labelDocRef = doc(labelsCollectionRef, labelId);

        // Create a reference to the sub-labels collection under the label document
        const subLabelsCollectionRef = collection(labelDocRef, "subLabels");

        // Create the sub-labels collection document
        await setDoc(doc(subLabelsCollectionRef, subLabelId), subLabelData);

        // console.log("Sub-label collection created successfully!");
        return subLabelId;
      } catch (error) {
        // console.error("Error creating sub-label collection:", error);
      }
    } catch (error) {
      // console.error("Error initiating sublabels:", error);
    }
  };

export const _updateLabelName =
  (newNameValue, labelId, projectId) => async (dispatch) => {
    try {
      const updatedLabels = {
        project_id: projectId,
        label_id: labelId,
        newLabelName: newNameValue,
      };

      dispatch({
        type: types.UPDATE_LABEL_NAME,
        payload: updatedLabels,
      });

      const labelRef = doc(db, "projects", projectId, "labels", labelId);
      await updateDoc(labelRef, { name: newNameValue });
    } catch (err) {
      console.error("Error updating label: ", err);
    }
  };

export const _setSubLabels =
  (subLabelValue, labelId, subLabelId, projectId) => async (dispatch) => {
    try {
      // Dispatch the action to update the local state immediately
      dispatch({
        type: types.SET_SUBLABELS,
        payload: {
          project_id: projectId,
          label_id: labelId,
          subLabel_id: subLabelId,
          subLabelValue,
        },
      });

      const subLabelDocRef = doc(
        db,
        "projects",
        projectId,
        "labels",
        labelId,
        "subLabels",
        subLabelId
      );

      await updateDoc(subLabelDocRef, {
        name: subLabelValue,
      });

      // console.log("sublabels added...");
    } catch (error) {
      // console.error("Error adding/updating sublabels:", error);
    }
  };

export const _removeLabel = (projectId, labelId) => async (dispatch) => {
  try {
    dispatch({
      type: types.REMOVE_LABEL,
      payload: { project_id: projectId, label_id: labelId },
    });

    const labelRef = doc(db, "projects", projectId, "labels", labelId);
    await deleteDoc(labelRef);

    // Check if the labels collection is empty
    const labelsCollectionRef = collection(db, "projects", projectId, "labels");
    const labelsSnapshot = await getDocs(labelsCollectionRef);
    const isEmpty = labelsSnapshot.empty;

    if (isEmpty) {
      await dispatch(setProjectDraftStatus(true, projectId));
    } else {
      // console.log("The labels collection is not empty.");
    }
  } catch (error) {
    console.error("Error removing label:", error);
  }
};

const generateUniqueId = () => {
  return nanoid();
};

export const _setLabels = (newLabel, projectId) => async (dispatch) => {
  let labelId;
  // console.log("Add label to id: ", projectId, newLabel);
  try {
    const labelsRef = collection(db, "projects", projectId, "labels");
    labelId = generateUniqueId();
    newLabel.label_id = labelId;

    dispatch({
      type: types.SET_LABELS,
      payload: { project_id: projectId, label: newLabel },
    });

    const labelRef = doc(labelsRef, labelId);
    await setDoc(labelRef, newLabel);

    await dispatch(setProjectDraftStatus(false, projectId));

    dispatch({
      type: types.UPDATE_LABEL_ID,
      payload: { project_id: projectId, oldId: labelId, newId: labelRef.id },
    });
    return labelId;
  } catch (error) {
    // console.log("Error while adding labels: ", error);
    dispatch(_removeLabel(projectId, labelId));
    return null;
  }
};
