import React from "react";
import {
  ArrowIcon,
  Button,
  FooterText,
  Footer,
  ProgressBarContainer,
  Progress,
} from "./PageLabelsStyles";
import { ReactComponent as RightArrow_white } from "../../assets/RightArrowIcon.svg";

const FooterComponent = ({ goToNextPage, progress, showStepNumber }) => {
  const StepGuideArray = [
    "Click on a section or press relevant numeric key to start tagging ",
    "On your keyboard, press any alphabet tile on a word to start tagging",
    "Press Spacebar to start annotating",
    "Press any key on keyboard to annotate till that key ",
    "Press “Enter” to select a label ",
  ];
  return (
    <Footer>
      {/* <ProgressBarContainer>
        <Progress style={{ width: `${progress}%` }} />
      </ProgressBarContainer> */}
      <FooterText>{StepGuideArray[showStepNumber]}</FooterText>
      <Button className="footer_button" onClick={goToNextPage}>
        <span className="footer_next">Next</span>
        <ArrowIcon as={RightArrow_white} className="right_arrow_white" />
      </Button>
    </Footer>
  );
};

export default FooterComponent;
