import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Project from "../../Model/Project";
import * as types from "./actionTypes";
import {
  updateDoc,
  serverTimestamp,
  deleteDoc,
  getFirestore,
  increment,
} from "firebase/firestore";

// Add action creator for adding a project
export const addProjectSuccess = (project) => {
  return {
    type: types.ADD_PROJECT_SUCCESS,
    project,
  };
};

export const addProjectError = (error) => {
  return {
    type: types.ADD_PROJECT_ERROR,
    error,
  };
};

// Modify addProjectDoc function
export const addProjectDoc = (name, createdBy, projectId) => {
  return async (dispatch, getState) => {
    const firestore = getFirestore();
    const createdDate = new Date().toISOString();

    //set this before uploding file so that temp is not needed
    const projectRef = doc(collection(firestore, "projects"), projectId);

    const project = new Project(name, createdBy, createdDate, projectId);
    project.setProjectId(projectId);
    // console.log("projecto ", project);

    const projectObject = project.toFirestoreObject();

    try {
      await setDoc(projectRef, projectObject);
      // console.log("Document written with ID: ", projectId);

      // Dispatch the action with the added project
      dispatch(addProjectSuccess({ ...projectObject, id: projectId }));

      return projectId; // Return the generated ID
    } catch (error) {
      console.error("Error adding document: ", error);

      // Dispatch the action with the error
      dispatch(addProjectError(error));

      return null; // Return null if there's an error
    }
  };
};

// updateProjectName function
export const updateProjectName = (projectId, updatedName, totalFiles) => {
  return async (dispatch) => {
    try {
      const projectRef = doc(getFirestore(), "projects", projectId);
      await updateDoc(projectRef, {
        name: updatedName,
        updatedAt: new Date().toISOString(),
        totalFiles: totalFiles,
      });

      // console.log("Project name updated successfully");
    } catch (error) {
      console.error("Error updating project name:", error);
    }
  };
};

export const updateProjectFilesData =
  (projectId, totalFiles) => async (dispatch) => {
    const projectRef = doc(getFirestore(), "projects", projectId);

    await updateDoc(projectRef, {
      totalFiles: totalFiles,
    });
  };

export const addFileDoc =
  (fileId, fileName, size, fileUrl, fileFormat, createdDate, projectId) =>
  async (dispatch) => {
    try {
      const fileData = {
        id: fileId,
        name: fileName,
        size: size,
        url: fileUrl,
        format: fileFormat,
        createdDate: createdDate,
      };
      if (fileData.createdDate && fileData.createdDate instanceof Date) {
        fileData.createdDate = fileData.createdDate.toISOString();
      }

      dispatch({
        type: types.SET_FILES,
        payload: { project_id: projectId, file: fileData },
      });
      const fileDocRef = doc(
        collection(getFirestore(), "projects", projectId, "files"),
        fileId
      );
      await setDoc(fileDocRef, fileData);

      return fileId;
    } catch (error) {
      console.error("Error adding file document: ", error);
      return null;
    }
  };

export const deleteFileFromFirestore = (projectId, fileId) => {
  return async (dispatch) => {
    try {
      // console.log("delete file from firestore: ", projectId, fileId);

      const fileDocRef = doc(
        getFirestore(),
        "projects",
        projectId,
        "files",
        fileId
      );
      await deleteDoc(fileDocRef);
      // console.log("File document deleted successfully");
      return true; // Return true on success
    } catch (error) {
      console.error("Error deleting file document:", error);
      return false; // Return false on error
    }
  };
};

export const deleteFiles = (projectId, selectedFileIds) => async (dispatch) => {
  try {
    dispatch({
      type: types.DELETE_FILES,
      payload: { project_id: projectId, selectedFileIds },
    });

    for (const file_id of selectedFileIds) {
      await dispatch(deleteFileFromFirestore(projectId, file_id));
    }
  } catch (error) {
    // console.log("error deleting files: ", error);
  }
};

export const decrementTotalFiles = (projectId) => {
  return async (dispatch) => {
    try {
      const projectRef = doc(getFirestore(), "projects", projectId);
      await updateDoc(projectRef, {
        totalFiles: increment(-1),
      });

      // console.log("Total files count decremented successfully");
    } catch (error) {
      console.error("Error decrementing total files count:", error);
    }
  };
};

export const activeProjectId = (projectId) => (dispatch) => {
  dispatch({
    type: types.ACTIVE_PROJECT_ID,
    projectId,
  });
};

export const setCurrentFileIndex = (index, activeProject_id) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_FILE_INDEX,
    payload: { index: index, projectId: activeProject_id },
  });
};

export const setCurrentFileId = (id, activeProject_id) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_FILE_ID,
    payload: { id: id, projectId: activeProject_id },
  });
};

export const setProjectDraftStatus =
  (status, project_id) => async (dispatch) => {
    try {
      const projectRef = doc(getFirestore(), "projects", project_id);
      await updateDoc(projectRef, {
        draft: status,
      });

      dispatch({
        type: types.SET_PROJECT_DRAFT_STATUS,
      });
    } catch (error) {
      console.error("error while adding draft status: ", error);
    }
  };

export const _getProject = (projectId, draft) => async (dispatch) => {
  try {
    // Fetch the project document based on the project ID
    const projectDocRef = doc(db, "projects", projectId);
    const projectDocSnapshot = await getDoc(projectDocRef);

    // Check if the project document exists
    if (projectDocSnapshot.exists()) {
      const project = projectDocSnapshot.data();

      // Exclude the "draft" field
      //const { draft, ...projectData } = project;

      // Fetch the files subcollection
      const filesCollectionRef = collection(projectDocRef, "files");
      const filesSnapshot = await getDocs(filesCollectionRef);
      const files = filesSnapshot.docs.map((fileDoc) => fileDoc.data());
      // Update the project data with the files  arrays
      project.files = files;

      if (!draft) {
        // Fetch the labels collection
        const labelsCollectionRef = collection(projectDocRef, "labels");
        const labelsSnapshot = await getDocs(labelsCollectionRef);
        const labels = [];

        for (const labelDoc of labelsSnapshot.docs) {
          const labelData = labelDoc.data();

          // Check if the subLabels collection exists under the current label
          const sublabelsCollectionRef = collection(labelDoc.ref, "subLabels");
          const sublabelsSnapshot = await getDocs(sublabelsCollectionRef);

          if (sublabelsSnapshot.empty) {
            // If subLabels subcollection is not present, fetch only the label
            labels.push(labelData);
          } else {
            const sublabels = sublabelsSnapshot.docs.map((sublabelDoc) =>
              sublabelDoc.data()
            );
            labelData.subLabels = sublabels;
            labels.push(labelData);
          }
        }

        // Update the project data with the labels array
        project.labels = labels;
      }

      // console.log("fetched project Data: ", project);
      //setProjectData([project]); // Assign the project data to the projectData array
      dispatch({
        type: types.GET_PROJECT,
        project,
      });
    } else {
      // console.log("Project document does not exist");
    }
  } catch (error) {
    // console.log("Error fetching project data: ", error);
  }
};

export const _deleteProject = (projectId) => async (dispatch) => {
  try {
    dispatch({
      type: types.DELETE_PROJECT,
      projectId,
    });

    const fileDocRef = doc(getFirestore(), "projects", projectId);
    await deleteDoc(fileDocRef);
  } catch (error) {
    // console.log("error : ", error);
  }
};

export const _createNewProject = () => (dispatch) => {
  let project = null;
  dispatch({
    type: types.CREATE_NEW_PROJECT,
    project,
  });
};

export const _setTotalPages = (projectId, fileId, totalPages) => (dispatch) => {
  // console.log("set total pages called...", projectId, fileId, totalPages);
  dispatch({
    type: types.SET_TOTAL_PAGES,
    payload: { project_id: projectId, file_id: fileId, totalPages },
  });
};

export const _setSharedUser = (projectId, user) => async (dispatch) => {
  // console.log("share project: ", projectId, user);
  try {
    if (user && user.uid) {
      const projectDoc = doc(db, "projects", projectId);
      const projectSnapshot = await getDoc(projectDoc);

      if (
        projectSnapshot.exists() &&
        projectSnapshot.data().createdBy === user.uid
      ) {
        // If currentUser is the creator, return null
        return null;
      }
    }

    const sharedUsersRef = collection(db, "projects", projectId, "users");
    const userData = {
      user_id: user.uid,
      profilePicture: user.photoURL,
      role: "",
    };
    dispatch({
      type: types.SET_SHARED_USER,
      payload: { projectId, userData },
    });

    const usersRes = await addDoc(sharedUsersRef, userData);
    // console.log("shared user added: ", usersRes.id);
  } catch (error) {
    // console.log("Error at add shared user :", error);
  }
};

export const _setSharedUserViaEmail =
  (projectId, email) => async (dispatch) => {
    // console.log("share project: ", projectId, email);
    try {
      const usersCollection = collection(db, "users");
      const emailQuery = query(usersCollection, where("email", "==", email));
      const querySnapshot = await getDocs(emailQuery);
      let user = null;

      if (!querySnapshot.empty) {
        // Assuming there's only one user with the given email
        user = querySnapshot.docs[0].data();
        // console.log("user data: ", user);

        const sharedUsersRef = collection(db, "projects", projectId, "users");
        const userData = {
          user_id: user.user_id || user.uid || null,
          profilePicture: user.photoURL || null,
          role: "",
        };
        dispatch({
          type: types.SET_SHARED_USER,
          payload: { projectId, userData },
        });

        const usersRes = await addDoc(sharedUsersRef, userData);
        // console.log("shared user added: ", usersRes.id);
      }
    } catch (error) {
      // console.log("Error at add shared user :", error);
    }
  };

// Function to fetch projects based on userID from the "projects" collection
export const fetchProjectsByCreatedBy = async (userID) => {
  try {
    const projectsCollectionRef = collection(db, "projects");
    const projectsQuery = query(
      projectsCollectionRef,
      where("createdBy", "==", userID)
    );
    const projectSnapshot = await getDocs(projectsQuery);
    const projects = [];

    projectSnapshot.forEach((doc) => {
      projects.push(doc.data());
    });

    return projects;
  } catch (error) {
    console.error("Error fetching projects by createdBy:", error);
    return [];
  }
};

// Function to fetch projects based on userID from the "users" subcollection
export const fetchProjectsByUserID = async (userID) => {
  const projectsRef = collection(db, "projects");
  const q = query(projectsRef);

  try {
    const querySnapshot = await getDocs(q);
    const projects = [];

    // Create an array of promises to fetch projects
    const fetchProjectsPromises = [];

    querySnapshot.forEach((doc) => {
      const usersSubcollectionRef = collection(doc.ref, "users");
      const usersQuery = query(
        usersSubcollectionRef,
        where("user_id", "==", userID)
      );

      // Add a promise for each project to the array
      fetchProjectsPromises.push(
        getDocs(usersQuery).then((userSnapshot) => {
          if (!userSnapshot.empty) {
            projects.push(doc.data());
          }
        })
      );
    });

    // Wait for all the promises to resolve
    await Promise.all(fetchProjectsPromises);

    return projects;
  } catch (error) {
    console.error("Error fetching projects by userID:", error);
    return [];
  }
};

// Function to merge two arrays of projects
export const mergeProjectsArrays = (arr1, arr2) => {
  const mergedArray = [...arr1, ...arr2];
  return mergedArray;
};
