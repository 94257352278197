import styled from "styled-components";

export const AvatarWrapper = styled.div`
  width: 80px;
  height: 80px;
  border: 4px solid #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 200px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    z-index: 10;
    position: absolute;
    margin-left: 20px;
    opacity: 0;
  }
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export const PlusSquareIcon = styled.svg`
  width: 18px;
  height: 18px;
  background: transparent;
  position: absolute;
  //right: 50px;
  //top: 200px;
`;
