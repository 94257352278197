import styled from "styled-components";

export const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  caret-color: transparent;
`;

export const Title = styled.div``;

export const Text = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #14213d;
  margin: 0;
`;

export const SupportiveText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  margin: 12px 0;
`;

export const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  //width: 300px;
`;

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #14213d;
  margin-bottom: 6px;
  margin-top: 20px;
`;

export const InputWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 360px;
`;

export const Input = styled.input`
  //margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 332px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  caret-color: #667085;

  &:focus {
    outline: none;
  }
`;

export const AlertIcon = styled.svg`
  width: 13.33px;
  height: 13.33;
  position: absolute;
  margin: 15.33px 0 15.33px 336.33px;
`;

export const HintText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f04438;
  margin: 6px 0 0;
  width: 360px;
`;

export const SignupButton = styled.button`
  align-items: center;
  background-color: #e26d5c;
  border: 1px solid #e26d5c;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 24px;
  &:hover {
    background-color: #cc5b4a;
  }
`;

export const GoogleSignupButton = styled.button`
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #344053;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  &:hover {
    background-color: #f0f1f3;
  }
`;

export const GoogleIcon = styled.svg`
  height: 24px;
  min-width: 24px;
  position: relative;
`;

export const GoogleButtonText = styled.span`
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

export const SignInControl = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin: 32px 0 0;
`;

export const LoginText = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #d66353;
  margin: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ErrorMessageText = styled.div`
  background-color: rgba(226, 109, 92, 0.3);
  color: #cc5b4a;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 8px;
  margin: 0;
  font-size: 12px;
  font-family: "Inter";
`;
