import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  caret-color: transparent;
`;

export const FeaturedIcon = styled.svg`
  width: 56px;
  height: 56px;
  position: relative;
  margin: 0 0 24px;
`;

export const TextAndSupportingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 360px;
`;

export const Text = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #101828;
  text-align: center;
  margin: 0 0 12px;
`;

export const SupportingText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  margin: 0 0 32px;
`;

export const ForgetPassForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 360px;
`;

export const InputWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 360px;
`;

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0 0 6px;
  color: #344054;
  align-self: flex-start;
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding: 10px 14px;
  //gap: 8px;
  width: 332px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  caret-color: #667085;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  //margin: 0 0 24px;
  &:focus {
    outline: none;
  }
`;

export const AlertIcon = styled.svg`
  width: 13.33px;
  height: 13.33px;
  position: absolute;
  margin: 15.33px 0 15.33px 336.33px;
`;

export const HintText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f04438;
  margin: 6px 0 0;
  width: 360px;
`;

export const ResetPassButton = styled.button`
  //box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  //padding: 10px 18px;
  gap: 8px;
  width: 360px;
  height: 44px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: #ef7866;
  border: 1px solid #ef7866;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin: 24px 0 32px;
  cursor: pointer;
  &:hover {
    background-color: #cc5b4a;
  }
`;

export const LeftArrowIcon = styled.svg`
  width: 11.67px;
  height: 11.67px;
  position: relative;
  margin-right: 12.17px;
`;

export const RedirectText = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
