const initialState = {
  loading: false,
  currentUser: null,
  authMessage: null,
  totalProject: 0,
  projects: [],
  activeProject_id: null,
  addProjectError: null,
  email: null,
};

export default initialState;
