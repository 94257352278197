import React, { useState, useEffect } from "react";
import validator from "validator";
import {
  AlertIcon,
  Container,
  FeaturedIcon,
  ForgetPassForm,
  HintText,
  Input,
  InputWithIcon,
  Label,
  LeftArrowIcon,
  RedirectText,
  ResetPassButton,
  SupportingText,
  Text,
  TextAndSupportingText,
} from "./ForgetPasswordStyles";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrowIcon.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as FeaturedLogo } from "../../assets/FeaturedIcon.svg";
import CheckEmail from "./CheckEmail";
import { ReactComponent as Alert } from "../../assets/Alert-CircleIcon.svg";
import { sendPasswordReset, setEmail } from "../../app/Actions/authActions";
import { connect } from "react-redux";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../app/firebase";

const ForgetPassword = ({ sendPasswordResetAPI, authMessage, setEmailAPI }) => {
  const navigate = useNavigate(); // react-router's navigate function
  const location = useLocation();

  const email2 = location.state?.email;

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    console.log("Email2 is: ", email2); // Debugging line

    if (email2) {
      setEmail(email2);
    }
  }, [email2]); // This ensures the effect runs only when email2 changes

  const validateEmail = (emailText) => {
    if (!validator.isEmail(emailText)) {
      setEmailError("Please enter a valid email address");
      return false;
    } else if (authMessage?.props?.error?.code === "auth/missing-email") {
      setEmailError("Email cannot be empty");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const SearchUser = async (email) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDoc(q);
      if (querySnapshot.empty) {
        setEmailError("No user found with this email.");
      } else {
        //console.log("fetched email data:", querySnapshot);
      }
    } catch (error) {
      console.error("Error while fetching email:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("forgetPassword 1 ", emailError, email);

    setEmailError(""); // Reset the error message

    if (!email) {
      setEmailError("Please enter an email address");
      return;
    }

    // Validate the email and proceed only if valid
    const isValidEmail = validateEmail(email); // Assume validateEmail returns boolean now
    if (!isValidEmail) {
      return;
    }

    try {
      await SearchUser(email);
      if (!emailError) {
        // Check if user was found
        await sendPasswordResetAPI(email);
        await setEmailAPI(email);
        navigate("/check-email", { state: { email } });
      }
    } catch (error) {
      // Handle error
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Container>
        <FeaturedIcon as={FeaturedLogo} />
        <TextAndSupportingText>
          <Text>Forgot password?</Text>
          <SupportingText>
            No worries, we’ll send you reset instructions.
          </SupportingText>
        </TextAndSupportingText>
        <ForgetPassForm onSubmit={handleSubmit}>
          <Label>Email</Label>
          <InputWithIcon>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ borderColor: emailError ? "#FDA29B" : "" }}
            />
            {emailError && <AlertIcon as={Alert} />}
          </InputWithIcon>
          {emailError.length !== 0 && <HintText>{emailError}</HintText>}
          <ResetPassButton type="submit">Reset password</ResetPassButton>
        </ForgetPassForm>
        <Link to="/" style={{ textDecoration: "none" }}>
          <RedirectText>
            <LeftArrowIcon as={LeftArrow} />
            Back to log in
          </RedirectText>
        </Link>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authMessage: state.auth.authMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendPasswordResetAPI: (email) => dispatch(sendPasswordReset(email)),
  setEmailAPI: (email) => dispatch(setEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
