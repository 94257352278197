import React, { useState } from "react";
import {
  Div,
  Input,
  LabelsContainer,
  PlusBadgeIcon,
  Text,
  LabelListContainer,
  LabelDiv,
  ShortcutIcon,
  PlusIcon,
  LabelNameText,
  LabelSetWrapper,
  SubLabelWrapper,
  SubLabelDiv,
  LabelEditInput,
  SubLabelEditInput,
  SaveIcon,
  CloseIcon,
  // SaveIcon,
} from "./LabelsStyles";
import { ReactComponent as PlusBadge } from "../../assets/PlusBadgeIcon.svg";
import { ReactComponent as Shortcut } from "../../assets/ShortcutIcon.svg";
import { ReactComponent as Plus_Label } from "../../assets/PlusIcon-Labels.svg";
import { ReactComponent as Close } from "../../assets/CloseIcon.svg";
import {
  _initSubLabels,
  _setLabels,
  _setSubLabels,
  _updateLabelName,
  _removeLabel, // Import _removeLabel action
} from "../../app/Actions/labelsActions";
import { connect } from "react-redux";

let COLORS = [
  "rgba(169, 222, 249, 0.4)",
  "rgba(228, 193, 249, 0.4)",
  "rgba(178, 195, 204, 0.4)",
  "rgba(208, 244, 222, 0.4)",
  "rgba(254, 140, 142, 0.4)",
  "rgba(252, 246, 189, 0.4)",
  "rgba(218, 210, 216, 0.4)",
  "rgba(161, 145, 206, 0.4)",
];

const Labels = ({
  _setLabelAPI,
  //project_id,
  _updateLabelNameAPI,
  labelsArray,
  _initSubLabelsAPI,
  _setSubLabelsAPI,
  activeProject_id,
  _removeLabelAPI, // Rename _removeLabel to _removeLabelAPI
}) => {
  const [labelName, setLabelName] = useState("");
  const [randomColor, setRandomColor] = useState(COLORS[0]);
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [editedLabelName, setEditedLabelName] = useState("");
  const [editedLabelId, setEditedLabelId] = useState(null); // Track the edited label ID
  const [editedSubLabelId, setEditedSubLabelId] = useState(null); // Track the sub edited label ID
  const [editedSubLabelName, setEditedSubLabelName] = useState(""); // Define the editedSubLabelName state
  const [isEditingSubLabel, setIsEditingSubLabel] = useState(false); // Track whether a sublabel is being edited
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddLabel = async (e) => {
    if (labelName.trim() === "") {
      // Check if the label name is empty or contains only whitespace
      //console.log("Label name cannot be empty");
      // set error message
      setErrorMessage("Label name cannot be empty");
      return;
    }

    if (COLORS.length === 1) {
      COLORS = [...CopyOfColors];
    }
    let num = getRandomNumber(0, COLORS.length - 1);
    let randomClr = COLORS[num];
    COLORS.splice(num, 1);
    setRandomColor(randomClr);
    const newLabel = { name: labelName, clr: randomColor };
    setLabelName("");

    try {
      let labelId = await _setLabelAPI(newLabel, activeProject_id);

      if (!labelId) {
        //console.log("error occurred ", labelId);
        // If API call fails, revert the state by removing the last added label
        _removeLabelAPI(activeProject_id, newLabel.label_id);
      } else {
        //console.log("labels added successfully ", labelId);
      }
    } catch (error) {
      console.error("Error in adding label:", error);
      // If an error occurs, revert the state by removing the last added label
      _removeLabelAPI(activeProject_id, newLabel.label_id);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddLabel();
    } else {
      return false;
    }
  };

  const handleRemoveLabel = (projectId, labelId) => {
    _removeLabelAPI(projectId, labelId);
  };

  const CopyOfColors = [
    "rgba(169, 222, 249, 0.4)",
    "rgba(228, 193, 249, 0.4)",
    "rgba(178, 195, 204, 0.4)",
    "rgba(208, 244, 222, 0.4)",
    "rgba(254, 140, 142, 0.4)",
    "rgba(252, 246, 189, 0.4)",
    "rgba(218, 210, 216, 0.4)",
    "rgba(161, 145, 206, 0.4)",
  ];

  const getRandomNumber = (min, max) => {
    let step1 = max - min + 1;
    let step2 = Math.random() * step1;
    let result = Math.floor(step2) + min;
    return result;
  };

  const handleNewLabelNameChange = async (newNameValue, labelId, projectId) => {
    await _updateLabelNameAPI(newNameValue, labelId, projectId);
  };

  const handleNewSubLabelNameChange = async (
    newNameValue,
    labelId,
    sublabel_id,
    projectId
  ) => {
    await _setSubLabelsAPI(newNameValue, labelId, sublabel_id, projectId);
  };

  const handleAddSubLabel = async (projectId, labelId, item) => {
    const newSubLabelName = `Sub Label ${item?.subLabels?.length + 1 || 1}`;
    await _initSubLabelsAPI(projectId, labelId, newSubLabelName);
  };

  function fetchSublabelName(labelId, sublabelId) {
    const label = labelsArray.find((label) => label.label_id === labelId);

    if (label) {
      const sublabel = label.subLabels.find(
        (sublabel) => sublabel.sublabel_id === sublabelId
      );

      if (sublabel) {
        return sublabel.name;
      }
    }

    return null;
  }

  const handleEditSubLabel = (labelId, subLabelId) => {
    //console.log("editing sublabel ", subLabelId);
    setEditedSubLabelId(subLabelId);
    setIsEditingSubLabel(true);
    const editedSublabel = fetchSublabelName(labelId, subLabelId);
    //console.log("editing sub label name: ", editedSublabel);
    if (editedSublabel) {
      setEditedSubLabelName(editedSublabel);
    }
  };

  const handleEditLabel = (labelId) => {
    //console.log("editing label ", labelId);
    setEditedLabelId(labelId); // Set the edited label ID
    setIsEditingLabel(true); // Set isEditingLabel to true when starting to edit label
    const editedLabel = labelsArray.find((label) => label.label_id === labelId);
    if (editedLabel) {
      setEditedLabelName(editedLabel.name); // Set the edited label name
    }
  };

  const handleSaveLabelName = async (labelId, projectId) => {
    //console.log("Saving label name...");

    setIsEditingLabel(false);
    handleNewLabelNameChange(editedLabelName, labelId, projectId);
  };

  const handleSaveSubLabelName = async (labelId, projectId, sublabel_id) => {
    //console.log("Saving sublabel name...");

    setIsEditingSubLabel(false);
    handleNewSubLabelNameChange(
      editedSubLabelName,
      labelId,
      sublabel_id,
      projectId
    );
  };

  return (
    <LabelsContainer>
      <Div className={!errorMessage ? "error_message" : ""}>
        <Input
          type="text"
          placeholder="Enter Label Name"
          value={labelName}
          onChange={(e) => {
            setLabelName(e.target.value);
            setErrorMessage(""); // Clear the error message when input changes
          }}
          onKeyPress={handleKeyPress}
          style={errorMessage ? { borderColor: "red" } : null}
        />
        <PlusBadgeIcon as={PlusBadge} onClick={handleAddLabel} />
      </Div>
      {errorMessage && (
        <Div style={{ color: "red" }} className="error_message">
          {errorMessage}
        </Div>
      )}
      <Text>
        Total labels:{" "}
        {labelsArray?.length !== undefined ? labelsArray?.length : "0"}
      </Text>
      <LabelListContainer>
        {labelsArray?.map((item) => (
          <LabelSetWrapper
            key={item.label_id}
            style={{ background: item.clr, borderColor: item.clr }}
            onClick={() => handleEditLabel(item.label_id)} // Pass the label ID as argument
          >
            <CloseIcon
              as={Close}
              onClick={() => handleRemoveLabel(activeProject_id, item.label_id)}
            />
            <LabelDiv>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ShortcutIcon as={Shortcut} />
                {isEditingLabel && editedLabelId === item.label_id ? ( // Check if the label ID matches the edited label ID
                  <LabelEditInput
                    type="text"
                    value={editedLabelName}
                    onChange={(e) => setEditedLabelName(e.target.value)}
                    onBlur={() =>
                      handleSaveLabelName(item.label_id, activeProject_id)
                    }
                  />
                ) : (
                  <LabelNameText>{item.name}</LabelNameText>
                )}
              </div>
              {isEditingLabel &&
                editedLabelId === item.label_id &&
                !(editedLabelName === item.name) && (
                  <SaveIcon
                    onClick={() =>
                      handleSaveLabelName(item.label_id, activeProject_id)
                    }
                  >
                    Save
                  </SaveIcon>
                )}
              {/* 
               // Add Sub-labels code
                <PlusIcon
                  as={Plus_Label}
                  onClick={() =>
                    handleAddSubLabel(activeProject_id, item.label_id, item)
                  }
                />
              */}
            </LabelDiv>
            {item?.subLabels?.length > 0 && (
              <SubLabelWrapper>
                {item.subLabels.map((subLabel, i) => (
                  <SubLabelDiv
                    key={subLabel.sublabel_id}
                    onClick={() =>
                      handleEditSubLabel(item.label_id, subLabel.sublabel_id)
                    }
                  >
                    <ShortcutIcon as={Shortcut} />
                    {isEditingSubLabel &&
                    editedSubLabelId === subLabel.sublabel_id ? ( // Check if the label ID matches the edited label ID
                      <>
                        <SubLabelEditInput
                          type="text"
                          value={editedSubLabelName}
                          onChange={(e) =>
                            setEditedSubLabelName(e.target.value)
                          }
                          onBlur={() =>
                            handleSaveSubLabelName(
                              item.label_id,
                              activeProject_id,
                              subLabel.sublabel_id
                            )
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              handleSaveSubLabelName(
                                item.label_id,
                                activeProject_id,
                                subLabel.sublabel_id
                              );
                            }
                          }}
                        />
                        {!(editedSubLabelName === subLabel.name) && (
                          <SaveIcon
                            onClick={() =>
                              handleSaveLabelName(
                                item.label_id,
                                activeProject_id
                              )
                            }
                          >
                            Save
                          </SaveIcon>
                        )}
                      </>
                    ) : (
                      <LabelNameText>{subLabel.name}</LabelNameText>
                    )}
                  </SubLabelDiv>
                ))}
              </SubLabelWrapper>
            )}
          </LabelSetWrapper>
        ))}
      </LabelListContainer>
    </LabelsContainer>
  );
};

const mapStateToProps = (state) => {
  const activeProject_id = state.projects.activeProject_id;

  const activeProject = state.projects.projects.find(
    (project) => project?.projectId === activeProject_id
  );

  return {
    activeProject_id: activeProject_id,
    labelsArray: activeProject ? activeProject.labels : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _setLabelAPI: (newLabel, project_id) =>
      dispatch(_setLabels(newLabel, project_id)),
    _updateLabelNameAPI: (newNameValue, labelId, projectId) =>
      dispatch(_updateLabelName(newNameValue, labelId, projectId)),
    _initSubLabelsAPI: (projectId, labelId, newSubLabelName) =>
      dispatch(_initSubLabels(projectId, labelId, newSubLabelName)),
    _setSubLabelsAPI: (subLabelValue, labelId, subLabelId, projectId) =>
      dispatch(_setSubLabels(subLabelValue, labelId, subLabelId, projectId)),
    _removeLabelAPI: (projectId, labelId) =>
      dispatch(_removeLabel(projectId, labelId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
