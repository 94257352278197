import styled from "styled-components";

export const TextWithAlpha = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  flex: 1;
  caret-color: transparent;
  //border: 1px dashed #eaecf0;
  //border-radius: 8px;
  width: calc(100vw - 23.75vw);
  // width: 120vh;
  height: 60vh;
  // background: #71e384;

  &.short_sidebar {
    width: calc(100vw - 5.8vw);
  }
`;

export const TableData = styled.td`
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-style: normal;
  position: relative;
  height: auto;
  overflow: visible;
  width: 100%;
  :first-child {
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    color: #be4e40;
    width: 3.33vw;
    height: 98%;
  }
  :nth-child(2) {
    padding: 8px;
    width: 100%;
    flex-wrap: wrap;
    margin-right: 1px;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  &.selected {
    border-bottom: 1px solid #e26d5c;
  }
  &:hover {
    border-bottom: 1px solid #e26d5c;
  }
`;

export const ParagraphText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;

  color: #667085;
  //padding: 0 5px;
  margin: 0;
  align-items: center;
  justify-content: center;
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: 2;
  position: relative;

  &.selected_para {
    margin: 4px 0;
  }
  /* Add your file path to the CSS file in your project. */
  .highlightedText {
    margin: 4px 0 0;
    padding: 0 5px;
  }

  .highlightedText.hovered {
    border: 4px solid black;
  }

  .highlightedText.highlighted {
    // background-color: #fe8c8e;
  }

  /* Modify this color as per your needs. */
  .highlightedText.highlighted.hovered {
    background-color: #fe8c8e;
    border: 4px solid black;
  }
`;
