// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
  listAll,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage"; // Import storage functions

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const isProduction = process.env.NODE_ENV === "production";

const firebaseConfig = {
  apiKey: "AIzaSyBoaXMMIMDx9nWHJ9s263NA0C_q4wfDFZA",
  authDomain: isProduction
    ? "app.annoberry.com"
    : "annoberrytool.firebaseapp.com",
  projectId: "annoberrytool",
  storageBucket: "annoberrytool.appspot.com",
  messagingSenderId: "245677617566",
  appId: "1:245677617566:web:b442d7cf2e14c12ac4e68f",
  measurementId: "G-NGFBMTNQ9B",
};

console.log("environment ", isProduction);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBoaXMMIMDx9nWHJ9s263NA0C_q4wfDFZA",
//   authDomain: "annoberrytool.firebaseapp.com",
//   projectId: "annoberrytool",
//   storageBucket: "annoberrytool.appspot.com",
//   messagingSenderId: "245677617566",
//   appId: "1:245677617566:web:b442d7cf2e14c12ac4e68f",
//   measurementId: "G-NGFBMTNQ9B",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app); // Initialize storage

const uploadFile = (createdBy, project_id, file, uid, onProgress) => {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(storage);

    const fileRef = ref(
      storageRef,
      `${createdBy}/${project_id}/${uid}-${file.name}`
    );
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (error) => {
        console.error("Error uploading file:", error);
        reject(error);
      },
      async () => {
        console.log("File uploaded successfully");

        // Get the download URL, storage name, and format
        const downloadURL = await getDownloadURL(fileRef);
        const storageName = `${createdBy}/${project_id}/${uid}-${file.name}`;
        const format = file.type.split("/")[1];

        resolve({ downloadURL, storageName, format });
      }
    );
  });
};

const deleteFileFromStorage = (createdBy, project_id, fileUid, fileName) => {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(getStorage());
    const fileRef = ref(
      storageRef,
      `${createdBy}/${project_id}/${fileUid}-${fileName}`
    );

    try {
      await deleteObject(fileRef);
      console.log("File deleted successfully");
      resolve(true); // Resolve the promise with true
    } catch (error) {
      console.error("Error deleting the file:", error);
      resolve(false); // Resolve the promise with false
    }
  });
};

const copy = async (sourceRef, destRef) => {
  try {
    const downloadURL = await getDownloadURL(sourceRef);
    console.log("Download URL:", downloadURL);

    const response = await fetch(downloadURL);
    console.log("Response:", response);

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const blob = await response.blob();
    await uploadBytes(destRef, blob);
  } catch (error) {
    console.error("Error copying file:", error);
  }
};

// Transfer files from a temporary project folder to a new project folder
const transferFilesToNewProject = async (tempProjectName, newProjectName) => {
  const storageRef = ref(storage);
  const tempProjectRef = ref(storageRef, tempProjectName);
  const newProjectRef = ref(storageRef, newProjectName);

  try {
    const tempProjectFiles = await listAll(tempProjectRef);
    const transferPromises = tempProjectFiles.items.map((item) => {
      const newFileRef = ref(newProjectRef, item.name);
      return copy(item, newFileRef).then(() => deleteObject(item));
    });

    await Promise.all(transferPromises);
    console.log("Files transferred successfully");
  } catch (error) {
    console.error("Error transferring files:", error);
  }
};

export {
  auth,
  db,
  googleProvider,
  analytics,
  storage,
  uploadFile,
  deleteFileFromStorage,
  transferFilesToNewProject,
};
