import React, { useState } from "react";
import {
  Button,
  Div,
  FeaturedIcon,
  HelpIcon,
  Input,
  InputDropDown,
  InputWrapper,
  Label,
  ModalActions,
  ModalContainer,
  ModalContent,
  Text,
  TextWrapper,
  UserIcon,
} from "./ExportModalStyles";
import { ReactComponent as Featured_green } from "../../assets/FeaturedIcon-green.svg";
import { ReactComponent as Help } from "../../assets/HelpIcon.svg";
import { ReactComponent as User } from "../../assets/UserIcon-JSON.svg";
import { useAnnotationsDownloader } from "./downloadJson";
import { CloseIcon, IconActions } from "./MarkAsCompleteModalStyles";
import { ReactComponent as Close } from "../../assets/CloseIcon.svg";

const ExportModal = ({
  closeModal,
  fileUrl,
  fileId,
  currentPage,
  project_id,
}) => {
  const [isExportingFormat, setIsExportingFormat] = useState("JSON");
  const [exportOption, setExportOption] = useState("Current File");

  const handleExportOption = (event) => {
    setExportOption(event.target.value);
  };

  const handleExportFormat = (event) => {
    setIsExportingFormat(event.target.value);
  };
  // Calling the hook at the top level of the functional component
  const downloadAnnotations = useAnnotationsDownloader();

  const onConfirmClick = () => {
    //console.log("download annotations export: ", project_id, fileId);

    downloadAnnotations(project_id, fileId);
  };

  return (
    <ModalContainer>
      <ModalContent>
        <IconActions>
          <FeaturedIcon as={Featured_green} />
          <CloseIcon as={Close} onClick={closeModal} />
        </IconActions>
        <TextWrapper>
          <Text className="text">Export Annotations</Text>
          <Text className="supportive_text">
            Seamlessly Save your annotations
          </Text>
        </TextWrapper>
        <InputWrapper>
          <Label>Export</Label>
          <Div>
            <UserIcon as={User} />
            <InputDropDown
              value={exportOption}
              className="type"
              onChange={(e) => handleExportOption(e)}
            >
              <option value="JSON">Current File</option>
            </InputDropDown>
          </Div>
        </InputWrapper>
        <InputWrapper>
          <Label>Format</Label>
          <Div>
            <UserIcon as={User} />
            <InputDropDown
              value={isExportingFormat}
              className="type"
              onChange={(e) => handleExportFormat(e)}
            >
              <option value="JSON">JSON</option>
            </InputDropDown>
          </Div>
        </InputWrapper>
      </ModalContent>
      <ModalActions>
        <Button className="button_cancel" onClick={closeModal}>
          Cancel
        </Button>
        <Button className="button_confirm" onClick={onConfirmClick}>
          Confirm
        </Button>
      </ModalActions>
    </ModalContainer>
  );
};

export default ExportModal;
