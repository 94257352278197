import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import AuthErrorHandler from "./../../app/AuthErrorHandler";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Header from "../../Header/Header";
import {
  GoogleButtonText,
  GoogleIcon,
  GoogleSignupButton,
  Input,
  Label,
  LoginText,
  HintText,
  SignInControl,
  SignupButton,
  SignupContainer,
  SignupForm,
  SupportiveText,
  Text,
  Title,
  AlertIcon,
  InputWithIcon,
} from "./SignupStyles";
import { ReactComponent as GoogleSignIn } from "../../assets/GoogleSignInIcon.svg";
import Panel from "./Panel";
import {
  onAuthChanged,
  register,
  signInWithGoogle,
} from "../../app/Actions/authActions";
import { ReactComponent as Alert } from "../../assets/Alert-CircleIcon.svg";
import validator from "validator"; // Import validator

const Signup = ({
  SignUpAPI,
  authMessage,
  currentUser,
  onAuthChanged,
  SignInWithGoogleAPI,
  emailVerificationSent,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const validateName = (nameText) => {
    if (nameText === "") {
      setNameError("Name cannot be empty");
    } else {
      setNameError("");
    }
  };

  /* const validateEmail = (emailText) => {
    if (!validator.isEmail(emailText)) {
      // Use validator's isEmail method
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  }; */
  const validateEmail = (emailText) => {
    if (emailText === "") {
      setEmailError("Email cannot be empty");
    } else if (!/\S+@\S+\.\S+/.test(emailText)) {
      if (!/@/.test(emailText)) {
        setEmailError('Email is missing "@" symbol');
      } else if (!/\.\S+/.test(emailText)) {
        setEmailError('Email is missing ".com"');
      }
    } else if (authMessage === "auth/invalid-email") {
      setEmailError("Email is not valid");
    } else if (authMessage === "auth/email-already-in-use") {
      setEmailError("Email already in use");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (passwordText) => {
    if (passwordText === "") {
      setPasswordError("Password cannot be empty");
    } else if (passwordText.length < 8) {
      setPasswordError("Password must be at least 8 characters");
    } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(passwordText)) {
      setPasswordError(
        "Password must contain at least one letter and one number"
      );
    } //else if (authMessage?.props?.error?.code === "auth/weak-password") {
    else if (authMessage === "auth/weak-password") {
      setPasswordError("Password is too weak");
    } else {
      setPasswordError("");
    }
  };

  const checkAndNavigate = (currentPath, targetPath) => {
    if (currentPath !== targetPath) {
      navigate(targetPath);
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentUser && currentUser.emailVerified) {
      console.log("email verified signup redirecting to home", currentUser);
      checkAndNavigate(currentPath, "/home");
      // Clear input fields
      setName("");
      setEmail("");
      setPassword("");
    } else if (
      currentUser &&
      !currentUser.emailVerified &&
      emailVerificationSent
    ) {
      console.log(
        "email not verified, email verification sent signup redirecting to verify-email",
        currentUser
      );

      checkAndNavigate(currentPath, "/verify-email");
    } else if (currentUser && !currentUser.emailVerified) {
      console.log("Email not verified. Stay on signup");
      console.log(
        "email not verified, email verification not sent signup redirecting to nowhere stay on signup",
        currentUser
      );
    }
  }, [currentUser, emailVerificationSent]); // Added 'emailVerificationSent' to the dependency array

  useEffect(() => {
    if (!currentUser && authMessage !== null) {
      console.log("authMessage in sign up: ", authMessage);
      //navigate("/signup");
      validateName(name);
      validateEmail(email);
      validatePassword(password);
    }
  }, [authMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    // It should clear previous validation error before next click on create account button
    setNameError("");
    setEmailError("");
    setPasswordError("");
    console.log("sign up- handle submit clicked", emailError, passwordError);

    // Validate email, name, and password
    validateName(name);
    validateEmail(email);
    validatePassword(password);
    await SignUpAPI(name, email, password);
  };

  // For Google Signup, bypass the frontend validation checks
  const handleGoogleSignup = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const { displayName, email } = result.user;

      console.log("call to google sign in popup from signup");
      // You can handle the Google signup logic here, e.g., using an API call
      await SignInWithGoogleAPI(displayName, email);

      // Clear any frontend errors
      setNameError("");
      setEmailError("");
      setPasswordError("");
    } catch (error) {
      //console.log("Google signup error:", error);
    }
  };

  return (
    <>
      <Header screen="signup" />
      <div style={{ display: "flex" }}>
        <Panel />
        <SignupContainer>
          <Title>
            <Text>Sign up</Text>
            <SupportiveText>
              Experience the planet's fastest data annotation
            </SupportiveText>
          </Title>
          <SignupForm>
            <Label>Name*</Label>
            <InputWithIcon>
              <Input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                //required
                style={{ borderColor: nameError ? "#FDA29B" : "" }}
              />
              {nameError && <AlertIcon as={Alert} />}
            </InputWithIcon>
            {nameError.length !== 0 && <HintText>{nameError}</HintText>}
            <Label>Email*</Label>
            <InputWithIcon>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                //required
                style={{ borderColor: emailError ? "#FDA29B" : "" }}
              />
              {emailError && <AlertIcon as={Alert} />}
            </InputWithIcon>
            {emailError.length !== 0 && <HintText>{emailError}</HintText>}

            <Label>Password*</Label>
            <InputWithIcon>
              <Input
                type="password"
                placeholder="Create a password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                //required
                minLength={8}
                style={{ borderColor: passwordError ? "#FDA29B" : "" }}
              />
              {passwordError && <AlertIcon as={Alert} />}
            </InputWithIcon>
            {passwordError.length !== 0 && <HintText>{passwordError}</HintText>}

            <SignupButton type="submit" onClick={handleSubmit}>
              Create account
            </SignupButton>
            <GoogleSignupButton>
              <GoogleIcon as={GoogleSignIn} />
              <GoogleButtonText onClick={handleGoogleSignup}>
                Sign up with Google
              </GoogleButtonText>
            </GoogleSignupButton>
          </SignupForm>

          <SignInControl>
            Already have an account?{" "}
            <Link to="/" style={{ textDecoration: "none" }}>
              <LoginText>Log in</LoginText>
            </Link>
          </SignInControl>
        </SignupContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    authMessage: state.auth.authMessage,
    emailVerificationSent: state.auth.emailVerificationSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignUpAPI: (name, email, password) =>
      dispatch(register(name, email, password)),
    SignInWithGoogleAPI: (name, email) =>
      dispatch(signInWithGoogle(name, email)),
    //onAuthChanged: () => dispatch(onAuthChanged()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
