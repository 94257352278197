import styled from "styled-components";

export const LabelsPopupContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 320px;
  max-height: 260px;
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  z-index: 12;
  //margin-top: 5.6rem;
  margin: 18rem auto auto;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 8px;
  }
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 4px 0px;
  z-index: 12;
  position: relative;
  width: 100%;
  margin-left: 25px;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 14px;
  gap: 8px;
  background: #ffffff;
  transition: all 0.2s;
  width: 100%;
  cursor: pointer;
  :hover {
    background-color: skyblue;
  }

  &.sub_labels {
    //padding: 0 14px;
    margin: 0;
  }
`;

export const ChooseKey = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13.1833px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f4343;
  padding: 4px 5px;
  border-radius: 3.29583px;
`;

export const Text = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #101828;
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  //margin-left: 150px;
  margin: 0px 0px 0px auto;
  outline: none;
`;

export const SubLabelList = styled.div`
  list-style-type: none;
  width: 100%;
  margin-left: 44px;

  :hover {
    background-color: skyblue;
  }
`;

export const DropdownIcon = styled.svg`
  height: 24px;
  width: 24px;
  position: relative;

  &.rotate {
    transform: rotate(270deg);
  }
`;
