import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  caret-color: transparent;
`;

export const MailFeaturedIcon = styled.svg`
  width: 56px;
  height: 56px;
  position: relative;
  margin: 0 0 24px;
`;

export const TextAndSupportingText = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  align-items: center;
`;

export const Text = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #101828;
  margin: 0 0 12px;
`;

export const SupportingText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #667085;
  margin: 0 0 32px;
`;

export const OpenEmailButton = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 10px 18px;
  gap: 8px;
  background: #ef7866;
  border: 1px solid #ef7866;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 360px;
  margin: 0 0 32px;
  cursor: pointer;
  &:hover {
    background-color: #cc5b4a;
  }
`;

export const ResendControl = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin: 0 0 32px;
`;

export const ResendText = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #d66353;
  margin-left: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const LeftArrowIcon = styled.svg`
  width: 11.67px;
  height: 11.67px;
  position: relative;
  margin-right: 12.17px;
`;

export const RedirectText = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
