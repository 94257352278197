import styled from "styled-components";

export const FooterContainer = styled.footer`
  //width: 480px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  height: 96px;
  width: 25%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
`;

export const CopyRightsText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #d0d5dd;
`;

export const EmailText = styled(CopyRightsText)``;
