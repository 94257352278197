import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  caret-color: transparent;
`;

export const Title = styled.div`
  //font-size: 2rem;
  //margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  //width: 300px;
  margin-bottom: 12px;
  text-align: center; // Add this line to center the text
`;

export const Text = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #14213d;
`;

export const SupportingText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  text-align: center;
  margin: 0;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  //width: 300px;
`;

export const InputWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 360px;
`;

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #14213d;
  margin-bottom: 6px;
  margin-top: 20px;
`;

export const Input = styled.input`
  //margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 332px;
  outline: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  caret-color: #667085;

  &:focus {
    outline: none;
  }
`;

export const AlertIcon = styled.svg`
  width: 13.33px;
  height: 13.33px;
  position: absolute;
  margin: 15.33px 0 15.33px 336.33px;
`;

export const HintText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f04438;
  margin: 6px 0 0;
  width: 360px;
`;

export const RememberText = styled.p`
  color: #14213d;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const RememberWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
`;

export const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  background-color: #ffffff;
  appearance: none;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;

  &:checked {
    background-color: #fffbfa;
    border-color: #be4e40;
  }

  &:checked::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 6px;
    border: solid #be4e40;
    border-width: 0 2px 2px 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%) rotate(45deg);
  }
`;

export const ForgotPassText = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #d66353;
  margin: 0;
  cursor: pointer;
  text-decoration: none; // Add this line to remove the underline
  &:hover {
    text-decoration: underline;
  }
`;

// export const SignUpText = styled(ForgetPassText);

export const SignUpControl = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin: 32px 0 0;
`;

export const SignInButton = styled.button`
  align-items: center;
  background-color: #e26d5c;
  border: 1px solid #e26d5c;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    background-color: #cc5b4a;
  }
`;

export const GoogleSignInButton = styled.button`
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #344053;
  background-color: #ffffff;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  &:hover {
    background-color: #f0f1f3;
  }
`;

export const GoogleIcon = styled.svg`
  height: 24px;
  min-width: 24px;
  position: relative;
`;

export const GoogleButtonText = styled.span`
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;
