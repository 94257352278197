import styled from "styled-components";

export const PanelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #14213d;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  width: 23vw;
  height: 60vh;
  justify-content: center;
  margin-right: 16px;

  @media (max-width: 1227px) {
    // hide preview panel at this screen size
    display: none;
  }
`;

export const StepBase = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  position: relative;
  margin-left: 3rem;
  margin-right: 2rem;
`;

export const StepBaseIcon = styled.svg`
  height: 2rem;
  width: 2rem;
  position: relative;

  &.checked_icon {
    background-color: white;
    border-radius: 50%;
  }
`;

export const ConnectorWrap = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0px 0px 4px;
  position: relative;
  width: fit-content;
  margin-top: -1px;
  //flex-grow: 1;
`;

export const ConnectorIcon = styled.div`
  width: 2px;
  height: 46px;
  background-color: #667085;
  flex-grow: 1;

  &.selected {
    background-color: white;
  }
`;

export const TextAndSupportingText = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  margin-left: 1rem;
  caret-color: transparent;
  //margin: 1.125rem 0rem 1.125rem;
`;

export const PanelText = styled.p`
  align-self: stretch;
  color: #344053;
  font-family: "Inter", Helvetica;
  font-weight: 500;
  position: relative;
  //white-space: nowrap;
  font-size: 1rem;
  color: #ffffff;
  margin: 0 0 2px;
`;

export const SupportingText = styled.p`
  align-self: stretch;
  color: #667084;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  position: relative;
  //white-space: nowrap;
  font-size: 1rem;
  color: #d0d5dd;
  margin: 0 0 3.46vh;

  &.select_label {
    margin: 0;
  }
`;
