// Logo.js
import React, { useState, useEffect } from "react";
import logoDesktop from "../assets/logos/logo-desktop.svg";
import logoMobile from "../assets/logos/logo-mobile.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Link to="/home">
      <img
        src={isMobile ? logoMobile : logoDesktop}
        alt={isMobile ? "Mobile logo" : "Desktop logo"}
      />
    </Link>
  );
};

export default Logo;
