import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { onAuthChanged } from "../app/Actions/authActions";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ currentUser }) => {
  const localUser = localStorage.getItem("user");

  // If there's no authenticated user, redirect to login
  if (!currentUser && !localUser) {
    return <Navigate to="/" />;
  }

  // Otherwise, render the protected component
  return <Outlet />;
};

// const ProtectedRoute = ({ currentUser, onAuthChanged }) => {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const checkAuth = async () => {
//       await onAuthChanged();
//       setLoading(false);
//     };
//     checkAuth();
//   }, [onAuthChanged]);

//   const localUser = localStorage.getItem("user");

//   if (loading) {
//     // Render a loading state while authentication check is in progress
//     return <div>Loading...</div>;
//   }

//   return localUser ? <Outlet /> : <Navigate to="/" />;
// };

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthChanged: () => dispatch(onAuthChanged()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
