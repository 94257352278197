import styled from "styled-components";
import { SaveButton } from "../UserProfile/ProfileSettingsStyle";

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  caret-color: transparent;
  overflow-y: auto;
  padding-top: 2%;
  padding-bottom: 2%;
  align-self: center;
  margin-top: 3.5%; // Adjust the margin-top value to position the content section correctly
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding: 0px;
  width: 1216px;
  height: auto;
  max-height: calc(
    100vh - 200px
  ); // Limit the height and adjust the value based on other elements' height
  overflow-y: auto; // Enable scrolling when content exceeds the specified height
  margin-bottom: 0%;
`;

export const Heading = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -0.02em;
  color: #14213d;
  //align-self: stretch;
  width: 264px;
  height: auto;
`;

export const ProgressSteps = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  width: 920px;
  height: auto;
  margin-left: 16px;
`;

export const StepBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 0 8px 0;
  width: 296px;
  height: 72px;
`;

export const ProgressLine = styled.div`
  width: 296px;
  height: 4px;
  //background: #d66353;
  background: #eaecf0;
`;

export const TextAndSupportingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px 0px;
  width: 296px;
  height: 58px;
`;

export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #344054;
  margin: 0 0 2px;
`;

export const SupportingText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  margin: 0;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 1218px;
  height: auto;
  margin: 0% 0 0; // Reduced the margin-top to 1% to decrease the space between input and progress steps
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 1190px; // total = 1218-2*14 (padding)
  align-self: center;
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  caret-color: #667085;

  &:focus {
    outline: none;
  }
`;

export const HelpIcon = styled.svg`
  width: 16px;
  height: 16px;
  position: absolute;
  margin: 14px 14px 14px 1188px;
  cursor: pointer;
`;

export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  width: 1170px;
  height: 44.7vh;
  background: #ffffff;
  border: 1px dashed #eaecf0;
  border-radius: 8px;
  margin: 2% 0; // Reduced the margin-top and margin-bottom to create more space for the content

  &.page_label {
    width: 23.75vw;
    height: calc(100vh - 318px);
    border: none;
    margin: 0;
    padding: 0;
    background-color: #f9fafb;
    flex-direction: column;
  }
`;

export const UploadFeaturedIcon = styled.svg`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 0 12px;
  cursor: pointer;
`;

export const UploadTextAndSupportingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 216px;
  text-align: center;

  div {
    input {
      position: absolute;
      margin: 0;
      padding: 0;
      font-size: 10px;
      margin-left: 2px;
    }
  }
`;

export const BrowseButton = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #d66353;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const SpanText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin: 0 0 0 4px;
`;

export const FileSizeInfoText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  margin: 0;
`;

export const ButtonControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  width: 1218px;
  margin-bottom: 2%; // Reduced the margin-bottom to create more space for the content
  justify-content: flex-end;

  @media (max-width: 1521px) {
    &.preview_buttons {
      width: 80%;
    }
  }
`;

export const CancelButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 92px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 0 11px 0 0;
  &:hover {
    background-color: #f0f1f3;
  }
`;

export const NextButton = styled(SaveButton)`
  background: #ef7866;
  border: 1px solid #ef7866;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background-color: #cc5b4a;
  }
`;

export const RightArrowIcon = styled.svg`
  width: 11.67px;
  height: 11.67px;
  position: relative;
`;

export const FileDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 408px;
  position: relative;

  &.file_uploaded {
    //margin-top: -25%;
    margin-bottom: 15%;
  }
`;

export const FileQueueContainer = styled.div`
  padding: 0 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 44vh;
  margin-left: 50px;
  width: 583px; //38vw;
  position: relative;
  cursor: pointer;
  // max-height: calc(
  //   100vh - 400px
  // ); // Adjust the value based on the height of other elements

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eaecf0;
    border-radius: 8px;
  }

  &.page_label {
    width: 342px;
    margin: 0px;
    max-height: 40vh;
    bottom: 0px;
  }
`;

export const FileBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  //width: 551px; // 583 - 2*16
  background: #ffffff;
  border: 1px solid #d66353;
  border-radius: 8px;
  //margin: 6px 0;
  margin-bottom: 12px;
`;

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 522px;

  &.page_label {
    width: 322px;
  }
`;

export const FileUploadIcon = styled.svg`
  width: 2rem;
  height: 2rem;
  position: relative;
  margin-right: 14px;
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const FileNameAndSizeText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 0 0 10px;
`;

export const FileName = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  color: #344054;
`;

export const FileSize = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #667085;
  margin: 0;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 458px; // 100%; /* set width to 100% */

  &.page_label {
    width: 240px;
  }
`;

export const ProgressBar = styled.progress`
  width: 100%; /* set width to 100% */
  height: 8px;
  border-radius: 8px;
  margin: 0px 5px 0px 0px;
  padding: 0;

  -webkit-appearance: none;

  ::-webkit-progress-bar {
    //width: 100%;
    height: 8px;
    background: #fffbfa;
    border-radius: 4px;
  }

  ::-webkit-progress-value {
    background: #ffbaa4;
    border-radius: 4px;
  }
`;

export const ProgressPercentage = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  margin: 0;
  padding: 0;
`;

export const TrashIcon = styled.svg`
  //margin-left: 6.5px;

  position: relative;
  width: 15px;
  height: 16.67px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

export const CheckIcon = styled.svg`
  width: 1rem;
  height: 1rem;
  position: relative;
  //margin-left: 6.5px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }

  :hover {
    display: none;
  }
`;
