import React from "react";
import { Text, TabsWrapper } from "./PageLabelsStyles";

const Tabs = ({ tabs, activeTab, setIsActiveTab, setIsDoneButtonOpen }) => {
  const handleSetSelectedTab = (inx) => {
    setIsActiveTab(inx);
    setIsDoneButtonOpen(false);
  };

  return (
    <TabsWrapper>
      {tabs?.map((tab, index) => (
        <Text
          key={index}
          className={activeTab === index ? "selected_tab" : ""}
          onClick={() => handleSetSelectedTab(index)}
        >
          {tab}
        </Text>
      ))}
    </TabsWrapper>
  );
};

export default Tabs;
