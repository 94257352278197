import React from "react";
import {
  CopyRightsText,
  EmailText,
  FooterContainer,
  FooterWrapper,
} from "./FooterStyles";

const Footer = () => {
  return (
    <FooterContainer>
      <CopyRightsText>© Annoberry 2021</CopyRightsText>
      <EmailText>business@annoberry.com</EmailText>
    </FooterContainer>
  );
};

export default Footer;
