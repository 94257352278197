// initialActions.

//export const INITIAL_LOADING = 'INITIAL_LOADING';
//export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const UPDATE_FIREBASE_USER = "UPDATE_FIREBASE_USER";

export const UNVERIFIED_EMAIL = "UNVERIFIED_EMAIL";

export const EMAIL_VERIFICATION_SENT = "EMAIL_VERIFICATION_SENT";
export const EMAIL_VERIFICATION_UNSENT = "EMAIL_VERIFICATION_UNSENT";

export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
// SIGN UP
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

// LOGIN
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// SIGN IN
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";

export const EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED";

// RESET PASSWORD
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";

// UPDATE PASSWORD
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

//LOGOUT
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

// TOTAL PROJECT COUNT
export const GET_TOTAL_PROJECT = "GET_TOTAL_PROJECT";
export const SET_TOTAL_PROJECT = "SET_TOTAL_PROJECT";

// LABELS
export const GET_LABELS = "GET_LABELS";
export const SET_LABELS = "SET_LABELS";

export const INIT_SUBLABELS = "INIT_SUBLABELS";
export const SET_SUBLABELS = "SET_SUBLABELS";
export const UPDATE_LABEL_NAME = "UPDATE_LABEL_NAME";

export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR";
export const GET_PROJECT = "GET_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const DECREMENT_TOTAL_FILES = "DECREMENT_TOTAL_FILES";

export const ACTIVE_PROJECT_ID = "ACTIVE_PROJECT_ID";
export const REMOVE_LABEL = "REMOVE_LABEL";
export const UPDATE_LABEL_ID = "UPDATE_LABEL_ID";
export const GET_LABELS_LOCAL = "GET_LABELS_LOCAL";

export const SET_PROJECT_DRAFT_STATUS = "SET_PROJECT_DRAFT_STATUS";

export const GET_FILES = "GET_FILES";
export const SET_FILES = "SET_FILES";

export const DELETE_FILES = "DELETE_FILES";

export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const GET_TOTAL_PAGES = "GET_TOTAL_PAGES";

export const SET_EMAIL = "SET_EMAIL";

// Action Types
export const ADD_ANNOTATION = "ADD_ANNOTATION";
export const REMOVE_ANNOTATION = "REMOVE_ANNOTATION";
export const UPDATE_ANNOTATION = "UPDATE_ANNOTATION";
export const CLEAR_ANNOTATIONS = "CLEAR_ANNOTATIONS";
export const SET_ANNOTATIONS = "SET_ANNOTATIONS";

export const SET_SHARED_USER = "SET_SHARED_USER";
export const GET_SHARED_USER = "GET_SHARED_USER";

export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";

export const SET_CURRENT_FILE_INDEX = "SET_CURRENT_FILE_INDEX";
export const SET_CURRENT_FILE_ID = "SET_CURRENT_FILE_ID";
