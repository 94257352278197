import React, { useEffect } from "react";
import {
  Container,
  OpenEmailButton,
  ResendText,
  ResendControl,
  SupportingText,
  Text,
  TextAndSupportingText,
  LeftArrowIcon,
  MailFeaturedIcon,
  RedirectText,
} from "./VerifyEmailStyles";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrowIcon.svg";
import { Link } from "react-router-dom";
import { ReactComponent as MailFeatured } from "../../assets/MailFeaturedIcon.svg";
import { connect } from "react-redux";
import { sendEmailVerification } from "firebase/auth";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { logout, onAuthChanged } from "../../app/Actions/authActions";
import { getAuth } from "firebase/auth";

// Initialize the Firebase Auth instance
const auth = getAuth();

const VerifyEmail = ({ currentUser, SignOutAPI, onAuthChanged }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // checks user state.
    onAuthChanged();
  }, []); // Empty dependency array ensures this runs once when component mounts.

  useEffect(() => {
    if (currentUser && currentUser.emailVerified) {
      console.log(
        "email verified, verifyemail redirecting to home",
        currentUser
      );
      navigate("/home");
    }
  }, [currentUser, navigate]);

  if (currentUser) {
    const email = currentUser.email;

    const handleOpenEmailAppClick = () => {
      const mailtoLink = `mailto:${email}`;
      //window.location.href = mailtoLink;      // open mail app on window which may redirect the user away from the current page.

      window.open(mailtoLink, "_blank"); //open in new tab or window, allowing the user to view the email web client without leaving the current page
      //setIsResetPasswordOpen(true);     // remove this to prevent the immediate redirect to the SetNewPassword component.
    };

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url:
        process.env.NODE_ENV === "production"
          ? "https://www.your-production-domain.com/finishSignUp"
          : "http://localhost:3000/finishSignUp",
      // This must be true.
      handleCodeInApp: true,
    };

    // handle sent password reset email
    const handleResendClick = async () => {
      const user = auth.currentUser;

      if (user) {
        console.log("resending email to: ", user);

        await sendEmailVerification(user, actionCodeSettings)
          .then(() => {
            console.log("Email verification sent!");
          })
          .catch((err) => {
            console.log("Error while email verifying...\n", err);
          });
      }
    };

    const handleLogout = () => {
      SignOutAPI();
      Cookies.remove("email");
      Cookies.remove("password");
      navigate("/signup");
    };

    return (
      <Container>
        <MailFeaturedIcon as={MailFeatured} />
        <TextAndSupportingText>
          <Text>Check your email</Text>
          <SupportingText>
            We sent an email verification link to {email}
          </SupportingText>
        </TextAndSupportingText>
        <OpenEmailButton onClick={handleOpenEmailAppClick}>
          Open email app
        </OpenEmailButton>
        <ResendControl>
          Didn’t receive the email?
          <ResendText onClick={handleResendClick}>Click to resend</ResendText>
        </ResendControl>
        <Link to="/" style={{ textDecoration: "none" }}>
          <RedirectText onClick={handleLogout}>
            <LeftArrowIcon as={LeftArrow} />
            Back to Sign Up
          </RedirectText>
        </Link>
      </Container>
    );
  } else {
    navigate("/signup");
  }
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    authMessage: state.auth.authMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  SignOutAPI: () => dispatch(logout()),
  onAuthChanged: () => dispatch(onAuthChanged()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
