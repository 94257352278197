import { useState, useEffect } from "react";
import { ref, getDownloadURL } from "firebase/storage"; // Assuming you're using Firebase
import { storage } from "../../app/firebase";

const useFileHandler = (fileUrl) => {
  const [fileContent, setFileContent] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    //console.log("fileurl: ", fileUrl);

    if (!fileUrl) {
      // If fileUrl is not provided or is empty
      setError("No file URL provided.");
      setLoading(false);
      return;
    }

    fetch(fileUrl)
      .then((response) => response.text())
      .then((text) => {
        const sanitizedContent = sanitizeFileContent(text);
        setFileContent(sanitizedContent);
        setData(getSegments(sanitizedContent));
        setLoading(false);
      })
      .catch((fetchError) => {
        setError(fetchError.message);
        setLoading(false);
      });
  }, [fileUrl]);

  const sanitizeFileContent = (content) => {
    // Processing the content of the file
    const sanitizedContent = content.replace(/\u2028/g, "");
    return sanitizedContent
      .split("\n")
      .filter((row) => row.trim() !== "")
      .map((row) => row.replace(/[^.,?!a-zA-Z0-9\s]/g, ""))
      .map((row) => row.replace(/\.\s+/g, "."));
  };

  const getSegments = (sentences) => {
    const segments = [];
    let currentSegmentWords = [];

    for (const sentence of sentences) {
      const words = sentence.split(" ");
      if (currentSegmentWords.length + words.length > 150) {
        segments.push(currentSegmentWords.join(" "));
        currentSegmentWords = words;
      } else {
        currentSegmentWords = [...currentSegmentWords, ...words];
      }
    }

    if (currentSegmentWords.length > 0) {
      segments.push(currentSegmentWords.join(" "));
    }

    return segments;
  };

  return { fileContent, data, loading, error };
};

export default useFileHandler;
