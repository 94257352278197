import React from "react";
import {
  HeaderContainer,
  FileNameText,
  RightContainer,
  ActionControls,
  DeleteIcon,
  UndoIcon,
  RedoIcon,
} from "./PageLabelsStyles";
import { ReactComponent as Delete } from "../../assets/DeleteIcon.svg";
import { ReactComponent as Redo } from "../../assets/RedoIcon.svg";
import { ReactComponent as Undo } from "../../assets/UndoIcon.svg";
import Pagination from "./Pagination";

const HeaderLabelling = ({
  selectFileToOpen,
  currentPage,
  totalPages,
  maxPage,
  totalFiles,
  currentFileIndex,
  goToPreviousPage,
  goToNextPage,
  setCurrentPage,
  isSidebarOpen,
  handleMarkAsCompleteClick,
  handleExportClick,
}) => {
  const ExtractFileName = ({ storageName }) => {
    const lastIndex = storageName.lastIndexOf("/");
    let fileName = storageName.substring(lastIndex + 1);
    const fileNameParts = fileName.split("-");
    fileName = fileNameParts[fileNameParts.length - 1];
    return fileName;
  };

  return (
    <HeaderContainer className={!isSidebarOpen ? "short_sidebar" : null}>
      <FileNameText className="header_file_name">
        {selectFileToOpen && (
          <ExtractFileName storageName={selectFileToOpen.name} />
        )}
      </FileNameText>
      <RightContainer>
        {/* <ActionControls>
          <DeleteIcon as={Delete} />
          <UndoIcon as={Undo} />
          <RedoIcon as={Redo} />
        </ActionControls>
        */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          maxPage={maxPage}
          totalFiles={totalFiles}
          currentFileIndex={currentFileIndex}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          setCurrentPage={setCurrentPage}
        />
      </RightContainer>
    </HeaderContainer>
  );
};

export default HeaderLabelling;
