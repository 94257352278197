import { useEffect, useRef, useCallback } from "react";
import { useSidebar } from "./../components/PageLabels/ContextFile"; // replace with actual path

const useKeyboardHandlers = (
  lastKeyRef,
  isVisible,
  setIsVisible,
  startId,
  endId,
  selectedRow,
  newLetters,
  setStartId,
  setEndId,
  setId,
  refFlag,
  setSelectedRow,
  setHighlights,
  selectLabel,
  setSelectLabel,
  completedSteps,
  setCompletedSteps,
  handleLabelSelection,
  labels,
  isMouseDragging,
  setIsMouseDragging,
  setShowStepNumber
) => {
  const { isSidebarActive } = useSidebar(); // Use the hook to get the function

  // New function to handle mouse down on alpha text
  const handleAlphaTextMouseDown = (index) => {
    if (!isMouseDragging) {
      setStartId({ id: index, row: selectedRow });
      setIsMouseDragging(true);
    }
  };

  // New function to handle mouse enter on alpha text
  const handleAlphaTextMouseEnter = (index) => {
    if (isMouseDragging) {
      setEndId({ id: index, row: selectedRow });
    }
  };

  // New function to handle mouse up on alpha text
  const handleAlphaTextMouseUp = (index) => {
    if (isMouseDragging) {
      setEndId({ id: index, row: selectedRow });
      setIsMouseDragging(false);
    }
  };

  //console.log("completed steps", completedSteps);
  const handleKeyDown = useCallback(
    (e) => {
      // If the sidebar is active, don't execute the logic
      if (isSidebarActive) {
        return;
      }

      // If the key pressed is a number
      if (isFinite(e.key) && e.key !== " " && e.keyCode !== 32) {
        //console.log("handleLabelSelection enter?", e);
        // Convert the key to a number and subtract 1 (since arrays are 0-based)
        let selectedLabelIndex = parseInt(e.key);
        if (selectedLabelIndex !== null || selectedLabelIndex !== 0) {
          // console.log(
          //     "selectedLabelIndex !== null || selectedLabelIndex !== 0 : setIsVisible false"
          //   );
          setIsVisible(false);
        }
        selectedLabelIndex = selectedLabelIndex - 1;
        // console.log(
        //     "selectedLabelIndex, labels.length",
        //     selectedLabelIndex,
        //     labels,
        //     refFlag.current.flag
        //   );

        if (!refFlag.current.flag) {
          // Update selected row and selected label

          setSelectedRow(selectedLabelIndex);
          if (typeof setShowStepNumber === "function") {
            setShowStepNumber(1); // step 2 - row is selected but yet to start annotate is complete
          }
          lastKeyRef.current = ""; // (changed: added now)
          // console.log(
          //     "setSelectedRow(selectedLabelIndex) ",
          //     selectedRow,
          //     selectedLabelIndex
          //   ); // (changed: added now)

          // setSelectLabel(labels[selectedLabelIndex]);
        } else {
          // If selectedLabelIndex is within labels array
          if (selectedLabelIndex >= 0 && selectedLabelIndex < labels.length) {
            //setSelectLabel(labels[selectedLabelIndex]);
            // console.log(
            //     "is this being called? ",
            //     "labels: ",
            //     labels,
            //     "selectedLabelIndex",
            //     selectedLabelIndex,
            //     labels[selectedLabelIndex]
            //   );
            handleLabelSelection(labels[selectedLabelIndex]);

            setId(null);
            setStartId({ id: null, row: null });
            setEndId({ id: null, row: null });
            setSelectLabel("");
            setIsVisible(false);
            if (typeof setShowStepNumber === "function") {
              setShowStepNumber(1); // step 2 - row is selected but yet to start annotate is complete
            }
            refFlag.current.flag = false; // Reset the flag
            ////console.log("After setIsVisible: ", isVisible); // Log after
          }
        }
      }

      // Capture the key pressed
      let key = e.key;

      // If the space bar was pressed
      if (key === " " || e.keyCode === 32) {
        lastKeyRef.current = "";
        let key2 =
          lastKeyRef.current || lastKeyRef.current === ""
            ? lastKeyRef.current + e.key
            : e.key; //(changed now: line added)

        e.preventDefault(); // Prevent the default action
        e.stopPropagation(); // Stop the event from bubbling up

        const index = newLetters.indexOf(lastKeyRef.current); // use lastKey instead of key
        //console.log("last key space0 ", index, lastKeyRef.current);
        if (typeof setShowStepNumber === "function") {
          setShowStepNumber(3); // step 2 - row is selected but yet to start annotate is complete
        }
        if (index !== -1 && newLetters.indexOf(key2) > index) {
          // (changed now: && newLetters.indexOf(key2) > index)
          //console.log("last key space1 index !== -1 ", lastKeyRef.current);

          refFlag.current.flag = true; // Set the flag to true

          lastKeyRef.current = "";
          return false; //issue
        } else {
          refFlag.current.flag = true; // Set the flag to true //issue
          //console.log("last key space2 index === -1", lastKeyRef.current);

          return false;
        }
      }

      // If a letter key was pressed
      if (e.keyCode >= 65 && e.keyCode <= 90) {
        // console.log(
        //     "lastKeyRef.currentey on letter key pressed",
        //     lastKeyRef.current
        //   );

        let key =
          lastKeyRef.current || lastKeyRef.current !== ""
            ? (lastKeyRef.current + e.key).slice(-2)
            : e.key; // (changed now : added || lastKeyRef.current != "")

        //console.log("last key key", key);

        // If the letter pressed is included in newLetters
        if (newLetters.includes(key)) {
          // Find the index of the pressed letter in newLetters
          const index = newLetters.indexOf(key);

          // If we are in annotation mode and a label is selected, and the index is within a certain range
          if (
            selectLabel &&
            startId.row === selectedRow &&
            startId.id <= index &&
            index <= endId.id
          ) {
            // Set the start and end IDs to the index
            setStartId({ id: index, row: selectedRow });
            setEndId({ id: index, row: selectedRow });
            setSelectLabel(null); // Reset the label to allow selecting a new one
          }
          // If refFlag.current.flag is false
          else if (!refFlag.current.flag) {
            //console.log("last key key flag false", key);

            // Set start ID
            setStartId({ id: index, row: selectedRow });
            // console.log("stepfunc :", completedSteps); // Add this line
            if (typeof setShowStepNumber === "function") {
              setShowStepNumber(2); // step 2 - row is selected but yet to start annotate is complete
            }
            if (lastKeyRef.current === "") {
              if (Array.isArray(completedSteps)) {
                const newArr = [...completedSteps]; // Create a new array to avoid mutating the state directly
                newArr[2] = 2;
                setCompletedSteps(newArr);
              }
            }
            lastKeyRef.current = key;
            //console.log("lastKeyRef.current = key", lastKeyRef.current, key); //(changed now: line added)
          } else {
            // console.log(
            //     "last key key flag true",
            //     "lastKeyRef.current " + lastKeyRef.current,
            //     "key " + key
            //   );

            // If startId.id and endId.id are null
            if (startId.id === null && endId.id === null) {
              // Set start and end IDs
              setStartId({ id: index, row: selectedRow });
              setEndId({ id: index, row: selectedRow });
              setSelectLabel(null); // Reset the label to allow selecting a new one
              // lastKeyRef.current = key;
            }
            // lastKeyRef.current = key; //(changed now: shiftef outside above if)
            //console.log("lastKeyRef.current = key", lastKeyRef.current, key); //(changed now: line added)

            // Set end ID
            setEndId({ id: index, row: selectedRow });
            if (typeof setShowStepNumber === "function") {
              setShowStepNumber(5); // step 2 - row is selected but yet to start annotate is complete
            }
            lastKeyRef.current = key;
          }
        } else if (!refFlag.current.flag) {
          //(changed now: if added)
          lastKeyRef.current = "";
          // let key = lastKeyRef.current ? lastKeyRef.current + e.key : e.key; //(changed now: line added)
          // lastKeyRef.current = "";

          // console.log(
          //     "llastKeyRef.current = null",
          //     lastKeyRef.current,
          //     "key" + key
          //   ); //(changed now: line added)
        }
      }

      // If the end ID is valid and matches the selected row
      if (
        // endId.row === selectedRow &&
        // endId.id >= 0 &&
        // endId.id &&
        key === "Enter"
      ) {
        //console.log("check this: endId.row=", endId.row, "endId.id=", endId.id);
        e.preventDefault(); // Prevent the default action
        e.stopPropagation(); // Stop the event from bubbling up

        if (endId.id === null) {
          endId.id = startId.id;
          endId.row = selectedRow;
          refFlag.current.flag = true;
        }
        //console.log("check thi2: endId.row=", endId.row, "endId.id=", endId.id);

        lastKeyRef.current = ""; //(changed now: added line)

        setIsVisible(true); // Show the popup

        // Calculate the ID for the popup
        const popupId = Math.floor((endId.id + 1 + (startId.id + 1)) / 2);
        setId(popupId); // Set the ID for the popup

        if (Array.isArray(completedSteps)) {
          const newArr = [...completedSteps]; // Create a new array to avoid mutating the state directly
          newArr[3] = 3;
          setCompletedSteps(newArr);
        }
      }

      // If the Escape key was pressed
      if (key === "Escape") {
        //console.log("escape key pressed......");
        // Reset IDs and selected label
        setId(null);
        setStartId({ id: null, row: null });
        setEndId({ id: null, row: null });
        setSelectLabel("");
        setIsVisible(false);
        if (typeof setShowStepNumber === "function") {
          setShowStepNumber(1); // step 2 - row is selected but yet to start annotate is complete
        }
        refFlag.current.flag = false; // Reset the flag
        lastKeyRef.current = "";
      }
    },
    [
      isVisible,
      setIsVisible,
      startId,
      endId,
      selectedRow,
      newLetters,
      setStartId,
      setEndId,
      setId,
      refFlag,
      setSelectedRow,
      setSelectLabel,
      completedSteps,
      setCompletedSteps,
      labels,
      handleLabelSelection,
    ]
  ); // Add all the dependencies used in your function

  const handleKeyUp = useCallback((e) => {
    e.preventDefault();
  }, []); // Add dependencies if needed

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  // Rest of your code...

  return {
    handleAlphaTextMouseDown,
    handleAlphaTextMouseEnter,
    handleAlphaTextMouseUp,
  };
};

export default useKeyboardHandlers;
