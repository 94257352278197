import styled from "styled-components";

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border: 1px dashed #eaecf0;
  border-radius: 8px;
  height: 59vh; // Adjust this value to your desired height, e.g., 80% of the viewport height
  width: 1218px;
  //margin: 53px 0 36px;
  margin: 0 0 2%;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 1218px;
  height: auto;

  &.error_message {
    margin: 0% 0 1.5%;
    align-items: flex-start;
  }
`;

export const PlusIcon = styled.svg`
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

//export const SaveIcon = styled(PlusIcon)`
//fill: #f44336; /* Set the desired color for the SaveIcon */
//margin-right: 8px; /* Adjust the margin as needed */
//transform: rotate(45deg); /* Rotate the icon by 45 degrees */
// cursor: pointer;
//`;

export const SaveIcon = styled.button`
  border: 1px solid #667085;
  background: transparent;
  color: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  //border-radius: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 1190px; // total = 1218-2*14 (padding)
  align-self: center;
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
  caret-color: #667085;
  &:focus {
    outline: none;
  }
`;

export const PlusBadgeIcon = styled.svg`
  width: 28px;
  height: 28px;
  position: absolute;
  margin: 10px 10px 10px 1176px;
  cursor: pointer;
`;

export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0 14px;
  color: #000000;
`;

export const LabelListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 528px;
  width: 1176px;
  margin-top: 9px;
  margin-left: 14px;
  padding-right: 5px;

  cursor: pointer;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    //border-radius: 8px;
  }
`;

export const CloseIcon = styled.svg`
  width: 14px;
  height: 14px;
  margin: -14px -24px auto auto;
  opacity: 0;
`;

export const LabelSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px;
  border: 4px solid;
  border-radius: 8px;
  margin-bottom: 12px;

  &:hover {
    ${CloseIcon} {
      opacity: 1;
    }
  }
`;

export const LabelEditInput = styled.input`
  border: none;
  margin: 0 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  caret-color: #344054;
  background: transparent;
  :focus {
    outline: none;
  }
`;

export const LabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1117px;
  caret-color: transparent;
`;

export const ShortcutIcon = styled.svg`
  width: 21px;
  height: 18px;
  //position: absolute;
`;

export const LabelNameText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  position: relative;
  margin: 0 16px;
`;

export const SubLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 1117px;
  gap: 4px;
`;

export const SubLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  //width: 127px;
  background: #ffffff;
  //border: 4px solid rgba(254, 140, 142, 0.4);
  border-radius: 8px;
  margin: 4px 4px 0px;
`;

export const SubLabelEditInput = styled.input`
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  caret-color: #344054;
  margin-left: 16px;
  max-width: 95px;

  :focus {
    outline: none;
  }
`;
