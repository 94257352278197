import React, { useRef, useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  ButtonControls,
  CancelButton,
  Content,
  Div,
  Heading,
  HelpIcon,
  Input,
  NextButton,
  ProgressLine,
  ProgressSteps,
  RightArrowIcon,
  StepBase,
  SupportingText,
  Text,
  TextAndSupportingText,
  UploadContainer,
} from "./UploadStyles";
import { ReactComponent as Help } from "../../assets/HelpIcon.svg";
import Header from "../../Header/Header";
import { ReactComponent as RightArrow } from "../../assets/RightArrowIcon.svg";
import Labels from "./Labels";
import Preview from "./Preview";
import { uploadFile, deleteFileFromStorage, db } from "../../app/firebase.js";
import { connect, useDispatch, useSelector } from "react-redux";
import { SidebarProvider } from "./../PageLabels/ContextFile";

import {
  addProjectDoc,
  updateProjectName,
  updateProjectFilesData,
  addFileDoc,
  deleteFileFromFirestore,
  decrementTotalFiles,
  activeProjectId,
  setProjectDraftStatus,
  _deleteProject,
} from "../../app/Actions/projectActions.js";
import { useLocation, useNavigate } from "react-router-dom";
import useFileHandlers from "../../Util/useFileHandlers";
import FileUpload from "./FileUpload";

const adjectives = [
  "Project Pink",
  "Project Blue",
  "Project Funky",
  "Project Groovy",
  "Project Awesome",
  "Project Sparkling",
  "Project Creative",
  "Project Dynamic",
  "Project Stylish",
  "Project Sizzling",
];

const nouns = [
  "Fluid",
  "Dance",
  "Moon",
  "Sun",
  "Galaxy",
  "Rainbow",
  "Vortex",
  "Rocket",
  "Cyclone",
  "Wave",
];

const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];

const generateFunkyProjectName = () => {
  const randomAdjective = getRandomElement(adjectives);
  const randomNoun = getRandomElement(nouns);
  return `${randomAdjective} ${randomNoun}`;
};

const Upload = ({ labelsArray, activeProject_id }) => {
  //console.log("upload activeProject_id: ", activeProject_id);
  // Add this line to access the dispatch function from Redux
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.currentUser;
  const [projectName, setProjectName] = useState(generateFunkyProjectName());
  const [project_id, setProject_id] = useState();
  const [isDragOver, setIsDragOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);
  const HeadingArray = ["Upload", "Labels", "Preview"];
  const [fileName, setFileName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [projectCreated, setProjectCreated] = useState(false);
  const [isStepsCompleted, setIsStepCompleted] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const screen = location?.state?.screen;

  useEffect(() => {
    //console.log("Files state updated: ", files);
    const checkLabelsExistence = async (projectId) => {
      try {
        const projectRef = collection(db, "projects");
        const q = query(projectRef, where("projectId", "==", projectId));

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          //console.log("Project not found");
          return;
        }

        const projectDoc = querySnapshot.docs[0];

        const labelsRef = collection(projectDoc.ref, "labels");
        const labelsSnapshot = await getDocs(labelsRef);
        //console.log("label collection exist ?- ", labelsSnapshot.empty);

        if (labelsSnapshot.empty) {
          setSelectedStep(1);
        } else {
          setSelectedStep(2);
        }
      } catch (error) {
        //console.log("Error checking labels existence:", error);
      }
    };

    if (activeProject_id && screen === "add-label") {
      checkLabelsExistence(activeProject_id);
    }
  }, [files, activeProject_id, screen]);

  const ProgressStepsArray = [
    {
      id: 1,
      headingTitle: "Upload",
      title: "Upload",
      subTitle: "Drag and drop or select .txt files",
    },
    {
      id: 2,
      headingTitle: "Labels",
      title: "Create Labels",
      subTitle: "Define unique labels for precise annotation",
    },
    {
      id: 3,
      headingTitle: "Preview",
      title: "Preview",
      subTitle: "Learn to annotate using our intuitive tool",
    },
  ];

  const inputRef = useRef(null);

  const {
    handleOnDropFiles,
    handleBrowseUpload,
    handleRemoveFile,
    handleDragLeave,
    handleOnDragOver,
    handleChange,
  } = useFileHandlers(
    setFileName,
    setFiles,
    setUploadProgress,
    currentUser,
    project_id,
    setProject_id,
    activeProjectId,
    addProjectDoc,
    projectName,
    setProjectCreated,
    setProjectDraftStatus,
    uploadFile,
    setIsDragOver,
    addFileDoc,
    updateProjectFilesData,
    files,
    inputRef,
    deleteFileFromFirestore,
    decrementTotalFiles,
    dispatch,
    deleteFileFromStorage,
    projectCreated
  );

  // handle next button click
  const handleNextClick = async (e) => {
    e.preventDefault();

    // Calculate total files directly from the files state
    const totalFiles = files.length;

    // Update the project name in Firestore
    await dispatch(updateProjectName(project_id, projectName, totalFiles));

    setSelectedStep((currentStep) => currentStep + 1);
  };

  // handle back button click
  const handleBackClick = (e) => {
    e.preventDefault();
    setSelectedStep((currentStep) => currentStep - 1);
  };

  // handle cancel button click
  const handleCancelClick = async (e) => {
    e.preventDefault();
    setFiles([]);
    await dispatch(_deleteProject(activeProject_id));
  };

  //handle skip button click
  const handleSkipClick = (e) => {
    e.preventDefault();
    navigate("/page-label");
  };

  const handlePreviewNextClick = (e) => {
    e.preventDefault();
    navigate("/page-label");
  };

  const handleSaveClick = async (e, activeProject_id, labelsArray) => {
    e.preventDefault();
    //const getLabelArray = await _getLabels(project_id);
    //console.log("fetched labels: ", getLabelArray);
    //if (getLabelArray?.length !== 0) setLabelsArray(getLabelArray);
    if (
      labelsArray?.length !== 0 &&
      labelsArray !== undefined &&
      labelsArray?.length > 0
    ) {
      await dispatch(setProjectDraftStatus(false, activeProject_id));
    } else {
      await dispatch(setProjectDraftStatus(true, activeProject_id));
    }
    setSelectedStep((currentStep) => currentStep + 1);
  };

  return (
    <SidebarProvider>
      <React.Fragment>
        <Header screen="upload-files" />
        <UploadContainer>
          <Content>
            <Heading>{HeadingArray[selectedStep]}</Heading>
            <ProgressSteps>
              {ProgressStepsArray.map((step, i) => (
                <StepBase key={i}>
                  <ProgressLine
                    style={{
                      backgroundColor:
                        i <= selectedStep ? "#d66353" : "#eaecf0",
                    }}
                  ></ProgressLine>
                  <TextAndSupportingText>
                    <Text
                      style={{
                        color: i <= selectedStep ? "#A6392E" : "#344054",
                      }}
                    >
                      {step.title}
                    </Text>
                    <SupportingText
                      style={{
                        color: i <= selectedStep ? "#D66353" : "#667085",
                      }}
                    >
                      {step.subTitle}
                    </SupportingText>
                  </TextAndSupportingText>
                </StepBase>
              ))}
            </ProgressSteps>
          </Content>
          {HeadingArray[selectedStep] === "Upload" && (
            <React.Fragment>
              <Div>
                <Input
                  type="text"
                  placeholder="Enter Project Name"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
                <HelpIcon as={Help} />
              </Div>
              <FileUpload
                handleOnDragOver={handleOnDragOver}
                handleOnDropFiles={handleOnDropFiles}
                handleDragLeave={handleDragLeave}
                isDragOver={isDragOver}
                handleBrowseUpload={handleBrowseUpload}
                handleChange={handleChange}
                inputRef={inputRef}
                files={files}
                handleRemoveFile={handleRemoveFile}
                currentUser={currentUser}
                project_id={project_id}
              />
            </React.Fragment>
          )}

          {HeadingArray[selectedStep] === "Labels" && (
            <Labels project_id={project_id} />
          )}

          {HeadingArray[selectedStep] === "Preview" && (
            <Preview setIsStepCompleted={setIsStepCompleted} />
          )}
          {/** next, back and cancel button controls are here */}
          <ButtonControls
            className={
              HeadingArray[selectedStep] === "Preview"
                ? "preview_buttons"
                : null
            }
          >
            <CancelButton
              onClick={
                HeadingArray[selectedStep] === "Labels" ||
                HeadingArray[selectedStep] === "Preview"
                  ? handleBackClick
                  : handleCancelClick
              }
            >
              {HeadingArray[selectedStep] === "Labels" ||
              HeadingArray[selectedStep] === "Preview"
                ? "Back"
                : "Cancel"}
            </CancelButton>
            <NextButton
              onClick={
                HeadingArray[selectedStep] === "Upload"
                  ? handleNextClick
                  : HeadingArray[selectedStep] === "Labels"
                  ? (e) => handleSaveClick(e, activeProject_id, labelsArray)
                  : HeadingArray[selectedStep] === "Preview" && isStepsCompleted
                  ? handlePreviewNextClick
                  : handleSkipClick
              }
              disabled={
                HeadingArray[selectedStep] === "Upload" && files.length === 0
                  ? true
                  : false
              }
            >
              {HeadingArray[selectedStep] === "Upload"
                ? "Next"
                : HeadingArray[selectedStep] === "Labels"
                ? "Save"
                : HeadingArray[selectedStep] === "Preview" && isStepsCompleted
                ? "Next"
                : "Skip"}
              <RightArrowIcon as={RightArrow} />
            </NextButton>
          </ButtonControls>
        </UploadContainer>
      </React.Fragment>
    </SidebarProvider>
  );
};

const mapStateToProps = (state) => {
  const activeProject_id = state.projects.activeProject_id;

  const activeProject = state.projects.projects.find(
    (project) => project?.projectId === activeProject_id
  );

  return {
    activeProject_id: activeProject_id,
    labelsArray: activeProject ? activeProject.labels : [],
  };
};

export default connect(mapStateToProps, {
  addProjectDoc,
  updateProjectName,
  addFileDoc,
  deleteFileFromFirestore,
  decrementTotalFiles,
  activeProjectId,
  _deleteProject,
})(Upload);
