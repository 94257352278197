class Project {
  constructor(name, createdBy, createdDate, projectId = null) {
    this.name = name;
    this.createdBy = createdBy;
    this.createdDate = createdDate;
    this.projectId = projectId;
  }

  toFirestoreObject() {
    const firestoreObject = {
      name: this.name,
      createdBy: this.createdBy,
      createdDate: this.createdDate,
      projectId: this.projectId,
    };
    return firestoreObject;
  }

  setProjectId(projectId) {
    this.projectId = projectId;
  }
}

export default Project;
