// useLabelHandlers.js
import { useState, useCallback } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

import {
  addAnnotation,
  removeAnnotation,
  updateAnnotation,
} from "../app/Actions/annotationActions"; // Update the path accordingly
import { Timestamp } from "firebase/firestore";

const useLabelHandlers = (
  highlights,
  startId,
  endId,
  initialSelectLabel,
  setIsVisible,
  selectedRow,
  setId,
  setStartId,
  setEndId,
  setSelectLabel,
  setSelectedRow,
  setHighlights,
  project_id,
  file_id
) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleLabelSelection = useCallback(
    (selectedLabel) => {
      setSelectLabel(selectedLabel);
      //console.log("labelSelection 1 ");
      // console.log(
      //     "handleLabelSelection clicked",
      //     selectedLabel,
      //     highlights,
      //     startId,
      //     endId
      //   );

      const annotation = {
        projectId: project_id,
        fileId: file_id,
        labelId: selectedLabel.label_id,
        labelName: selectedLabel.name,
        fileURL: null,
        createdBy: auth.currentUser.uid,
        subLabelId: null,
        subLabelName: null,
        startRow: startId.row,
        endRow: endId.row,
        startCharIndex: null,
        endCharIndex: null,
        startTokenId: startId.id,
        endTokenId: endId.id,
        relativeStartCharIndex: null,
        relativeEndCharIndex: null,
        relativeStartTokenIndex: startId.id,
        relativeEndTokenIndex: endId.id,
        createdDate: new Date(),
        type: "NER",
        clr: selectedLabel.clr,
        annotationId: null,
      };

      setIsVisible(false);

      setHighlights((prevHighlights) => {
        const index = prevHighlights.findIndex(
          (h) =>
            h.startTokenId === startId.id &&
            h.startRow === startId.row &&
            h.endTokenId === endId.id &&
            h.endRow === endId.row
        );

        const updatedHighlights = [...prevHighlights];

        if (index !== -1) {
          updatedHighlights[index] = annotation;
        } else {
          updatedHighlights.push(annotation);
        }

        return updatedHighlights;
      });

      //console.log("labelSelection 4");
      if (project_id != null && file_id != null) {
        //console.log("labelSelection 3");
        //console.log("annotation is: ", annotation);
        dispatch(addAnnotation(annotation));
      }

      setStartId((prevState) => ({ id: null, row: null }));
      setEndId((prevState) => ({ id: null, row: null }));
    },
    [dispatch, auth, highlights, startId, endId, project_id, file_id]
  );

  const handleDeleteClick = (selectedLabel, annotationId) => {
    //console.log("annotation to delete: ", annotationId);
    setHighlights((prevHighlights) => {
      //console.log("delete clicked, prevHighlights: ", prevHighlights);

      const newHighlights = prevHighlights.filter(
        (highlight) => !(highlight.annotationId === annotationId)
      );

      //console.log("delete clicked, newHighlights: ", newHighlights);
      return newHighlights;
    });

    // Assuming the annotation to be removed is identified by startId, endId, and other properties.
    const annotationToRemove = {
      startTokenId: startId.id,
      startRow: startId.row,
      endTokenId: endId.id,
      endRow: endId.row,
      annotationId: annotationId,
      projectId: project_id,
      fileId: file_id,
      // ... add other identifying properties if needed
    };

    // Dispatch the removeAnnotation action
    if (project_id != null && file_id != null) {
      dispatch(removeAnnotation(annotationToRemove));
    }

    setId(null);
    setStartId({ id: null, row: null });
    setEndId({ id: null, row: null });
    setSelectLabel("");
    setIsVisible(false);
  };

  return {
    handleLabelSelection,
    handleDeleteClick,
    setHighlights,
    setStartId,
    setEndId,
    setSelectLabel,
  };
};

export default useLabelHandlers;
