import React from "react";
import { NavLink, Navigation, SidebarMenuContainer } from "./SidebarMenuStyles";
import { connect } from "react-redux";

const SidebarMenu = ({ handleProfileClick, currentUser }) => {
  return (
    <SidebarMenuContainer>
      {currentUser ? (
        <Navigation>
          <NavLink onClick={handleProfileClick}>Profile</NavLink>
          <NavLink>Products</NavLink>
          <NavLink>Resources</NavLink>
          <NavLink>Pricing</NavLink>
        </Navigation>
      ) : (
        <Navigation>
          <NavLink>About us</NavLink>
          <NavLink>Contact us</NavLink>
        </Navigation>
      )}
    </SidebarMenuContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps)(SidebarMenu);
