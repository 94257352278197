import React, { useState } from "react";
import Logo from "../components/Logo";
import { Link, useNavigate } from "react-router-dom";
import {
  HeaderContainer,
  LeftContainer,
  Navigation,
  NavLink,
  KebabMenu,
  SignUpButton,
  LoginButton,
  AvatarWrapper,
  Avatar,
  AvatarContainer,
  RightContainer,
  ShareButton,
  ShareIcon,
  ExportButton,
  EditExportICon,
  MarkAsCompleteButton,
  CheckIcon,
} from "./HeaderStyles";
import { ReactComponent as MenuIcon } from "../assets/MenuIcon.svg";
import AvatarIcon from "../assets/AvatarIcon.svg";
import Profile from "../components/UserProfile/Profile";
import CheckOutsideClick from "./CheckOutSideClick";
import { logout } from "../app/Actions/authActions";
import { connect } from "react-redux";
import { ReactComponent as Share } from "../assets/ShareLabelIcon.svg";
import { ReactComponent as EditExport } from "../assets/EditExportIcon.svg";
import SidebarMenu from "../components/SidebarMenu/SidebarMenu";
import { ReactComponent as Check_Green } from "../assets/CheckIcon-green.svg";
import Cookies from "js-cookie";

const Header = ({
  screen,
  handleMarkAsCompleteClick,
  handleExportClick,
  handleShareClick,
  projectName,
  currentUser,
  SignOutAPI,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // console.log("user in header: ", currentUser);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const navigate = useNavigate();

  const handleSignupClick = () => {
    // if (!currentUser.emailVerified) {
    SignOutAPI();
    Cookies.remove("email");
    Cookies.remove("password");
    console.log("signout api called from header");
    // } else {
    //   navigate("/signup");
    // }
  };

  const handleProfileClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <HeaderContainer>
      <LeftContainer>
        <Logo />
        {screen !== "page-label" ? (
          <Navigation className={isMenuOpen ? "open" : ""}>
            <NavLink href="https://annoberry.com/contact-us/" target="_blank">
              Contact Us
            </NavLink>
            <NavLink href="https://annoberry.com/blog/" target="_blank">
              Blog
            </NavLink>
            <NavLink href="https://annoberry.com/services/" target="_blank">
              Services
            </NavLink>
          </Navigation>
        ) : (
          <NavLink>{projectName}</NavLink>
        )}
      </LeftContainer>

      {screen === "signup" ? (
        <Link to="/" className="login-button">
          <LoginButton>Log in</LoginButton>
        </Link>
      ) : (screen === "home" || screen === "upload-files") &&
        currentUser !== null ? (
        <>
          <AvatarContainer
            style={{ backgroundColor: isSidebarOpen ? "#f9fafb" : "#ffffff" }}
          >
            <AvatarWrapper onClick={handleProfileClick}>
              <Avatar
                src={
                  currentUser?.profilePicture === null
                    ? AvatarIcon
                    : currentUser?.profilePicture
                }
                alt="user avatar"
                referrerPolicy="no-referrer"
              />
            </AvatarWrapper>
          </AvatarContainer>
          <CheckOutsideClick onClickOutside={handleProfileClick}>
            {isSidebarOpen && <Profile />}
          </CheckOutsideClick>
        </>
      ) : (
        !isSignup &&
        screen !== "page-label" && (
          <Link to="/signup">
            <SignUpButton onClick={handleSignupClick}>Sign up</SignUpButton>
          </Link>
        )
      )}

      {screen === "page-label" && (
        <RightContainer>
          {/* <MarkAsCompleteButton onClick={handleMarkAsCompleteClick}>
            Mark as complete <CheckIcon as={Check_Green} />
          </MarkAsCompleteButton> */}
          {/* <Link style={{ textDecoration: "none" }}>
            <ShareButton onClick={handleShareClick}>
              <ShareIcon as={Share} />
              Share
            </ShareButton>
          </Link> */}
          <ExportButton onClick={handleExportClick}>
            <EditExportICon as={EditExport} />
            Export
          </ExportButton>
        </RightContainer>
      )}

      <KebabMenu onClick={handleMenuToggle}>
        <MenuIcon />
      </KebabMenu>
      <CheckOutsideClick onClickOutside={handleMenuToggle}>
        {isMenuOpen && <SidebarMenu handleProfileClick={handleProfileClick} />}
      </CheckOutsideClick>
    </HeaderContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  SignOutAPI: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
