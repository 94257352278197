import React from "react";
import {
  Button,
  FeaturedIcon,
  ModalActions,
  ModalContainer,
  ModalContent,
  Text,
  TextWrapper,
} from "./DeleteFilesConfirmModalStyles";
import { ReactComponent as Delete_featured } from "../../assets/DeleteFilesIcon.svg";

const DeleteFilesConfirmModal = ({
  closeModal,
  handleDeleteFilesConfirmClick,
}) => {
  return (
    <ModalContainer>
      <ModalContent>
        <FeaturedIcon as={Delete_featured} />
        <TextWrapper>
          <Text className="text">Delete File?</Text>
          <Text className="supportive_text">
            Deleting this file will permanently remove all its annotations.
            Proceed with caution.
          </Text>
        </TextWrapper>
      </ModalContent>
      <ModalActions>
        <Button className="button_cancel" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          className="button_confirm"
          onClick={handleDeleteFilesConfirmClick}
        >
          Confirm
        </Button>
      </ModalActions>
    </ModalContainer>
  );
};

export default DeleteFilesConfirmModal;
