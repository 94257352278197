import * as types from "../Actions/actionTypes";

import initialState from "./initialState";

const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.SIGNUP_SUCCESS:
      return { ...state, authMessage: action.payload };
    case types.SIGNUP_ERROR:
      return { ...state, authMessage: action.payload };

    case types.LOGIN_SUCCESS:
      return { ...state, authMessage: action.payload };
    case types.LOGIN_ERROR:
      return { ...state, authMessage: action.payload };

    case types.SIGNIN_SUCCESS:
      return { ...state, authMessage: action.payload };

    case types.SIGNIN_ERROR:
      return { ...state, authMessage: action.payload };

    case types.LOGOUT_SUCCESS:
      return { ...state, currentUser: null };

    case types.LOGOUT_ERROR:
      return { ...state, authMessage: action.payload };

    case types.RESET_SUCCESS:
      return { ...state, authMessage: action.payload };

    case types.RESET_ERROR:
      return { ...state, authMessage: action.payload };

    case types.UPDATE_PASSWORD_SUCCESS:
      return { ...state, authMessage: action.payload };

    case types.UPDATE_PASSWORD_ERROR:
      return { ...state, authMessage: action.payload };

    case types.SET_USER:
      return { ...state, currentUser: action.user };

    case types.UNVERIFIED_EMAIL:
      return { ...state, currentUser: action.user };

    case types.EMAIL_VERIFICATION_SENT:
      return { ...state, emailVerificationSent: true };
    case types.EMAIL_VERIFICATION_UNSENT:
      return { ...state, emailVerificationSent: false };

    case types.SET_EMAIL:
      return { ...state, email: action.email };

    case types.UPDATE_USER_NAME:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          name: action.name, // Update only the name field
        },
      };

    case types.UPDATE_FIREBASE_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          firebaseUser: action.firebaseUser, // Update only the name field
        },
      };

    case types.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          profilePicture: action.downloadURL, // Update profile picture
        },
      };

    default:
      return state;
  }
};

export default auth;
