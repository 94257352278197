import React, { useEffect } from "react";
import {
  BottomText,
  ProjectCard,
  LogoMarkText,
  HomeContainer,
  PlusIcon,
  Text,
} from "./HomeStyles";
import { ReactComponent as Plus } from "../../assets/PlusIcon.svg";
import Header from "../../Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const Home = ({ currentUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("you are in home: ", currentUser);
    if (currentUser && !currentUser.emailVerified) {
      console.log("email not verified, home redirecting to login", currentUser);
      navigate("/");
    }
  }, [currentUser, navigate]);

  return (
    <React.Fragment>
      <Header screen="home" />
      <HomeContainer>
        <LogoMarkText>
          Happy<span>&nbsp;Tagging</span>
        </LogoMarkText>
        <Link to="/upload-files" style={{ textDecoration: "none" }}>
          <ProjectCard>
            <PlusIcon as={Plus} />
            <Text>New Project</Text>
          </ProjectCard>
        </Link>
        <BottomText>
          Experience the fastest text annotation tool on the planet!
        </BottomText>
      </HomeContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps)(Home);
