import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "./Reducers";
import storage from "redux-persist/lib/storage"; // Default: localStorage

import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};
// Use persistReducer to wrap rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// getDefaultMiddleware includes thunk by default, among other things
const defaultMiddleware = getDefaultMiddleware();

const middleware = [...defaultMiddleware];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger); // Add logger in development mode
}

const store = configureStore({
  reducer: persistedReducer, // Use persistedReducer instead of rootReducer
  middleware: middleware,
  devTools: process.env.NODE_ENV !== "production", // Use Redux DevTools in development mode only
});

export const persistor = persistStore(store); // Create a persistor object

export default store;
